import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BehaviorSubject } from 'rxjs';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  colors,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Typography,
  TextField,
} from '@material-ui/core';
import { Link as _Link, Redirect, useHistory } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Copyright from './Copyright';
import useAuthUser from '../common/useAuthUser';
import { withFirebase } from '../Firebase';
import Message from '../common/Message';
import InputCriteriaHint, {
  emailCriterias,
  findInput,
  handleInputChange,
  passwordCriterias,
} from '../common/form/InputCriteriaHint';
import FormInput from '../common/form/FormInput';
import AccountSettingsForm, { inputNames } from '../Setting/AccountSettingsForm';
import FirestoreHelper from '../common/FirestoreHelper';
import { useEffect } from 'react';
import { rxHeaderEnabled } from '../common/config';
import useRxSubject from '../common/useRxSubject';
import imgGoogleSignIn from '../../assets/images/btn_google_signin_light_normal_web@2x.png';

const theme = createTheme();
const images = {
  bannerSignIn: '/images/login/bg-signin.jpg',
  bannerSignUp: '/images/login/bg-signup.jpg',
  googleSignIn: imgGoogleSignIn, //'./images/login/btn_google_signin_light_normal_web.png',
};
const useStyles = makeStyles(() => ({
  googleButton: {
    background: `url(${images.googleSignIn}) no-repeat`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    display: 'block',
    height: 46,
    margin: '10px auto',
    width: 196,
  },
  root: { height: '100vh' },
  terms: {
    color: colors.grey[600],
  },
  tm: {
    fontSize: 13,
    fontWeight: 'bold',
    paddingBottom: 5,
  },
}));

function Login(props) {
  const { doSignup: _doSignup, firebase, redirectTo = '/' } = props;
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [doSignup, setDoSignup] = useState(_doSignup);
  const [email, setEmail] = useState({
    criterias: emailCriterias,
    value: null,
    valid: false,
  });
  // const [username, setUsername] = useState({})
  const [password, setPassword] = useState({
    criterias: (doSignup && passwordCriterias) || [],
    show: false,
    value: null,
    valid: false,
  });
  // RxJS subject to be provided to the account settings form so that sign in/up form has access to it
  const [rxInputs] = useState(new BehaviorSubject());
  // whenever rxInputs changes, automatically update the username which will contain properties like: value, valid....
  const [username] = useRxSubject(rxInputs, (inputs = []) =>
    findInput(inputNames.username, inputs)
  );

  useEffect(() => {
    // hide the header
    rxHeaderEnabled.next(false);
  }, []);

  const doRedirect = () => {
    // (re-)enable header
    rxHeaderEnabled.next(true);
    setTimeout(() => history.push(redirectTo));
  };

  const submitDisabled =
    loading ||
    !![email, password, doSignup && username]
      .filter(Boolean)
      .find((x) => !x.value || (doSignup && !x.valid));
  const auth = firebase.auth;
  const bannerImg = doSignup ? images.bannerSignUp : images.bannerSignIn;

  // gracefully handle any unexpected error and show in the message field
  const catchError =
    (fn) =>
    async (...args) => {
      try {
        fn && (await fn(...args));
      } catch (error) {
        let text = error?.message || '';
        // override default 'email in use' error message
        if (text.includes('email address is already in use')) {
          text = `That email address is already in use by another account. \n\nPlease reset your password or try a different email address.`;
        }
        if (text.includes('password is invalid or the user does not have a password')) {
          text = 'Either the password is incorrect or you signed up with your Google account.';
        }
        // ignore error if user closes Google login modal
        const ignoreError = text.includes('closed by the user');
        !ignoreError && setMessage({ status: 'error', text });
        setLoading(false);
      }
    };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitDisabled) return;

    const data = new FormData(e.currentTarget);
    const persistence = data.get('remember') === 'remember' ? 'session' : 'none'; // 'local'
    // eslint-disable-next-line no-console
    if (!email.valid || !password.valid)
      throw !email.valid && !password.valid
        ? 'Both email and password fields are required'
        : !email.valid
        ? 'Please enter your email address'
        : 'Please enter your password';

    setMessage({
      status: 'loading',
      text: 'Processing...',
    });
    setLoading('email');
    await auth.setPersistence(persistence);
    const func = doSignup ? 'createUserWithEmailAndPassword' : 'signInWithEmailAndPassword';
    const { user } = await auth[func](email.value, password.value);

    // save username after signup is completed
    if (doSignup && username.valid) {
      setMessage({
        status: 'loading',
        text: 'Processing....',
      });
      const { uid } = user;
      const doc = {
        email: email.value,
        uid,
        username: username.value,
      };
      await new FirestoreHelper('users').save(doc, user.uid, true);
    }

    // login success redirect to homepage
    setLoading(false);
    doRedirect();
  };

  const handleSignInWithGoogle = async (event) => {
    event.preventDefault();

    setLoading('google');
    setMessage(null);
    const result = await firebase.signInWithGoogle();
    const doc = await firebase.firestore.collection('users').doc(result.user.uid).get();

    if (doc.data()?.blocked === true) throw new Error('User is blocked by admin');
    // login success redirect to homepage
    doRedirect();
  };

  const handlePWReset = async (e) => {
    e.preventDefault();
    const invalid = !email.value || !email.valid;

    setMessage({
      status: invalid ? 'error' : 'loading',
      text: invalid
        ? !email.value
          ? 'Please enter your account email address'
          : emailCriterias[0].text
        : 'Processing...',
    });
    if (invalid) return;

    await auth.sendPasswordResetEmail(email.value, {
      url: process.env.REACT_APP_SIGN_IN_URL,
      handleCodeInApp: false,
    });
    setMessage({
      status: 'success',
      text: `An email with instructions to recover your account has been sent to ${email.value}`,
    });
  };

  // user already logged in => let user login in again or force logout?
  // if (user?.uid) firebase.doSignOut()

  const acFormValues = {};
  acFormValues[inputNames.username] = username?.value || '';

  return (
    <ThemeProvider theme={theme}>
      <Grid className={classes.root} container component="main">
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          style={{
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${bannerImg})`,
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar
              sx={{
                m: 1,
                width: 50,
              }}
              src="/images/logo.png"
            />
            <Typography alignCenter component="h1" style={{ margin: '20px 0 15px' }} variant="h5">
              Sign {doSignup ? 'up' : 'in'} for OMNISCAPE
              <sup className={classes.tm}>TM</sup>
              {' VRE'}
            </Typography>

            {/* Start: Sign in/up with Google */}
            <Button
              className={classes.googleButton}
              disabled={!!loading}
              onClick={catchError(handleSignInWithGoogle)}
              startIcon={loading === 'google' && <CircularProgress size={20} />}
              type="submit"
            />

            <Box
              component="form"
              noValidate
              onSubmit={catchError(handleSubmit)}
              sx={{
                mt: 1,
                width: '100%',
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '12px',
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  margin: '15px 0px',
                }}
              >
                <Typography style={{ background: 'white', padding: '5px' }} variant="p">
                  OR
                </Typography>
              </div>
              {/* End: Sign in/up with Google */}

              <div>
                {/* Show user name field when signing up */}
                {doSignup && rxInputs && (
                  <AccountSettingsForm
                    {...{
                      // only show username field
                      hiddenInputs: Object.keys(inputNames).filter(
                        (name) => name !== inputNames.username
                      ),
                      rxInputs,
                      submitButton: null, // hides the save button
                      // preserves the username even when switched between sign in and sign up mode.
                      values: acFormValues,
                    }}
                  />
                )}

                <FormInput
                  {...{
                    autoComplete: 'email',
                    autoFocus: true,
                    fullWidth: true,
                    id: 'email',
                    label: 'Email Address',
                    margin: 'normal',
                    name: 'email',
                    onChange: handleInputChange(setEmail, email),
                    required: true,
                    type: 'email',
                    valid: email.valid,
                    validation: {
                      ...email,
                      hideOnBlur: false,
                      hideOnValid: true,
                    },
                    value: email.value || '',
                    variant: 'outlined',
                  }}
                />

                <FormInput
                  {...{
                    id: 'password',
                    fullWidth: true,
                    label: 'Password',
                    name: 'password',
                    onChange: handleInputChange(setPassword, password),
                    required: true,
                    type: 'password',
                    value: password.value,
                    valid: password.valid,
                    validation: {
                      ...password,
                      hideOnBlur: true,
                      hideOnValid: false,
                      title: 'Please enter a password matching the following criteria:',
                    },
                  }}
                />

                <FormControlLabel
                  control={<Checkbox color="primary" name="remember" value="remember" />}
                  label="Remember me"
                />
              </div>

              {/* Error/success message container */}
              {message && <Message {...message} />}

              {doSignup && (
                <Typography className={classes.terms} component="div" variant="caption">
                  {`By clicking the 'SIGN UP' button, you are agreeing to OMNISCAPE's `}
                  <Link href="https://www.omniscape.com/tos" underline="always">
                    Terms of Use
                  </Link>
                  {' and '}
                  <Link href="https://www.omniscape.com/privacy" underline="always">
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              )}

              <Button
                disabled={submitDisabled}
                fullWidth
                startIcon={loading === 'email' && <CircularProgress size={20} />}
                sx={{
                  marginTop: 10,
                  mt: 3,
                  mb: 2,
                }}
                type="submit"
                variant="contained"
              >
                Sign {doSignup ? 'Up' : 'In'}
              </Button>

              <Grid container style={{ margin: '10px 0' }}>
                <Grid item xs>
                  <Link
                    href="#"
                    onClick={catchError(handlePWReset)}
                    underline="always"
                    variant="body2"
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    href="#"
                    underline="always"
                    variant="body2"
                    onClick={(e) => {
                      e.preventDefault();
                      const pw = {
                        ...password,
                        criterias: (!doSignup && passwordCriterias) || [],
                      };

                      // manually trigger change
                      const fakeEvent = {
                        target: {
                          value: pw.value,
                        },
                      };
                      handleInputChange(setPassword, pw)(fakeEvent);
                      setMessage(null);
                      setDoSignup(!doSignup);
                    }}
                  >
                    {doSignup ? 'Got an account? Sign In' : `Don't have an account? Sign Up`}
                  </Link>
                </Grid>
              </Grid>
              <Copyright />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
Login.defaultProps = {
  doSignup: false,
};
Login.propTypes = {
  doSignup: PropTypes.bool,
};
export default withFirebase(Login);
