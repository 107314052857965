import React from 'react';
import { Dialog, DialogContent, DialogContentText, CircularProgress, Box } from '@material-ui/core';

export default function AlertDialog({ open, setOpen }) {

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogContent>
          <>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress color="inherit" /><br />

            </Box>
            <DialogContentText id="alert-dialog-description" style={{ marginTop: 12, marginBottom: 5 }}>
              Loading buildings. Please wait.

            </DialogContentText>
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
}
