import { connect } from 'react-redux';
import { compose } from 'redux';
import { setViewState } from '../../actions';
import { withAxios } from '../api';
import MapLocations from './MapLocations';

export default compose(
  withAxios,
  connect(
    ({ viewStateReducer }) => ({ viewState: viewStateReducer }),
    { setViewState },
  ),
)(MapLocations);
