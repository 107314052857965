import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    borderBottomLeftRadius: 18,
    bottom: 0,
    boxShadow: '0px 3px 6px #00000096',
    overflowY: 'auto',
    position: 'absolute',
    right: 0,
    top: '4rem',
    transition: 'width .4s',
    width: 330,
  },
  container: {
    padding: '2rem',
    display: 'flex',
    height: 'calc(100% - 124px)',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  button: {
    height: 55,
    backgroundSize: '330% 100%',
    background:
      'linear-gradient(90deg, rgba(200,200,200,1) 33%, #008873 33%, #008873 66%, #00CB7D 66%)',
    transition: 'color .4s, background-position .5s',
    borderRadius: 18,
    color: 'white',
    margin: '25px 0px 24px 0px',
    width: '100%',
    fontSize: 18,
    fontWeight: 'bold',
    backgroundPosition: '-44% 0%',
    '&:hover': {
      backgroundPosition: '-94% 0%',
    },
  },
  buttonSaving: {
    color: '#008873',
    pointerEvents: 'none',
  },
  disabled: {
    backgroundPosition: '0% 0%',
    '&:hover': {
      backgroundPosition: '0% 0%',
    },
    pointerEvents: 'none',
  },
  cartSpacing: {
    height: 70,
  },
  checkoutSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkoutValue: {
    fontWeight: 800,
  },
  discounts: {
    color: '#3A6FE6',
  },
  divider: {
    height: 1,
    border: 'none',
    background: '#BEBBBB',
    margin: '16px 0px',
  },
  invalidUrl: {
    background: '#e5737f',
  },
  lineItemLabel: {
    fontSize: 16,
    fontWeight: '600',
  },
  orderSummary: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  popUp: {
    fontWeight: 700,
    color: '#0000FF',
    textDecoration: 'underline',
    marginTop: '12px',
  },
  promoButton: {
    textTransform: 'capitalize',
    borderRadius: '0px 8px 8px 0px',
    background: '#EFEFEF',
    fontSize: 18,
    fontWeight: 'bold',
    padding: '4px 24px',
  },
  promoCode: {
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0px 3px 6px #00000028',
    marginBottom: 28,
    borderRadius: 8,
  },
  promoInput: {
    borderRadius: '8px 0px 0px 8px',
    fontSize: 18,
    fontWeight: 'bold',
    paddingLeft: 12,
    paddingTop: 5,
  },
  title: {
    display: 'block',
    fontSize: 32,
    fontWeight: 700,
    margin: '18px 0px 23px',
  },
  totalCost: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  bttn: {
    marginTop: 5,
    marginBottom: 15,
    fontSize: 12,
    maxWidth: 400,
    background: 'black',
    color: 'white',
    '&:hover': {
      background: '#262cf2',
    },
  },
}));

export default useStyles;
