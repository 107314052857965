import { addTs, isObj } from "./utils"
import firebase from '../Firebase'

/**
 * @name    saveBatch
 * @summary save documents in batch. (new entries tested. Updates not tested)
 * 
 * @param   {Array}         docs        Documents to save. Must include an ID field. See `idKey` param.
 * @param   {String|Object} collection  Collection name or instance of collection 
 *                                      Eg:`admin.firestore().collection(collectionName)`
 * @param   {Boolean}       merge       Whether to merge with existing document
 *                                      Default: `true`
 * @param   {String}        idKey       (optional) the name of the ID property.
 *                                      Default: `"id"`
 * @param   {String}        firestore   (optional) 
 * 
 * @returns {*} result
 */
export default async function saveBatch(docs, collection, merge = true, idKey = 'id', firestore = firebase.firestore) {
    if (!Array.isArray(docs)) throw new Error('Array required')

    if (!docs.every(isObj)) throw new Error('Every document must be an object')

    const gotIds = docs.every(x => !!x[idKey])
    if (!gotIds) throw new Error(`Missing "${idKey}"`)

    // instantiate collection reference if required
    const db = typeof collection === 'string'
        ? firestore.collection(collection)
        : collection

    // Initiate a batch write peration 
    const batch = firestore.batch()
    for (let i = 0; i < docs.length; i++) {
        const doc = docs[i]
        const docRef = db.doc(doc[idKey])
        // add timestamps to document
        addTs(doc)
        // add document to the batch write operation
        batch.set(docRef, doc, { merge })
    }

    // submit batch write operation
    return await batch.commit()
}