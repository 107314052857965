/* eslint-disable no-undef */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable no-alert */
/* eslint-disable no-unused-expressions */

import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import Geocoder from 'react-map-gl-geocoder';

// deck.gl loaders and dependencies
import { GLTFLoader } from '@loaders.gl/gltf';
import { registerLoaders } from '@loaders.gl/core';
import { EditableGeoJsonLayer, DrawPolygonMode } from 'nebula.gl';
import booleanContains from '@turf/boolean-contains';
import { featureToH3Set, h3ToFeature } from 'geojson2h3';
import { geoToH3 } from 'h3-js';
import { Button, Select, MenuItem, Typography, InputLabel, FormControl } from '@material-ui/core';
import useStyles from './MapLocations.styles';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './map.css';
import { createH3Layer, createHexList } from '../../utils/layer_helpers';
import { InfoModal, ConfirmOrderModal, AlertDialog } from './InfoModals';
import { isInsideRegion, getRestrictedAreas } from '../common/geoutils';
import { toast } from 'react-toastify';
// Register the proper loader for scenegraph.gltf
registerLoaders([GLTFLoader]);

const toolList = {
  point: 'drawPoint',
  polygon: DrawPolygonMode,
  delete: DrawPolygonMode,
};

const SuccessModal = ({ onCancel }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.modalWrapper}>
      <div className={classes.modalContainer}>
        <Typography align="left" className={classes.title}>
          Purchase successful
        </Typography>
        <Typography align="left" className={classes.modalText} variant="body1">
          Want to leave this page and add 3D content to your new locations?
        </Typography>
        <div className={classes.modalButtonRow}>
          <Button
            className={classes.modalSaveButton}
            color="primary"
            onClick={() => history.push('/editor')}
            variant="contained"
          >
            Yes
          </Button>
          <Button className={classes.modalButton} onClick={onCancel}>
            No
          </Button>
        </div>
      </div>
    </div>
  );
};

const SelectTool = ({ tool, setTool }) => {
  const classes = useStyles();
  return (
    <FormControl style={{ left: 300 }}>
      <InputLabel id="demo-simple-select-label" />
      <Select
        className={classes.selectTool}
        inputProps={{ className: classes.selectInput }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={tool}
        style={{ margin: 0 }}
        onChange={(e) => setTool(e.target.value)}
      >
        <MenuItem value="point">Select Single Location</MenuItem>
        <MenuItem value="polygon">Select Multiple Locations</MenuItem>
        <MenuItem value="delete">Delete Multiple Locations</MenuItem>
      </Select>
    </FormControl>
  );
};

const MapLocations = ({
  axios,
  success,
  setSuccess,
  totalCredits,
  cartList,
  setCartList,
  master,
  infoModal,
  setInfoModal,
  viewState,
  setViewState,
  confirmModal,
  setConfirmModal,
  firebase,
  setButtonText,
  maptheme,
  discount,
  setDiscount,
  setPromo,
  claimed,
  owned,
  loading,
}) => {
  // map view state

  const [search, setSearch] = useState(null);

  // Layer of all owned hexagons
  const [masterList, setMasterList] = useState([]);
  const [masterLayer, setMasterLayer] = useState([]);
  const [masterList1, setMasterList1] = useState([]);
  const [masterLayer1, setMasterLayer1] = useState([]);
  // Layer of hexagons in cart
  const [cartLayer, setCartLayer] = useState([]);
  const [credits, setCredits] = useState(0);

  // Layer of hexagon use currently hovering over
  const [hovered, setHovered] = useState([]);

  // Used for Polygon edit tool
  const [featureCollection] = useState({
    type: 'FeatureCollection',
    features: [],
  });
  const [selectedFeatureIndexes] = useState([]);
  const [tool, setTool] = useState('point');
  const [doConfirmOrder, onConfirm] = confirmModal || [];

  const [restrictedAreas, setrestrictedAreas] = useState(null);
  useEffect(() => {
    getRestrictedAreas().then((data) => {
      setrestrictedAreas(data);
    });
  }, []);

  /**
   * Calculates acrt price
   */
  const priceCalc = () => setCredits(cartList.length * 100);

  const handleAddNewData = (current, geo) => {
    const { type, coordinates } = geo.geometry;

    // check if the clicked location is inside a restricted area
    let isInside = false;
    let region;
    restrictedAreas.forEach((area) => {
      if (isInsideRegion({ x: coordinates[0], y: coordinates[1] }, area.coordinates)) {
        isInside = true;
        region = area.name;
        return;
      }
    });
    if (isInside) {
      toast.error(`For now we are disabling purchasing locations in ${region}`);
      return;
    }

    let hexes = null;

    // handle polygon/point hexagon select
    // handle delete tool
    if (tool === 'delete') {
      const newList = current.filter((x) => !booleanContains(geo, h3ToFeature(x)));
      setCartList([...newList]);
      setCartLayer([createH3Layer(newList, [255, 183, 77])]);
      return;
    }
    if (type === 'Polygon') {
      hexes = featureToH3Set(geo, 13);
    } else if (type === 'Point') {
      hexes = [geoToH3(geo.geometry.coordinates[1], geo.geometry.coordinates[0], 13)];
    }

    // if (100 * (cartList.length + hexes.length) > totalCredits) {
    //   alert('not enough credits');
    //   return;
    // }

    const add = [];
    const remove = [];
    hexes.forEach((hex) => {
      const found = masterList.includes(hex) || masterList1.includes(hex);

      if (found) {
        remove.push(hex);
      } else if (!cartList.includes(hex)) {
        add.push(hex);
      }
    });
    // Old cartlist combined with new  hexes
    const newList = current.filter((x) => !remove.includes(x)).concat(add);

    setCartList(newList);
    setCartLayer([createH3Layer(newList, [255, 183, 77])]);

    // updates cart credits based on cartList @ 100 cr per hex
    priceCalc();
  };

  /**
   * Creates editable layer that allows user to add points and polygons
   */
  const createDrawLayer = new EditableGeoJsonLayer({
    id: 'geojson',
    data: featureCollection,
    mode: toolList[tool],
    selectedFeatureIndexes,
    onEdit: (object) => {
      const geo = object.updatedData.features.pop();

      // geo is a geojson feature with a polygon geometry
      handleAddNewData(cartList, geo);
    },
    getFillColor: [255, 255, 0],
    opacity: 0.3,
    stroked: false,
  });

  /**
   * Right click deletes hex from cart
   */
  const handleRightClick = (e) => {
    e.preventDefault();
    const clicked = createHexList(e.lngLat);
    const filtered = cartList.filter((i) => !clicked.includes(i));
    setCartList(filtered);
    setCartLayer([createH3Layer(filtered, [255, 183, 77])]);
  };

  // Map Utils
  const mapRef = React.useRef();
  const geocoderContainerRef = React.useRef();
  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
  const handleViewPortChange = (vp) => setViewState({ ...vp, transitionDuration: 0 });

  const onSearchResult = useCallback((vp) => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };
    const { latitude, longitude } = vp;
    setSearch({ latitude, longitude });
    return handleViewPortChange({
      ...vp,
      ...geocoderDefaultOverrides,
    });
  }, []);
  const classes = useStyles();
  /**
   * Renders buildings from props data
   *
   * master = {
   *  total: 5,
   *  locations: [
   *    'location1',
   *    'location2',
   *    ]
   *  }
   *
   */
  useEffect(() => {
    if (claimed) {
      let tempMaster = [];
      tempMaster = [...tempMaster, createH3Layer(claimed, [128, 128, 128])];
      setMasterList([...masterList, ...claimed]);
      setMasterLayer([...masterLayer, ...tempMaster]);

      setCartList([]);
      setCartLayer([]);
    }
  }, [claimed]);

  useEffect(() => {
    if (owned) {
      let tempMaster1 = [];
      tempMaster1 = [...tempMaster1, createH3Layer(owned, [33, 150, 243])];
      setMasterList1([...masterList1, ...owned]);
      setMasterLayer1([...masterLayer1, ...tempMaster1]);
      setCartList([]);
      setCartLayer([]);
    }
  }, [owned]);
  useEffect(() => {
    if (cartList.length === 0) setCartLayer([]);
  }, [cartList]);

  return loading ? (
    <AlertDialog />
  ) : (
    <div>
      {success && <SuccessModal onCancel={() => setSuccess(false)} />}
      {infoModal && <InfoModal info={infoModal} onCancel={() => setInfoModal(false)} />}
      {doConfirmOrder && (
        <ConfirmOrderModal
          {...{
            onClick: onConfirm,
            onCancel: () => setConfirmModal([]),
          }}
        />
      )}

      <div className={classes.root}>
        <ReactMapGL
          ref={mapRef}
          {...viewState}
          maxZoom={20}
          minZoom={13}
          width="inherit"
          height="100%"
          touchRotate // used for mobile
          touchZoom // used for mobile
          onViewportChange={handleViewPortChange}
          onContextMenu={handleRightClick}
          mapStyle={maptheme ? maptheme : `mapbox://styles/mapbox/streets-v11`}
          mapboxApiAccessToken={MAPBOX_TOKEN}
        >
          {search && (
            <Marker latitude={search.latitude} longitude={search.longitude}>
              <div className={classes.marker} />
            </Marker>
          )}
          <div className={classes.navigation}>
            <NavigationControl />
          </div>
          <div className={classes.menuContainer}>
            <div className={classes.geocoder}>
              <Geocoder
                mapRef={mapRef}
                trackProximity
                containerRef={geocoderContainerRef}
                onViewportChange={onSearchResult}
                mapboxApiAccessToken={MAPBOX_TOKEN}
                position="top-left"
                marker={false}
              />
            </div>
            <SelectTool className={classes.select} tool={tool} setTool={setTool} />
          </div>

          <DeckGL
            getCursor={(e) => 'default'}
            useDevicePixels
            viewState={{ ...viewState }}
            maxZoom={20}
            minZoom={13}
            layers={[[...masterLayer, ...cartLayer, hovered, createDrawLayer, ...masterLayer1]]}
          />
        </ReactMapGL>
      </div>
    </div>
  );
};

export default MapLocations;
