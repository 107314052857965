import React, { useState, useEffect, Suspense } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@material-ui/core';
import Loading from '../Loading';
import { withFirebase } from '../Firebase';
import 'firebase/auth';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Orders from '../Orders'
import useAuthUser from '../common/useAuthUser';

const useStyles = makeStyles((edit) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'absolute',
        overflowY: 'scroll'
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: 10,
        paddingLeft: '30px',
        paddingRight: '30px'
    },
    smallwrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: 10,
    },
    button: {
        marginRight: 5,
        fontSize: 12,
        maxWidth: 400,
        background: 'black',
        color: 'white',
        '&:hover': {
            background: '#262cf2',
        },
    },
    field: {
        width: '50vw',
        maxWidth: 400,
    },
    title: {
        fontSize: 24,
        fontWeight: 700,
        marginBottom: 10,
    },
    link: {
        marginTop: 8,
        fontSize: 14,
    },
    table: {
        minWidth: 150,
    },
    drower: {
        width: 200,
        background: 'black',
        height: '100%'
    },
    content: {
        background: 'red',
        height: '100%'
    },
    name: {
        fontWeight: 'bold',
    },
    buttoncell: {
        borderBottom: 'none'
    },
    icondiv: {
        width: '100%'
    },
    title: {
        fontWeight: 'bold',
        fontSize: 20,
        textAlign: 'center',
        marginBottom: 30
    },
    titlebar: {
        background: '#0080fff0',
        width: '100%',
        color: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
    },
    titleText: {
        textAlign: 'center',
        fontWeight: 'bold',
        margin: 0
    },
    titletext: {
        textAlign: 'center',
        fontWeight: 'normal',
        margin: 0
    },
    mapButton: {
        margin: 'auto',
        textAlign: 'center',
        background: '#f0cd3c',
        border: 'none',
        color: 'white',
        width: '100vw',
        maxWidth: 400,
        color: 'black',
        fontWeight: 700,
        marginTop: 5,
        marginBottom: 5,
        '&:hover': {
            background: '#f0cd3c',
        },
    },
    topic: {
        fontSize: 24,
        fontWeight: 700,
        marginBottom: 18,
        textAlign: 'center',
        width: '100%'
    },
    sidebar: {
        width: '270px',
        background: '#B4BDC1',
        height: '100 %',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    content: {
        marginLeft: '250px',
        height: '100%',
        background: 'black'
    },
    titleacnt: {
        padding: 5,
        fontWeight: 700,
        marginLeft: 5
    }

}));

function Inventory({ firebase }) {

    const classes = useStyles();
    const { user: currentUser, userData = {} } = useAuthUser()
    const [account, setAccount] = useState(true);
    const [userLocations, setUserLocations] = useState(0);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        if (currentUser && currentUser.uid) {
            firebase.firestore.collection("purchasedLocations")
                .where("ownerID", "==", firebase.auth.currentUser.uid)
                .get().then((querySnapshot) => {
                    setUserLocations(querySnapshot.size)
                })

        }
    }, []);

    return (
        <Suspense fallback={<Loading />}>
            <div className={classes.root}>
                <div className={matches ? classes.wrapper : classes.smallwrapper} style={{ paddingTop: !account ? 550 : '' }}>
                    <div className={classes.titlebar}>
                        <h1 className={classes.titleText}>The world at your fingertips</h1>
                        <p className={classes.titletext}>Grab a piece of the new frontier.  Collect ad revenue.  Sell for profit. </p>
                        <h1 className={classes.titleText}>
                            <Button variant="outlined" className={classes.mapButton}>
                                Go To map
                            </Button>
                        </h1>
                    </div>

                    <TableContainer component={Paper} style={{ width: 'fit-content', marginTop: 20 }}>
                        <Table className={classes.table1} aria-label="simple table">
                            <TableHead>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row" align="left" className={classes.buttoncell}>
                                        <Button className={classes.button} onClick={() => setAccount(true)}>Account</Button>
                                        <Button className={classes.button} onClick={() => setAccount(false)}>Inventory</Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <TableContainer component={Paper} style={{ marginTop: 15, width: account ? 600 : 1200 }}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <h1 className={classes.titleacnt}>{account ? 'Account :' : 'Inventory :'} </h1>
                            </TableHead>
                            <TableBody>
                                {account ?
                                    <>
                                        <TableRow>
                                            <TableCell component="th" scope="row" align="left" className={classes.name}>
                                                Credits :
                                            </TableCell>
                                            <TableCell align="left">{userData.credits}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row" align="left" className={classes.name}>
                                                Properties :
                                            </TableCell>
                                            <TableCell align="left">{userLocations}</TableCell>
                                        </TableRow>
                                    </>
                                    :
                                    <Orders />
                                    // <TableRow component="th" scope="row" align="center">
                                    //     <p style={{ color: 'red' }}>No data found !!</p>
                                    // </TableRow>

                                }

                            </TableBody>
                        </Table>
                    </TableContainer>

                </div>
            </div>
        </Suspense>
    );
}

export default withFirebase(Inventory);
