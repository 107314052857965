import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  '@global': {
    body: {
      // overflowY: 'scroll',
    },
  },
});

function Baseline() {
  return null;
}

Baseline.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Baseline);
