/* eslint-disable no-console */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import axios from 'axios';
import { toast } from 'react-toastify';
import { getToken as getAuthToken } from '../common/useAuthToken';
import { isObj } from '../common/utils';

/**
 * @name    getOptions
 * @summary get request options including header and user auth token
 * 
 * @param   {Number}  timeout       (optional)
 * @param   {Boolean} requireLogin  (optional) whether to wait until user is authenticated/logged in.
 *                                  Defaut: `true`
 * 
 * @returns {Object}
 */
export const getOptions = async (timeout, requireLogin) => {
  const token = await getAuthToken(requireLogin)
  const headers = {
    'Access-Control-Allow-Origin': '*',
  }
  if (token) headers.authorization = `Bearer ${token}`
  return {
    headers,
    timeout,
  }
}
export const handleError = (err, doToast) => {
  err = err?.response?.data || err
  if (err && doToast) toast.error(`${err}`)
  return Promise.reject(err)
}

// export function handleQueries(rootUrl, queries) {
//   if (queries === undefined) return rootUrl;
//   let url = rootUrl;
//   const { length } = Object.keys(queries);
//   if (queries?.length > 0) {
//     url += '?';
//     Object.keys(queries).forEach((query, index) => {
//       url += `${query}=${queries[query]}`;
//       if (index < length - 1) {
//         url += '&';
//       }
//     });
//   }
//   // console.log(url);
//   return url;
// }

/**
 * @name  handleQueries
 * @summary adds URL parameters
 * 
 * @param   {String} baseUrl 
 * @param   {Object} params  (optional)
 * 
 * @returns {String}
 */
export const handleQueries = (baseUrl, params = {}) => {
  if (!isObj(params)) return baseUrl

  params = Object
    .keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return `${baseUrl}?${params || ''}`
}

export default class Axios {

  // unused????
  async confirmCheckout(res) {
    if (!res) throw new Error('Unable to complete checkout');

    const options = await getOptions(30000)
    return await axios.post(
      `${process.env.REACT_APP_SATELLITE_URL}/checkout`,
      {
        uid: res.data.uid,
        tx: res.data.tx,
      },
      options,
    )
  }

  async addToMap(toAdd) {
    if (!toAdd) throw new Error('Missing object information');

    const options = await getOptions()
    return await axios.post(
      process.env.REACT_APP_WORLDMAP_FUNCTION_URL,
      toAdd,
      options,
    );
  }

  async removeFromMap(objectConfirmation) {
    if (!objectConfirmation) throw new Error('Missing object information');

    const options = await getOptions()
    const apiUrl = process.env.REACT_APP_WORLDMAP_FUNCTION_URL
    const url = `${apiUrl}?contentID=${objectConfirmation.object.contentID}&h3=${objectConfirmation.object.h3}`
    return await axios.delete(url, options)
  }

  async getLocations(args) {
    const options = await getOptions()
    const url = handleQueries(`${process.env.REACT_APP_PICKLE_URL}/locations`, args)
    return await axios.get(url, options)
  }

  // unused??
  async getContent(args) {
    const options = await getOptions()
    const url = handleQueries(`${process.env.REACT_APP_PICKLE_URL}/content`, args)
    return await axios.get(url, options)
  }

  /**
   *
   * Options:
   * - limit
   * - order status
   * @param {object} args   arguments to pass
   */
  async getOrders(args) {
    // console.log('at getOrders');
    const options = await getOptions()
    const url = handleQueries(`${process.env.REACT_APP_ORDERS_FUNCTION_URL}`, args)
    const res = await axios.get(url, options)

    return res.data;
  }

  /**
   * @name    calculateCost
   * @summar  calculate order cost and apply promo code
   *
   * @param   {Array}   data.orderDetails
   * @param   {String}  data.orderType
   * @param   {String}  data.paymentMethod
   * @param   {String}  data.promoCode
   *
   * @returns {Object}
   */
  async calculateCost(data = {}) {
    const options = await getOptions(10000)
    const url = handleQueries(`${process.env.REACT_APP_ORDERS_FUNCTION_URL}/calculate-cost`)
    return await axios
      .post(url, data, options)
      .catch(handleError);
  }

  /**
   * @name  createOrder
   *
   * @param {Object}  args
   * @param {Array}   args.cartList     location IDs
   * @param {String}  args.payment.id    (optional) moneybutton payment ID, if paid with OmniToken
   * @param {String}  args.payment.txId  (optional) OmniToken payment transaction ID
   * @param {String}  args.payment.paymentMethod  Payment method: credits, bsv or omni_token
   *
   * @returns {Object}
   */
  async createOrder(args) {
    const { cartList, payment, promoCode } = args;
    if (!cartList) throw new Error('Missing list of locations');

    const options = await getOptions(60000)
    return await axios
      .post(
        handleQueries(`${process.env.REACT_APP_ORDERS_FUNCTION_URL}`),
        { cartList, payment, promoCode },
        options,
      )
      .catch(handleError);
  }

  async confirmOrder(orderId) {
    if (!orderId || orderId === undefined) throw new Error('Missing orderId');
    const options = await getOptions(60000)

    return await axios
      .post(
        handleQueries(`${process.env.REACT_APP_ORDERS_FUNCTION_URL}/confirm`),
        { orderId },
        options,
      )
      .catch(handleError);
  }

  async getToken() {
    const url = handleQueries(`${process.env.REACT_APP_BRAINTREE_FUNCTION_URL}/payment/getToken`);
    const options = await getOptions()
    const result = await axios.get(url, options)
    return result.data.data
  }

  async processPayment(paymentObj) {
    const options = await getOptions()
    const url = handleQueries(`${process.env.REACT_APP_BRAINTREE_FUNCTION_URL}/payment/braintree`)
    return await axios
      .post(
        url,
        { ...paymentObj },
        options,
      )
      .catch(err => handleError(err, true))
  }

  async createPaymentIntent(prodId, moneytype, creditsPurchased) {
    const options = await getOptions()
    return axios
      .post(
        handleQueries(`${process.env.REACT_APP_PAY_URL}/create-payment-intent`),
        {
          creditsPurchased,
          moneytype,
          prodId,
        },
        options,
      )
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data);
        }
        return Promise.reject(err?.response?.data || err);
      });
  }

  /**
   * @name    confirmPaymentForOmniToken
   * @summary submit BSV payment information
   *
   * @param   {Object}  data
   * @param   {Number}  data.amountPaid
   * @param   {Number}  data.amountReceived
   * @param   {String}  data.creditType
   * @param   {String}  data.provider
   * @param   {String}  data.providerReference  payment ID
   */
  async confirmPaymentForOmniToken(data) {
    const url = handleQueries(`${process.env.REACT_APP_PAY_URL}/buy-omnitoken/bsv`);
    const options = await getOptions()
    const result = await axios.post(url, data, options);
    return result
  }

  username = usernameApi;

  async changeOrderName(orderId, name) {
    const url = handleQueries(`${process.env.REACT_APP_ORDERS_FUNCTION_URL}/change-name`)
    const options = await getOptions()
    return await axios.post(
      url,
      { orderId, name },
      options,
    )
  }
}

export const usernameApi = {
  baseUrl: process.env.REACT_APP_USERNAME_API,

  /**
   * @name    checkAvailability
   * @summary check username availability
   *
   * @param   {String} username
   * @param   {String} uid
   *
   * @returns {Boolean}
   */
  checkAvailability: async (username, uid) => {
    const data = { uid, username };
    const url = handleQueries(usernameApi.baseUrl, data)
    // if user is not logged in, do not use token
    const options = await getOptions(undefined, !!uid)
    const result = await axios.get(url, options)
    return result.data;
  },

  /**
   * @name    checkAvailability
   * @summary check username availability
   *
   * @param   {String} username
   * @param   {String} uid
   *
   * @returns {Boolean}
   */
  update: async (username, uid) => {
    const url = handleQueries(usernameApi.baseUrl)
    const data = { uid, username }
    const options = await getOptions()
    const result = await axios.post(url, data, options)
    return result.data
  },
};
