import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core'
import { withFirebase } from '../Firebase'
import Container from '@material-ui/core/Container'
import Baseline from './baseline'
import useAuthUser from '../common/useAuthUser'
import { className } from '../common/utils'
import PasswordResetForm from './PasswordResetForm'
import AccountSettingsForm from './AccountSettingsForm'
import { withAuthorization } from '../Session'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    margin: '10px 0',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  configMenuItem: {
    background: '#f0f0f0',
    borderRadius: '10px',
    cursor: 'pointer',
    margin: '5px 13px',
    padding: '5px',
    textAlign: 'center',
  },
  configMenuItemActive: {
    backgroundColor: '#ccc',
    boxShadow: 'black 0px 3px 8px -5px',
    fontWeight: '600',
  },
  settingsForm: {
    padding: 35,
    borderRadius: 20,
  },
  settingsFormRow: {
    padding: '15px 10px',
  },
  settingsFormField: {
    minWidth: '70%',
  },
  settingsPasswordField: {
    margin: '16px 3px',
    maxWidth: '85%',
  },
  infoBoxError: {
    background: '#fddddd none repeat scroll 0% 0%',
    padding: '10px 16px',
    margin: '10px 5px 0px',
    borderRadius: '5px',
    border: '1px solid red',
    color: '#d50303',
  },
  infoBox: {
    background: '#e6fddd none repeat scroll 0% 0%',
    padding: '10px 16px',
    margin: '10px 5px 0px',
    borderRadius: '5px',

    color: '#03d51a',
    border: '1px solid #12e600',
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 10,
    paddingLeft: '130px',
    paddingRight: '130px',
  },
  smallwrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 22,
  },
  button: {
    fontSize: 12,
    maxWidth: 400,
    background: 'black',
    color: 'white',
    '&:hover': {
      background: '#262cf2',
    },
  },
  field: {
    width: '50vw',
    maxWidth: 400,
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 18,
  },
  link: {
    marginTop: 8,
    fontSize: 14,
  },
  table: {
    minWidth: 150,
  },
  name: {
    fontWeight: 'bold',
    lineHeight: 1,
    padding: 9,
  },
  tdName: {
    lineHeight: 1,
    padding: 9,
  },
  buttoncell: {
    borderBottom: 'none',
  },
  icondiv: {
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',
    marginBottom: 30,
  },
  editbtn: {
    padding: 0,
    marginLeft: 3,
  },
  noPadding: {
    paddingLeft: 7.5,
    paddingTop: 7,
    paddingBottom: 7,
  },
}))

function Profile(props) {
  const classes = useStyles()
  const { user = {}, userData = {} } = useAuthUser()
  const [selectedTab, setSelectedTab] = useState(props.tab || 'account')
  const tabs = [
    {
      name: 'account',
      title: 'Account',
      getContent: () => userData?.uid && <AccountSettingsForm {...{ values: userData }} />,
    },
    {
      name: 'security',
      title: 'Security',
      getContent: () => !userData?.uid
        ? <center><CircularProgress /></center> // show loading spinner until user is logged in
        : <PasswordResetForm {...{ user, values: userData }} />,
    },
  ]
  const tab = tabs.find((x) => x.name === selectedTab)

  return !userData?.uid ? (
    ''
  ) : (
    <>
      <Baseline />
      <div className={classes.root}>
        <Container maxWidth='md'>
          <Typography variant='h4' component='h3' style={{ margin: '30px 15px' }}>
            Settings
          </Typography>

          <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid
              xs={11}
              sm={3}
              md={3}
              item
              style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}
            >
              {tabs.map(({ name, title }) => (
                <span
                  {...{
                    className: className({
                      [classes.configMenuItem]: true,
                      [classes.configMenuItemActive]: selectedTab == name,
                    }),
                    onClick: () => setSelectedTab(name),
                  }}
                >
                  {title}
                </span>
              ))}
            </Grid>
            <Grid xs={10} sm={7} md={8} item style={{ margin: '0 10px' }}>
              <Paper elevation={2} className={classes.settingsForm}>
                {/* Selected tab content */}
                {tab && (
                  <>
                    <Typography variant='h5' component='h2'>
                      {tab.header || tab.title}
                    </Typography>
                    <div style={{ paddingTop: 25 }}>
                      {tab.getContent()}
                    </div>
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default withFirebase(withAuthorization(auth => !!auth?.uid)(Profile))
