import { combineReducers } from "redux";
import firebaseReducer from './firebase.reducer';
import viewStateReducer from './viewState.reducer';
import cookiePopupReducer from './popup.reducer';

const rootReducer = combineReducers({
	firebaseReducer,
	viewStateReducer,
	cookiePopupReducer
});

export default rootReducer;
