import { connect } from 'react-redux';
import { compose } from 'redux'
import { withFirebase } from '../Firebase';
import { withAxios } from '../api';
import { withAuthorization } from '../Session';

import { setViewState } from '../../actions';

import Locations from './Locations';

const mapState = ({ firebaseReducer, viewStateReducer }) => ({
  user: firebaseReducer.user,
  viewState: viewStateReducer,
})

export default compose(
  withAuthorization((authUser) => !!authUser),
  withFirebase,
  withAxios,
  connect(
    mapState,
    { setViewState }
  ))(Locations)

