import { useEffect, useState } from 'react'
import { BehaviorSubject } from 'rxjs'
import firebase from '../Firebase'
import FirebaseHelper from './FirestoreHelper'
import useRxSubject from './useRxSubject'

export const rxAuthUser = new BehaviorSubject({
    user: firebase.auth.currentUser,
    userData: {},
    isAdmin: false,
})
const ROLE_ADMIN = 'admin'

setTimeout(() => {
    let unsubscribe
    firebase
        .auth
        .onAuthStateChanged(user => {
            unsubscribe && unsubscribe()
            if (!user?.uid) return rxAuthUser.next({})

            const handleResult = (userData = {}) => {
                const { blocked = false, roles = [] } = userData
                const isAdmin = roles.includes(ROLE_ADMIN)
                rxAuthUser.next({
                    isAdmin,
                    isBlocked: blocked,
                    user,
                    userData,
                })
            }
            const handleError = err => console.error('Failed to retrieve user data', err)

            // Auto update users collection entry for the logged in user
            unsubscribe = new FirebaseHelper('users')
                .subscribeById(
                    handleResult,
                    handleError,
                    user.uid,
                )
            rxAuthUser.next({
                isAdmin: false,
                isBlocked: null,
                user,
                userData: {},
            })
        })
})

/**
 * @name    useAuthUser
 * 
 * @returns {Object}
 */
export default () => useRxSubject(rxAuthUser)[0]