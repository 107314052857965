const INITIAL_STATE = {
    longitude: -122.41669,
    latitude: 37.7853,
    zoom: 14,
    minZoom: 13,
    pitch: 60,
    mapStyle: 'mapbox://styles/mapbox/streets-v11',
    hex: '2134',
    refresh: false,
}

export default function viewStateReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'SET_VIEWSTATE': {
            return {...state, ...action.payload}
        }
        default: return state;
    }
}
