import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    width: 296,
    backgroundColor: 'white',
    // boxShadow: '0px 3px 6px #00000096',
    transition: 'width .4s',
    top: 64,
    bottom: 0,
    maxHeight: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    // '&:hover': {
    //   overflowY: 'auto',
    // },
    '&::-webkit-scrollbar': {
      padding: '5px 0',
      width: 10,
    },
    '&::-webkit-scrollbar-track': {
      background: grey[100],
      margin: '1px 0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: grey[500],
      borderRadius: 5,
    },
  },
  hideobjroot: {
    display: 'none'
    // position: 'absolute',
    // zIndex: 0,
    // width: 300,
    // backgroundColor: 'white',
    // height: '100%',
    // boxShadow: '0px 3px 6px #00000096',
    // transition: 'width .4s',
    // top: 'auto',
    // bottom: 0,
    // borderBottomRightRadius: 18,
  },
  container: {
    // padding: '84px 35px 16px 24px', tf is this xddddd
    padding: "2rem",
    // ==fix start== for text and search input resizing when scrolbar visibility changes on (root) hover
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    // ==fix end==
  },
  title: {
    // fontSize: 32,
    fontSize: 20,
    fontWeight: 700,
    margin: '18px 0px 18px',
  },
  count: {
    fontSize: 14,
    margin: '0px 0px 16px 0px',
  },
  objectsContainer: {
    width: '100%',
  },
  search: {
    padding: 4,
    color: '#767070',
    fontSize: 16,
    fontWeight: 500,
    width: '100%',
    borderRadius: 18,
    border: '1px solid #00000021',
    boxShadow: '0px 2px 6px #00000096',
    paddingLeft: 8,

  },
  list: {
    marginTop: 16,
    // width: 240,
    // maxHeight: 240,
    width: 240,
    // maxHeight: 255,

    overflow: 'hidden',
    overflowY: 'auto',
    border: '1px solid #00000021',
    boxShadow: '0px 0px 6px #00000050',
    borderRadius: '18px 18px 18px 18px',
  },
  box: {
    background: 'red',
    width: 50,
    height: 100,
    marginBottom: 12,
  },
  objectOverlay: {
    background: 'transparent',
    height: 100,
    width: 200,
    position: 'absolute',
    zIndex: 10,
  },
  button: {
    padding: '8px 0px',
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'capitalize',
    display: 'flex',
    width: '100%',
    overflow: 'clip',
    borderRadius: 0,
    background: 'white',
    boxShadow: '0px 3px 6px #00000096',

    justifyContent: 'unset',
    padding: '5px 35px',
    textAlign: 'left',
  },
  selectedButton: {
    background: 'black',
    color: 'white',
    '&:hover': {
      background: '#00000096',
      color: 'white',
    },
  },
  mode: {
    margin: '8px 0px',
  },
  infoText: {
    marginBottom: 16,
  },
  searchIcon: {
    paddingRight: 8,
    width: 50,

  },
}));

export default useStyles;
