import { useEffect, useState } from "react"
import useAuthUser from "../common/useAuthUser"

/**
 * How to render an object in aframe?????
 *
 */

// const Object = ({ value }) => {
//   const classes = useStyles();
//   return (
//   <div>
//     <a-scene embedded className={classes.box}>
//       <a-assets>
//         <a-asset-item id="object" src={`models/${value}`}></a-asset-item>
//       </a-assets>

//       <a-plane></a-plane>
//       <a-gltf-model
//         scale=".1 .1 .1"
//         position="0 0 -5"
//         rotation="0 180 0"

//          src="#object"
//       ></a-gltf-model>

//       <a-sky color="#ECECEC"></a-sky>
//     </a-scene>
//   </div>
// )};

// project directory where model thumbnails are stored
export const thumbnailDir = 'images/thumbnails/'

// default model scaling settings for use with slider
export const scaleDefault = {
  max: 10,
  min: 0.05,
  start: 1, // initial scale when object is selected
}

export const allModels = [
  // {
  //   src: "coin2.gltf",
  //   isPublic: false,
  //   scale: {
  //     max: 50,
  //     min: 6,
  //     start: 30,
  //   },
  //   thumbnail: "coin.png"
  // },
  {
    src: "apartment1.glb",
    isPublic: true,
    scale: {
      max: 10,
      min: 0.07,
      start: 1,
    },
    thumbnail: "apartment.png"
  },
  {
    src: "apartment2.glb",
    isPublic: true,
    scale: {
      max: 10,
      min: 0.07,
      start: 1,
    },
    thumbnail: "apartment.png"
  },
  {
    src: "apartment3.glb",
    isPublic: true,
    scale: {
      max: 10,
      min: 0.07,
      start: 1,
    },
    thumbnail: "apartment.png"
  },
  {
    src: "apartment4.glb",
    isPublic: true,
    scale: {
      max: 10,
      min: 0.07,
      start: 1,
    },
    thumbnail: "apartment.png"
  },
  {
    src: "apartment5.glb",
    isPublic: true,
    scale: {
      max: 10,
      min: 0.07,
      start: 1,
    },
    thumbnail: "apartment.png"
  },
  {
    src: "apartment6.glb",
    isPublic: true,
    scale: {
      max: 10,
      min: 0.07,
      start: 1,
    },
    thumbnail: "apartment.png"
  },
  {
    src: "ballpark2.glb",
    isPublic: true,
    scale: {
      max: 20,
      min: 0.07,
      start: 2,
    },
    thumbnail: "stick.png"
  },
  {
    src: "ballpark3.glb",
    isPublic: true,
    scale: {
      max: 20,
      min: 0.07,
      start: 2,
    },
    thumbnail: "stick.png"
  },
  // {
  //   src: "coin3.glb",
  //   isPublic: false,
  //   scale: {
  //     max: 200,
  //     min: 20,
  //     start: 30,
  //   },
  //   thumbnail: "coin.png"
  // },
  {
    src: "corp1.glb",
    isPublic: true,
    thumbnail: "box.png"
  },
  {
    src: "corp2.glb",
    isPublic: true,
    thumbnail: "box.png"
  },
  {
    src: "corp3.glb",
    isPublic: true,
    thumbnail: "box.png"
  },
  {
    src: "corp4.glb",
    isPublic: true,
    thumbnail: "box.png"
  },
  {
    src: "corp5.glb",
    isPublic: true,
    thumbnail: "box.png"
  },
  {
    src: "corp6.glb",
    isPublic: true,
    thumbnail: "box.png"
  },
  {
    src: "corp7.glb",
    isPublic: true,
    thumbnail: "box.png"
  },
  {
    src: "highrise1.glb",
    isPublic: true,
    thumbnail: "building.png"
  },
  {
    src: "highrise2.glb",
    isPublic: true,
    thumbnail: "building.png"
  },
  {
    src: "highrise3.glb",
    isPublic: true,
    thumbnail: "building.png"
  },
  {
    src: "highrise4.glb",
    isPublic: true,
    thumbnail: "building.png"
  },
  {
    src: "cottage_1.glb",
    isPublic: true,
    scale: {
      max: 1,
      min: 0.02,
      start: 0.1,
    },
    thumbnail: "house.png"
  },
  {
    src: "cottage_2.glb",
    isPublic: true,
    scale: {
      max: 1,
      min: 0.02,
      start: 0.1,
    },
    thumbnail: "house.png"
  },
  {
    src: "Cottage_3.glb",
    isPublic: true,
    scale: {
      max: 1,
      min: 0.02,
      start: 0.1,
    },
    thumbnail: "house.png"
  },
  {
    src: "house_1.glb",
    isPublic: true,
    scale: {
      max: 1,
      min: 0.02,
      start: 0.1,
    },
    thumbnail: "house.png"
  },
  {
    src: "House_2.glb",
    isPublic: true,
    scale: {
      max: 1,
      min: 0.02,
      start: 0.1,
    },
    thumbnail: "house.png"
  },
  {
    src: "office_1.glb",
    isPublic: true,
    scale: {
      max: 0.25,
      min: 0.002,
      start: 0.05,
    },
    thumbnail: "building3.png"
  },
  {
    src: "Office_2.glb",
    isPublic: true,
    scale: {
      max: 0.25,
      min: 0.002,
      start: 0.05,
    },
    thumbnail: "building3.png"
  },
  {
    src: "Office_3.glb",
    isPublic: true,
    scale: {
      max: 0.25,
      min: 0.002,
      start: 0.05,
    },
    thumbnail: "building3.png"
  },
  {
    src: "shop1.glb",
    isPublic: true,
    scale: {
      max: 1,
      min: 0.006,
      start: 0.15,
    },
    thumbnail: "mall.png"
  },
  {
    src: "shop2.glb",
    isPublic: true,
    scale: {
      max: 1,
      min: 0.006,
      start: 0.15,
    },
    thumbnail: "mall.png"
  },
  {
    src: "Atari.glb",
    thumbnail: "atari.png",
    scale: {
      max: 10,
      min: 0.007,
      start: 1,
    },
    isPublic: false
  },
  {
    src: "Damac_towers.glb",
    thumbnail: "towers.png",
    scale: {
      max: 1.5,
      min: 0.015,
      start: 0.5,
    },
    isPublic: false
  },
  {
    src: "Hilton_Dubai.glb",
    thumbnail: "building2.png",
    scale: {
      max: 1.5,
      min: 0.15,
      start: 2.5,
    },
    isPublic: false
  },
  {
    src: "Qasr.glb",
    thumbnail: "building3.png",
    isPublic: false
  },
  {
    src: "Damac.glb",
    thumbnail: "building.png",
    isPublic: false
  },
  {
    src: "Atlantis_Palm_Dubai.glb",
    thumbnail: "building3.png",
    scale: {
      max: 10,
      min: 0.18,
      start: 2.5,
    },
    isPublic: false
  },
  {
    src: "DubaiMall.glb",
    thumbnail: "mall.png",
    isPublic: false
  },
  {
    src: "Jumeirah.glb",
    thumbnail: "bridge.png",
    scale: {
      max: 50,
      min: 0.25,
      start: 3.5,
    },
    isPublic: false
  },
  {
    src: "Marriott.glb",
    thumbnail: "building2.png",
    isPublic: false
  },
  {
    src: "Opera_Dubai.glb",
    thumbnail: "boat.png",
    isPublic: false
  },
  {
    src: "Burj Al Arab.glb",
    thumbnail: "tower.png",
    scale: {
      max: 20,
      min: 0.23,
      start: 5,
    },
    isPublic: false
  },
  {
    src: "Burj Khalifa.glb",
    thumbnail: "tower.png",
    scale: {
      max: 20,
      min: 0.18,
      start: 3,
    },
    isPublic: false
  },
  {
    src: "Cayan Tower.glb",
    thumbnail: "itower.png",
    isPublic: false
  },
  {
    src: "Dubai Frame.glb",
    thumbnail: "frame.png",
    isPublic: false
  },
  {
    src: "Dubai International Financial Center.glb",
    thumbnail: "stadium.png",
    isPublic: false
  },
  {
    src: "Emirates Towers.glb",
    thumbnail: "itower.png",
    isPublic: false
  },
  {
    src: "Opus.glb",
    thumbnail: "opus.png",
    isPublic: false
  },
  {
    src: "Sofitel Hotel.glb",
    thumbnail: "hotel.png",
    isPublic: false
  },
  {
    src: "Ritossa.glb",
    thumbnail: "statue.png",
    isPublic: false
  },
  {
    src: "Waldorf Astoria Palm.glb",
    thumbnail: "opus.png",
    isPublic: false
  },
  {
    src: "Sofitel Palm.glb",
    thumbnail: "Sofitel.png",
    isPublic: false
  },
  {
    src: "1 wall st.glb",
    thumbnail: "wall.png",
    isPublic: false
  },
  {
    src: "ATT stadium.glb",
    thumbnail: "stadium.png",
    isPublic: false
  },
  {
    src: "coca-cola-museum.glb",
    thumbnail: "museum.png",
    scale: {
      max: 30,
      min: 0.35,
      start: 5,
    },
    isPublic: false
  },
  {
    src: "airplane.glb",
    thumbnail: "plane.png",
    scale: {
      max: 5,
      min: 0.05,
      start: 2,
    },
    isPublic: false
  },
  {
    src: "capitol.glb",
    thumbnail: "box.png",
    scale: {
      max: 10,
      min: 0.1,
      start: 2,
    },
    isPublic: false
  },
  {
    src: "cruise.glb",
    thumbnail: "boat.png",
    scale: {
      max: 5,
      min: 0.02,
      start: 1,
    },
    isPublic: false
  },
  {
    src: "Figueroa Hotel.glb",
    thumbnail: "hotel.png",
    isPublic: false
  },
  {
    src: "golden-gate-bridge.glb",
    thumbnail: "bridge.png",
    scale: {
      max: 30,
      min: 0.03,
      start: 3,
    },
    isPublic: false
  },
  {
    src: "gundam.glb",
    thumbnail: "men.png",
    scale: {
      max: 100,
      min: 2,
      start: 5,
    },
    isPublic: false
  },
  {
    src: "factory.glb",
    thumbnail: "factory.png",
    isPublic: false
  },
  {
    src: "LA Convention Center.glb",
    thumbnail: "stadium.png",
    isPublic: false
  },
  {
    src: "LA Staples Center.glb",
    thumbnail: "stadium.png",
    isPublic: false
  },
  {
    src: "Raleigh PNC Arena.glb",
    thumbnail: "stadium.png",
    isPublic: false
  },
  {
    src: "luxor.glb",
    thumbnail: "cut.jpg",
    isPublic: false
  },
  {
    src: "madison-square-garden.glb",
    thumbnail: "sport.png",
    isPublic: false
  },
  {
    src: "met.glb",
    thumbnail: "ball.png",
    isPublic: false
  },
  {
    src: "metlife.glb",
    thumbnail: "ball.png",
    isPublic: false
  },
  {
    src: "Raymond James Stadium.glb",
    thumbnail: "stadium.png",
    isPublic: false
  },
  {
    src: "stadium.glb",
    thumbnail: "stadium.png",
    scale: {
      max: 5,
      min: 0.02,
      start: 1,
    },
    isPublic: false
  },
  {
    src: "smithsonian.glb",
    thumbnail: "house.png",
    scale: {
      max: 10,
      min: 0.12,
      start: 1.5,
    },
    isPublic: false
  },
  {
    src: "tardis.glb",
    thumbnail: "wall.png",
    scale: {
      max: 10,
      min: 0.2,
      start: 1,
    },
    isPublic: false
  },
  {
    src: "VendingMachine.glb",
    thumbnail: "wall.png",
    scale: {
      max: 50,
      min: 5,
      start: 10,
    },
    isPublic: false
  },
  {
    src: "white_house.glb",
    thumbnail: "house.png",
    scale: {
      max: 30,
      min: 0.2,
      start: 5,
    },
    isPublic: false
  },
  {
    src: "train_station.glb",
    thumbnail: "station.png",
    scale: {
      max: 5,
      min: 0.025,
      start: 0.75,
    },
    isPublic: false
  },
  {
    src: "ufo.glb",
    thumbnail: "ufo.png",
    scale: {
      max: 10,
      min: 0.1,
      start: 1,
    },
    isPublic: false
  },
  {
    src: "Steinway_Tower.glb",
    thumbnail: "itower.png",
    scale: {
      max: 0.1,
      min: 0.0015,
      start: 0.03,
    },
    isPublic: false
  },
  {
    src: "Bellagio.glb",
    thumbnail: "building2.png",
    scale: {
      max: 5,
      min: 0.03,
      start: 1,
    },
    isPublic: false
  },
  {
    src: "Carillon.glb",
    thumbnail: "building2.png",
    scale: {
      max: 0.075,
      min: 0.0006,
      start: 0.015,
    },
    isPublic: false
  },
  {
    src: "Loews.glb",
    thumbnail: "building2.png",
    scale: {
      max: 0.075,
      min: 0.0005,
      start: 0.015,
    },
    isPublic: false
  },
  {
    src: "MGM_Grand.glb",
    thumbnail: "hotel.png",
    scale: {
      max: 0.05,
      min: 0.00035,
      start: 0.05,
    },
    isPublic: false
  },
  {
    src: "One_Vanderbuilt.glb",
    thumbnail: "tower.png",
    scale: {
      max: 0.15,
      min: 0.0015,
      start: 0.05,
    },
    isPublic: false
  },
  {
    src: "Resorts_Would.glb",
    thumbnail: "hotel.png",
    scale: {
      max: 0.05,
      min: 0.00025,
      start: 0.005,
    },
    isPublic: false
  },
  {
    src: "Akoy.glb",
    thumbnail: "building2.png",
    scale: {
      max: 10,
      min: 0.08,
      start: 1,
    },
    isPublic: false
  },
  {
    src: "Sony.glb",
    thumbnail: "building2.png",
    scale: {
      max: 0.1,
      min: 0.0015,
      start: 0.05,
    },
    isPublic: false
  },
  {
    src: "allegiant.glb",
    thumbnail: "stadium.png",
    scale: {
      max: 1,
      min: 0.012,
      start: 0.3,
    },
    isPublic: false
  },
  {
    src: "Bank.glb",
    thumbnail: "wall.png",
    scale: {
      max: 0.1,
      min: 0.0012,
      start: 0.05,
    },
    isPublic: false
  },
  {
    src: "Center_part_tower_residense.glb",
    thumbnail: "stadium.png",
    scale: {
      max: 0.1,
      min: 0.0018,
      start: 0.03,
    },
    isPublic: false
  },
  {
    src: "Crysler.glb",
    thumbnail: "tower.png",
    scale: {
      max: 0.2,
      min: 0.0018,
      start: 0.05,
    },
    isPublic: false
  },
  {
    src: "Empire.glb",
    thumbnail: "tower.png",
    scale: {
      max: 0.15,
      min: 0.001,
      start: 0.02,
    },
    isPublic: false
  },
  {
    src: "Hearst.glb",
    thumbnail: "tower.png",
    scale: {
      max: 0.1,
      min: 0.0015,
      start: 0.015,
    },
    isPublic: false
  },
  {
    src: "Sheraton.glb",
    thumbnail: "hotel.png",
    scale: {
      max: 0.15,
      min: 0.0015,
      start: 0.05,
    },
    isPublic: false
  },
  {
    src: "Strat.glb",
    thumbnail: "tower.png",
    scale: {
      max: 0.15,
      min: 0.0007,
      start: 0.03,
    },
    isPublic: false
  },
  {
    src: "Times_square4.glb",
    thumbnail: "tower.png",
    scale: {
      max: 0.15,
      min: 0.0014,
      start: 0.1,
    },
    isPublic: false
  },
  {
    src: "Trump_tower.glb",
    thumbnail: "towers.png",
    scale: {
      max: 0.1,
      min: 0.0015,
      start: 0.025,
    },
    isPublic: false
  },
  {
    src: "WTC.glb",
    thumbnail: "towers.png",
    isPublic: false
  }
]
  .map(x => ({
    ...x,
    displayName: x.displayName || x.src.split('.')[0]
  }))

/**
 * @name  useModels
 * 
 * @param {String} keywords filter by keywords
 * 
 */
export default function useModels(keywords = '') {
  const { isAdmin } = useAuthUser()
  const [models, setModels] = useState([])

  useEffect(() => {
    const models =
      allModels
        .filter(model => {
          const { isPublic, src = '' } = model
          if (!isPublic && !isAdmin) return
          return !keywords
            || src.includes(
              keywords.toLowerCase()
            )
        })
    setModels(models)
  }, [isAdmin, keywords])

  return [models]
}