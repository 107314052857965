import { kRing } from 'h3-js';
import { getHexCoordinates } from './models';

export const getLimitDistance = ([x, y], userHexes, singleHexRad) => {
  // find nearest hex to x, y
  let nearest = userHexes[0];
  userHexes.forEach((hex) => {
    const [xCoordinate, yCoordinate] = getHexCoordinates(hex);
    const distance = Math.sqrt((x - xCoordinate) ** 2 + (y - yCoordinate) ** 2);

    const [nearestX, nearestY] = getHexCoordinates(nearest);
    const nearestDistance = Math.sqrt((x - nearestX) ** 2 + (y - nearestY) ** 2);

    nearest = distance < nearestDistance ? hex : nearest;
  });

  // console.log(`x: ${x}, y: ${y}`);
  // console.log(`nearestX: ${getHexCoordinates(nearest)[0]}, nearestY: ${getHexCoordinates(nearest)[1]}`);

  // get hexes rings until reaching a non-user hex
  let maxHexRad = 1;
  while (maxHexRad) {
    const ring = kRing(nearest, maxHexRad);
    const notInUserHexes = ring.filter((hex) => !userHexes.includes(hex));
    if (notInUserHexes.length > 0) break;
    maxHexRad++;
  }
  // console.log('maxHex', maxHexRad);

  const distance = maxHexRad * singleHexRad;
  // console.log('distance', distance);

  return distance;
};
