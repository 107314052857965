import React, { useState, useRef, useEffect } from 'react';

import {
  Typography,
  Table,
  TableContainer,
  TextField,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Switch,
  Button,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ClearIcon from '@material-ui/icons/Clear';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';

import useStyles from './EditObjectBar.styles';
import app from 'firebase/app';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ColorPicker from 'material-ui-color-picker';
import Slider from '../Slider';
import { scaleDefault } from '../Drawer/objectFiles';
import { getModelInfo } from '../../utils/models';
const convert = require('color-convert');

const RotationSlider = withStyles({
  track: {
    height: 10,
  },
})(Slider);

const EditObjectBar = ({
  despt,
  color,
  contentid,
  draftObjectDetails,
  setDraftObjectDetails,
  name,
  position,
  removeObj,
  scale,
  setColor,
  displayEditBar,
  setDisplayEditBar,
  setName,
  setUrl,
  setDespt,
  setEditname,
  url,
  hexRadius,
  objectInfo,
  setShowUpdateOptions,
  updatingObject,
  setContentid,
  sliderScale,
  cancel,
}) => {
  const [linkScales, setLinkScales] = useState(true);
  const [localColor, setLocalColor] = useState(`#${convert.rgb.hex(color)}`);

  const previousObjectDetails = useRef(draftObjectDetails);
  const step = 1 / sliderScale;

  const { max = scaleDefault.max, min } = scale || {};

  const handleSlide = (scaleNum, index) => {
    const newState = [...draftObjectDetails];
    newState[index] = scaleNum;
    setDraftObjectDetails(newState);
  };

  const handleAttach = (scaleNum) => {
    if (scaleNum <= max) {
      const newState = [...draftObjectDetails];
      newState[3] = scaleNum;
      newState[4] = scaleNum;
      newState[5] = scaleNum;
      setDraftObjectDetails(newState);
    }
  };

  const handleColor = (c) => {
    // return if undefined
    if (!c) return;
    setLocalColor(c);
    // if hex
    if (c[0] === '#') return setColor([...convert.hex.rgb(c), 255]);
    // if rgba
    const returnColor = c
      .slice(5, -1)
      .split(', ')
      .map((i) => i * 1);
    returnColor[3] = Math.floor(returnColor[3] * 255);
    return setColor(returnColor);
  };

  function handleUpdate() {
    app
      .firestore()
      .collection('objects')
      .doc(contentid)
      .update({
        label: name,
        url,
        description: despt,
        color,
        rotation: [draftObjectDetails[0], draftObjectDetails[1], draftObjectDetails[2]],
        scale: [
          draftObjectDetails[3], // x
          draftObjectDetails[4], // y
          draftObjectDetails[5], // z
        ],
        location: [position[0], position[1], draftObjectDetails[6]],
      })
      .then((res) => {
        toast.dark(`Object properties edited sucessfully !!`);
        setEditname(false);
        setDisplayEditBar(false);
        setShowUpdateOptions(false);
        updatingObject.current = false;
        setContentid('');
      })
      .catch((err) => toast.error(err.message));
  }

  const setDefaultObjectScale = async () => {
    const modelDimensions = await getModelInfo(objectInfo.object.source);
    if (modelDimensions) {
      const hexDiameter = hexRadius * 2;

      const maxModelWidth = Math.max(modelDimensions.x, modelDimensions.y);
      const scaleFactor = Math.floor((hexDiameter / maxModelWidth) * sliderScale) / sliderScale;

      const newObjectDetails = [...draftObjectDetails];
      newObjectDetails[3] = scaleFactor;
      newObjectDetails[4] = scaleFactor;
      newObjectDetails[5] = scaleFactor;
      setDraftObjectDetails(newObjectDetails);
    }
  };

  useEffect(() => {
    const { object: info } = objectInfo;
    previousObjectDetails.current = [
      info.rotation[0],
      info.rotation[1],
      info.rotation[2],
      info.scale[0],
      info.scale[1],
      info.scale[2],
      info.location[0],
      info.location[1],
      info.location[2],
      info.url,
    ];
  }, [objectInfo]);

  useEffect(() => {
    setLocalColor(`#${convert.rgb.hex(color)}`);
  }, [color]);

  const classes = useStyles();

  return (
    <div className={displayEditBar ? classes.root : classes.hideprop}>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <Typography variant="h6">Edit Object</Typography>
        <Button onClick={() => cancel(false, previousObjectDetails)} color="secondary" variant="outlined">
          Cancel
        </Button>
      </div>
      <div>
        <Typography className={classes.editformlabel}>Label</Typography>
        <TextField
          InputProps={{ classes: { input: classes.editInputArea } }}
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          variant="outlined"
        />
      </div>
      <div>
        <Typography className={classes.editformlabel}>URL</Typography>
        <TextField
          InputProps={{ classes: { input: classes.editInputArea } }}
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          type="text"
          variant="outlined"
          placeholder="https://example.com"
        />
      </div>
      <div>
        <Typography className={classes.editformlabel}>Description</Typography>
        <TextField
          InputProps={{ classes: { input: classes.editInputArea } }}
          value={despt}
          onChange={(e) => setDespt(e.target.value)}
          type="text"
          variant="outlined"
        />
      </div>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            margin: '10px 0',
            gap: '2rem',
          }}
        >
          <Typography className={classes.editformlabel} style={{ marginTop: 0 }}>
            Color
          </Typography>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => {
              setColor([255, 255, 255, 255]);
              setLocalColor('#fff');
            }}
          >
            Default
          </Button>
        </div>
        <ColorPicker
          className={classes.colorSelector}
          name="color"
          value={localColor}
          TextFieldProps={{
            variant: 'outlined',
            style: { background: localColor, color: localColor, padding: 4 },
            className: classes.input,
          }}
          InputProps={{ classes: { input: classes.input } }}
          onChange={handleColor}
        />
      </div>
      <div>
        <Typography className={classes.editslidelabel} style={{ marginTop: '1.6rem' }}>
          Height
        </Typography>
        <Slider
          min={0}
          max={100}
          value={draftObjectDetails[6]}
          handleChange={(e, v) => handleSlide(v, 6)}
          valueLabelDisplay="auto"
          getAriaValueText={draftObjectDetails[6]}
        />
      </div>
      <div>
        <Typography className={classes.editslidelabel}>Rotation</Typography>
        <Slider
          label="axis"
          min={-180}
          max={180}
          step={5}
          value={draftObjectDetails[1]}
          handleChange={(e, v) => handleSlide(v, 1)}
          valueLabelDisplay="auto"
          track={false}
          getAriaValueText={draftObjectDetails[1]}
        />
      </div>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '5px',
          }}
        >
          <Typography className={classes.editformlabel} style={{ marginTop: 0 }}>
            Scale
          </Typography>
          <Button variant="outlined" onClick={setDefaultObjectScale}>
            default scale
          </Button>
        </div>
        <div>
          <IconButton onClick={() => setLinkScales(!linkScales)}>
            {linkScales ? <LinkIcon /> : <LinkOffIcon />}
          </IconButton>{' '}
          Link Axes: {linkScales ? 'On' : 'Off'}
        </div>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" align="left" className={classes.tdslide}>
                  <Slider
                    label="x-axis"
                    min={min}
                    max={max}
                    step={step}
                    value={draftObjectDetails[3]}
                    handleChange={(e, v) => {
                      linkScales ? handleAttach(v) : handleSlide(v, 3);
                    }}
                    valueLabelDisplay="auto"
                    getAriaValueText={draftObjectDetails[3]}
                  />
                </TableCell>
                <TableCell align="left" className={classes.tdfield}>
                  <TextField
                    type="number"
                    min={min}
                    max={max}
                    step={step}
                    value={draftObjectDetails[3] * sliderScale}
                    onChange={(e) => {
                      linkScales
                        ? handleAttach(e.target.value / sliderScale)
                        : handleSlide(e.target.value / sliderScale, 3);
                    }}
                    variant="outlined"
                    inputProps={{
                      style: {
                        padding: `12.5px 0px`,
                        textAlign: 'center',
                        fontSize: 'small',
                        width: '70px',
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="left" className={classes.tdslide}>
                  <Slider
                    label="y-axis"
                    min={min}
                    max={linkScales ? max : Math.max(max, 1)}
                    step={step}
                    value={draftObjectDetails[4]}
                    handleChange={(e, v) => {
                      linkScales ? handleAttach(v) : handleSlide(v, 4);
                    }}
                    valueLabelDisplay="auto"
                    getAriaValueText={draftObjectDetails[4]}
                  />
                </TableCell>
                <TableCell align="left" className={classes.tdfield}>
                  <TextField
                    type="number"
                    min={min}
                    max={linkScales ? max : Math.max(max, 1)}
                    step={step}
                    value={draftObjectDetails[4] * sliderScale}
                    onChange={(e) => {
                      linkScales
                        ? handleAttach(e.target.value / sliderScale)
                        : handleSlide(e.target.value / sliderScale, 4);
                    }}
                    variant="outlined"
                    inputProps={{
                      style: {
                        padding: `12.5px 0px`,
                        textAlign: 'center',
                        fontSize: 'small',
                        width: '70px',
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="left" className={classes.tdslide}>
                  <Slider
                    label="z-axis"
                    min={min}
                    max={max}
                    step={step}
                    value={draftObjectDetails[5]}
                    handleChange={(e, v) => {
                      linkScales ? handleAttach(v) : handleSlide(v, 5);
                    }}
                    valueLabelDisplay="auto"
                    getAriaValueText={draftObjectDetails[5]}
                  />
                </TableCell>
                <TableCell align="left" className={classes.tdfield}>
                  <TextField
                    type="number"
                    min={min}
                    max={max}
                    step={step}
                    value={draftObjectDetails[5] * sliderScale}
                    onChange={(e) => {
                      linkScales
                        ? handleAttach(e.target.value / sliderScale)
                        : handleSlide(e.target.value / sliderScale, 5);
                    }}
                    variant="outlined"
                    inputProps={{
                      style: {
                        padding: `12.5px 0px`,
                        textAlign: 'center',
                        fontSize: 'small',
                        width: '70px',
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          onClick={handleUpdate}
          color="primary"
          variant="contained"
          style={{ width: '40%' }}
          autoFocus
        >
          Save
        </Button>
        <Button
          onClick={() => {
            removeObj();
            setDisplayEditBar(false);
          }}
          color="secondary"
          variant="contained"
          startIcon={<ClearIcon />}
          style={{ width: '40%' }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default EditObjectBar;
