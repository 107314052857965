import { Button, CircularProgress, Grid } from '@material-ui/core';
import DropIn from 'braintree-web-drop-in-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Axios from '../api';
import { isFn } from '../common/utils';
import useStyles from './Shop.styles';
import "./Stripe.css"

const PaymentModal = ({ amount, setSucceeded, creditsPurchased, user, onSuccess }) => {
    const [authToken, setAuthToken] = useState({
        clientToken: '',
        loading: false,
        instance: {}
    });
    const [processingPayment, setProcessingPayment] = useState(false);

    useEffect(() => {
        const getToken = async () => {
            authToken.loading = true
            setAuthToken({ ...authToken });
            try {
                let axios = new Axios();
                const data = await axios.getToken()
                authToken.clientToken = data.clientToken
            } catch (err) {
                toast.error(err.message || err.response.data.error.message)
            }

            console.log({ authToken })
            authToken.loading = false
            setAuthToken({ ...authToken })
        }
        getToken()
    }, [])

    const makePayment = async () => {
        setProcessingPayment(true);
        let nonce = '';
        await authToken.instance.requestPaymentMethod()
            .then(data => nonce = data.nonce)
            .catch(e => {
                setProcessingPayment(false);
            });
        let axios = new Axios();
        if (nonce) {
            let paymentObj = {
                amount, // amount to be paid
                creditsPurchased, // number of credits to receive
                paymentMethodNonce: nonce,
                userId: user.uid
            }
            await axios.processPayment(paymentObj).then(async (res) => {
                if (res.data.data.success) {
                    setSucceeded(true);
                    setProcessingPayment(false);
                    isFn(onSuccess) && onSuccess(true)
                } else {
                    toast.error('Something went wrong!');
                    setProcessingPayment(false);
                }
            }).catch(e => {
                console.log('Payment failed', e)
                toast.error('Something went wrong!');
                setProcessingPayment(false);
            })
        }
        setProcessingPayment(false);
    }

    const BraintreeDropin = () => (
        <>
            {authToken.clientToken
                ? (
                    <div className="buyButton">
                        <DropIn
                            options={{
                                authorization: authToken.clientToken,
                                paypal: {
                                    amount,
                                    currency: 'USD',
                                    flow: 'checkout',
                                }
                            }}
                            onInstance={(instance) => setAuthToken({ ...authToken, instance })}
                        />
                        <Button
                            variant="outlined"
                            style={{ padding: "10px 16px" }}
                            onClick={makePayment}
                            disabled={processingPayment}
                            fullWidth
                        >{processingPayment ? "Processing payment" : "Pay"}</Button>
                    </div>
                )
                : (
                    <Grid container direction="row" justify="center" alignItems="center">
                        {authToken.loading
                            ? <CircularProgress color="inherit" />
                            : <h1>Payment method currently unavailable!</h1>
                        }
                    </Grid>
                )}
        </>
    )

    return (
        <>
            {BraintreeDropin()}
        </>
    )
}

export default PaymentModal;
