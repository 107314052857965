import { BehaviorSubject } from 'rxjs'
import FirestoreHelper from './FirestoreHelper'

export const rxPublicConfig = new BehaviorSubject({})

export const rxHeaderEnabled = new BehaviorSubject(true)

// Subscribe to automatically retrieve public config
new FirestoreHelper('config')
    .subscribeById(
        doc => rxPublicConfig.next(doc),
        err => console.error('Failed to retrieve config', err),
        'public',
    )