// import { makeStyles } from '@material-ui/styles';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 84,
  },
  body: {
    height: '100vh',
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center'
  },
  container: {
    margin: 'auto',
    maxWidth: 500,
    textAlign: 'left',
    padding: 24,
  },
  title: {
    fontSize: 32,
    fontWeight: 700,
    margin: 0,
    paddingTop: 48,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 24,
    },
  },
  subtitle: {
    color: 'green',
    fontSize: 20,
    fontStyle: 'italic',
  },
  info: {
    margin: '24px 0px 24px 0px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: '8px 0px 16px 0px',
    },
  },
  itemContainer: {},
  lineItemButton: {
    padding: '24px 24px',
    marginBottom: 16,
    display: 'block',
    width: '100%',
    borderRadius: 18,
    boxShadow: '0px 0px 6px #00000042',
    textTransform: 'none',
  },
  lineItemButtonMobile: {
    padding: '24px 0',
    whiteSpace: 'nowrap',
  },
  selected: {
    background: '#1B2032',
    color: 'white',
    '&:hover': {
      background: '#1B2032',
    },
  },
  lineItemGrid: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalRow: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: 44,
    padding: '24px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '24px 24px',
    },
  },
  totalLabel: {
    fontSize: 20,
    lineHeight: '54px',
  },
  totalValue: {
    fontSize: 24,
  },
  checkoutButton: {
    padding: '24px 24px',
    fontSize: 19,
    display: 'block',
    width: '100%',
    borderRadius: 18,
    boxShadow: '0px 0px 6px #00000042',
    textTransform: 'none',
    backgroundSize: '330% 100%',
    background:
      'linear-gradient(90deg, rgba(200,200,200,1) 33%, #008873 33%, #008873 66%, #00CB7D 66%)',
    transition: 'color .4s, background-position .5s',
    color: 'white',
    marginBottom: 300,
    backgroundPosition: '-44% 0%',
    '&:hover': {
      backgroundPosition: '-94% 0%',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '24px 24px',
    },
  },
  buttonSaving: {
    color: '#008873',
    pointerEvents: 'none',
  },
  disabled: {
    backgroundPosition: '0% 0%',
    '&:hover': {
      backgroundPosition: '0% 0%',
    },
    pointerEvents: 'none',
  },
  stripeCard: {
    maxWidth: 500,
    width: '100%',
    background: 'white',
    zIndex: 10,
    top: '12%',
    position: 'absolute',
    borderRadius: 19,
    boxShadow: '0px 3px 6px #00000042',
  },
  stripeWrapper: {
    display: 'flex',
    zIndex: 10,
    justifyContent: 'center',
    width: '100%',
    position: 'absolute',
    background: '#00000042',
    height: '100%',
  },
  radioLabel: {
    marginRight: 20,
    fontSize: 20,
    lineHeight: '50px',
  },
  radioFromControl: {
    marginBottom: 30
  },
  creditsDiscounted: {
    fontWeight: 'bold',
  },
  creditsDiscountedOriginal: {
    color: 'grey',
    textDecoration: 'line-through',
  },
  paymentModalStyle: {
    zIndex: 998, // Forces the modal to be underneath moneybutton login modal (1001) and error modal (999)
  },
  ul: {
    margin: 0,
  }
}));

export default useStyles;
