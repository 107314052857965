import firebase from '../Firebase'
import saveBatch from './saveBatch'
import { addTs, isObj, isStr, isFn, isValidNumber, isArr } from './utils'

/**
 * @name    FirestoreHelper
 * @summary Firebase query helper class for most basic operations such as saving and retrieving documents.
 *          Beaware that certain queries may require creating indexes beforehand. 
 *          For more complex actions, please create a custom Class by extending FirestoreHelper class.
 * 
 * @param   {String}    collectionName
 * @param   {String}    idKey           property that contains the ID. 
 *                              (As convention the ID of the document should also be part the of object)
 * @param   {String}    firestore   (optional)
 */
export default class FirestoreHelper {
    constructor(collectionName, idKey = 'id', firestore = firebase.firestore) {
        this.collectionName = collectionName
        this.idKey = idKey
        this.db = firestore
            .collection(collectionName)
    }

    /**
     * @name    get
     * @summary retrieve document by ID
     * 
     * @param   {String} id 
     * 
     * @returns {Object}
     */
    get = async (id) => {
        const result = await this.db
            .doc(id)
            .get()
        const doc = result.data()

        if (doc) {
            // makes sure document contains the ID property
            doc[this.idKey] = doc[this.idKey] || id
        }

        return doc
    }

    /**
     * @name    query
     * @summary constructs query to be used by search and subscribe functions
     *          Beaware that certain queries may require creating indexes beforehand.
     *          
     *
     * @param   {Array}     where       2D array of arguments to construct a queery using db.where(), db.orderBy() etc.
     *                                  See below for examples on the `FirestoreHelper.search` function.
     * @param   {String}    where[i][0] path/property name
     * @param   {*}         where[i][1] condition
     * @param   {*}         where[i][2] value to match
     * @param   {String}    where[i][3] name of the function property (Eg: db.where, db.orderBy, db.startAtr).
     *                                  Default: 'where'
     * @param   {Number}    limit       maximum number of items to retrieve
     *
     * @returns {*} query
     */
    query = (where = [], limit = 0) => {
        if ((!isArr(where) || !where.length) && !limit) throw new Error('Missing query parameters')

        let query = this.db
        where = where.filter(x => isArr(x) && x.length > 2)
        for (let i = 0; i < where.length; i++) {
            const [
                property,
                condition,
                value,
                func = 'where',
            ] = where[i]

            if (!isFn(query[func])) throw new Error(`Invalid query: "${func}" is not a function`)

            query = query[func](property, condition, value)
        }

        if (isValidNumber(limit) && limit) query = query.limit(limit)
        // if (isValidNumber(offset) && offset) query.offset(offset)
        return query
    }

    /**
     * @name    save
     * @summary add/update document
     * 
     * @param   {Object}    doc     document to store
     * @param   {String}    id      document ID.
     * @param   {Boolean}   merge   (optional) whether to merge or override any existing document
     *                              Default: `true`
     */
    save = async (doc, id, merge = true) => {
        if (!isStr(id)) throw new Error('ID required')
        if (!isObj(doc)) throw new Error('Invalid document')

        // add timestamps
        addTs(doc)

        // attach ID property if not already exists
        doc[this.idKey] = doc[this.idKey] || id
        return await this.db
            .doc(id)
            .set(doc, { merge })
    }

    /**
     * @name    saveBatch
     * @summary save documents in a single batch request.
     * 
     * @param   {Object}    docs
     * @param   {Boolean}   merge   whether to merge with existing documents or override
     * 
     * @returns {*} result
     */
    saveBatch = async (docs, merge = true) => await saveBatch(
        docs,
        this.db,
        merge,
        this.idKey,
    ).catch(err =>
        new Error(`Firestore batch documents save failed. Error: ${err.message}`)
    )

    /**
     * @name    search
     * @summary perform simple search collection.
     *          Beaware that certain queries may require creating indexes beforehand.
     * 
     * @param   {Array}     where   2D array of arguments to be supplied to construct db.where() and db.orderBy() etc.
     *                              See below for examples.
     * @param   {Number}    limit   maximum number of items to retrieve
     * @param   {Number}    offset  number of items to skip
     * 
     * @returns {Array} documents
     * 
     * @example ```javascript
     *      const instance = new FirestoreHelper('users')
     *      
     *      // Simple example: retrieve 101 to 110th users with status active
     *      const result = await instance.search([
     *          [ 'status', '==', 'active' ],
     *      ], 10, 100)
     * 
     * 
     *      // Complex example: retrieve max 10 users with 5000+ credits sort by credits in descending order
     *      const result = await instance.search([
     *          [ 'status', '==', 'active' ], // default function is 'where'
     *          [ 'credits', 'desc', null, 'orderBy' ],
     *          [ 'credits', '>=', 5000 ],
     *      ], 10)
     * 
     * ```
     */
    search = async (where = [], limit = 0, offset = 0) => {
        const docs = []
        const query = this.query(where, limit, offset)
        const result = await query.get()
        result.forEach(entry => {
            const doc = entry.data()
            doc[this.idKey] = doc[this.idKey] || entry.id
            docs.push(doc)
        })
        return docs
    }

    /**
     * @name    subscribe
     * @summary subscribe to conditionally retrieve documents.
     *          Beaware that certain queries may require creating indexes beforehand.
     * 
     * @param   {Function}  onResult    callback to be invoked whenever documents received. Argument: `result`
     * @param   {Function}  onError     callback to be invoked on error. Argument: `error`
     * @param   {Array}     where       2D array to construct db.where, db.orderBy etc. See `search` for examples.
     * @param   {Number}    limit       maximum number of items to retrieve
     * @param   {Number}    offset      number of items to skip
     * 
     * @returns {Function}  function to unsubscribe
     */
    subscribe = (onResult, onError, where = [], limit = 0, offset = 0) => {
        const query = this.query(where, limit, offset)

        const handleResult = result => {
            const data = []
            const addedIds = []
            const modifiedIds = []
            const removedIds = []
            result.forEach(entry => {
                const doc = entry.data()
                doc[this.idKey] = entry.id
                data.push(doc)
            })
            result
                .docChanges()
                .forEach((change) => {
                    const { id } = change.doc
                    switch (change.type) {
                        case 'added':
                            addedIds.push(id)
                            break
                        case 'modified':
                            modifiedIds.push(id)
                            break
                        case 'removed':
                            removedIds.push(id)
                            break
                    }
                })
            onResult(data, { addedIds, modifiedIds, removedIds })
        }
        return query.onSnapshot(handleResult, onError)
    }

    /**
     * @name    subscribeById
     * @summary subscribe to a single document changes by ID
     * 
     * @param   {Function}  onResult
     * @param   {Function}  onError
     * @param   {String}    id 
     * 
     * @returns {Function}  unsubscribe
     */
    subscribeById = (onResult, onError, id) => this.db
        .doc(id)
        .onSnapshot(
            result => {
                const doc = result.data()
                if (doc) {
                    doc[this.idKey] = result.id
                }
                onResult(doc, result.id)
            },
            onError
        )
}