import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import './PaymentGateways.css';
import shopStyles from './Shop.styles';
import DropIn from "braintree-web-drop-in-react";
import Axios from "../api/axios";
import { toast } from "react-toastify";
import PaymentModal from './Braintree';
import FormInput from '../common/form/FormInput'

import useStyles from './PaymentForm.styles';
import PayButton, { ASSET } from '../MoneyButton/PayButton';
import { isMobile } from 'react-device-detect';
import { className, isFn } from '../common/utils';

function LineItemButton({
  className, onClick, selected, text
}) {
  const classes = shopStyles()
  const classNames = [
    className,
    classes.lineItemButton,
    isMobile && classes.lineItemButtonMobile,
    selected && classes.selected,
  ]
    .filter(Boolean)
    .join(' ')
  return (
    <Button
      onClick={onClick}
      // style={{ padding: "14px 24px", fontSize: "16px" }}
      className={classNames}
    >
      {text}
    </Button>
  );
}
const PAYMENT_PROVIDER = {
  moneybutton: 'moneybutton',
  paypal: 'paypal',
  stripe: 'stripe',
}
export const CREDIT_TYPE = {
  credit: 'credit',
  omni_token: 'omni_token',
}

export default function PaymentForm({
  onClose,
  selected,
  setStripe,
  price,
  creditsPurchased,
  firebase,
  user,
  moneytype,
  onSuccess,
}) {
  const allowBSV = moneytype === CREDIT_TYPE.omni_token
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [provider, setProvider] = useState(
    allowBSV
      ? PAYMENT_PROVIDER.moneybutton
      : PAYMENT_PROVIDER.stripe
  )
  const [email, setEmail] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (user.email) setEmail(user.email)
    if (provider === PAYMENT_PROVIDER.stripe) createPaymentIntent()
  }, [provider])

  const createPaymentIntent = async () => {
    let axios = new Axios();
    setDisabled(true)
    try {
      const result = await axios.createPaymentIntent(
        selected,
        moneytype,
        creditsPurchased,
      )
      setClientSecret(result?.data?.clientSecret)
    } catch (err) {
      setError(err)
    }
    setDisabled(false)
  }

  const cardStyle = {
    style: {
      base: {
        color: '#262c26',
        borderRadius: 18,
        width: 300,
        fontFamily: 'Roboto, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#262c26',
        },
      },

      invalid: {
        color: '#ff21ca',
        iconColor: '#ff21ca',
      },
    },
  };

  const handleBSVPayment = paymentData => async (paymentResult) => {
    const { id, txid, } = paymentResult || {}
    try {
      setProcessing(true)
      await new Axios()
        .confirmPaymentForOmniToken({
          ...paymentData,
          provider: PAYMENT_PROVIDER.moneybutton,
          providerReference: id,
        })
      setSucceeded(true)
      isFn(onSuccess) && onSuccess(true)
    } catch (err) {
      const msg = 'Failed to process your request. '
        + 'Please contact support with the following information:'
        + `\n\nBundle: US $${price}`
        + `\nPaymentMethod: BSV`
        + `\nPaymentID: ${id}`
        + `\nTxID: ${txid}`
      console.log(msg)
      alert(msg)
    }
    setProcessing(false)
  }

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    setProcessing(true);

    const name = ev.target.name
    const payload = await stripe.confirmCardPayment(clientSecret, {
      receipt_email: email,
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: !name
          ? undefined
          : { name },
      },
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      isFn(onSuccess) && onSuccess(true)
    }
  };

  let paymentForm = ''
  switch (provider) {
    case PAYMENT_PROVIDER.moneybutton:
      const paymentData = {
        amountPaid: price,
        creditsPurchased,
        creditType: moneytype,
        userId: user.uid,
      }
      paymentForm = !allowBSV
        ? 'Payment with Bitcoin SV (BSV) is only available when buying OmniToken'
        : (
          <div className={classes.bsvContainer}>
            <div className={classes.label}>
              Pay with Bitcon SV (BSV):
            </div>
            <PayButton {...{
              amount: `${price}`,
              asset: ASSET.BSV,
              currency: 'USD',
              buttonData: paymentData,
              // DO NOT CHANGE. buttonId along with buttonData will be used to verify payment
              buttonId: 'Buy-OmniToken',
              label: 'in BSV',
              onError: console.warn,
              onPayment: handleBSVPayment(paymentData),
            }} />

            <div>
              <small>
                (drag the blue circle to the right to pay)
              </small>
            </div>
          </div>
        )
      break
    case PAYMENT_PROVIDER.paypal:
      paymentForm = (
        <PaymentModal {...{
          amount: price,
          creditsPurchased,
          firebase,
          onSuccess,
          setSucceeded,
          user,
        }} />
      )
      break
    case PAYMENT_PROVIDER.stripe:
      paymentForm = (
        <div className={classes.stripeContainer}>
          <div className={classes.label}>
            Email:
          </div>
          <input {...{
            className: classes.email,
            type: 'email',
            value: email,
            onChange: e => setEmail(e.target.value),
            placeholder: 'Enter email address',
          }} />

          <div className={classes.label}>
            Credit/Debit Card:
          </div>
          <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
          <div className={classes.label}>
            Amount: ${price}
          </div>
          <Button
            className={classes.submit}
            disabled={processing || disabled || succeeded || !clientSecret}
            id="submit"
            onClick={!processing && !disabled && handleSubmit}
          >
            {clientSecret == ''
              ? (
                <span id="button-text" className='loading'>
                  Loading...
                </span>
              )
              : (
                <span id="button-text">
                  {processing ? <div className="spinner" id="spinner" /> : 'Pay'}
                </span>
              )}
          </Button>
        </div>
      )
      break
  }

  const rootClassNames = [
    classes.root,
    isMobile && classes.rootMobile,
  ]
    .filter(Boolean)
    .join(' ')
  return (
    <Grid className={rootClassNames} id="payment-form">
      {!succeeded && (
        <div>
          <Typography className={classes.title}>Pay With</Typography>
          <Grid
            container
            className={"switch-field"}
            xs={12}
            justify={!isMobile && 'space-between'}
          >
            <Grid item xs={5} className="margin">
              <LineItemButton
                text='Credit Card'
                onClick={() => setProvider(PAYMENT_PROVIDER.stripe)}
                selected={provider === PAYMENT_PROVIDER.stripe} />
            </Grid>
            {/* <Grid item xs={5} className="margin">
              <LineItemButton
                text='Paypal'
                onClick={() => setProvider(PAYMENT_PROVIDER.paypal)}
                selected={provider === PAYMENT_PROVIDER.paypal} />
            </Grid> */}
            <Grid item xs={5} className="margin">
              <LineItemButton
                className={classes.lineItemButtonMobileBSV}
                text='Bitcoin SV (BSV)'
                onClick={() => setProvider(PAYMENT_PROVIDER.moneybutton)}
                selected={provider === PAYMENT_PROVIDER.moneybutton} />
            </Grid>
          </Grid>
          <div>
            {paymentForm}
          </div>
        </div>
      )}
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}

      {/* Show a success message upon completion */}
      <div className={className([
        'result-message',
        !succeeded && 'hidden',
      ])}>
        <Typography className={classes.title}>Payment Successful</Typography>
        <Typography>
          {moneytype === CREDIT_TYPE.omni_token
            ? 'Your OmniTokens will be sent to your moneybutton.com account momentarily. '
            : 'Credits will be added to your account momentarily. '}
          Time to go claim those locations!
        </Typography>
        <br />
        <div className={classes.buttonContainer}>
          <Button
            className={classes.successButtons}
            onClick={() => window.location.href = '/locations'}
          >
            Claim locations!
          </Button>
          <Button
            className={classes.successButtons}
            onClick={onClose}
          >
            Buy more credits
          </Button>
        </div>
      </div>
    </Grid>
  );
}