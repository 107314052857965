import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import MailIcon from '@material-ui/icons/Mail';
import { withFirebase } from '../Firebase';
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AccountMenu, CreditsMenu, FemmecubatorLogo } from './Header.components';
import { headersData, settingOptions, admindrawerData } from './Header.options';
import ExploreIcon from '@material-ui/icons/Explore';
import ApartmentIcon from '@material-ui/icons/Apartment';
import EditIcon from '@material-ui/icons/Edit';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import useAuthUser from '../common/useAuthUser';
import useRxSubject from '../common/useRxSubject';
import { rxHeaderEnabled } from '../common/config';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  navbarRouterLink: {
    color: 'white',
    borderRadius: 10,
    padding: '0 6px',
    textDecoration: 'none',
  },
  leftNavbarRouterLink: {
    color: 'black',
    textDecoration: 'none',
  },
  navbarRouterLinkActive: {
    background: 'white',

    color: 'black',
  },
}));

function Header({ firebase }) {
  const { user: currentUser, userData } = useAuthUser()
  // NEW DEFINITIONS
  const [anchorEl, setAnchorEl] = useState(null);
  const [buyCreditsEl, setbuyCreditsEl] = useState(null);
  const open = Boolean(anchorEl);
  const openBuyCredits = Boolean(buyCreditsEl);
  const [DrawerOpened, setDrawerOpened] = useState(false);
  const handleMenuCredits = (event) => {
    console.log(event.currentTarget);
    setbuyCreditsEl(event.currentTarget);
  };

  const handleCloseCredits = () => {
    setbuyCreditsEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const history = useHistory();
  const { credits, uid } = userData || {}
  const {
    location: { href },
  } = window;
  const handleSignOut = () => {
    firebase.doSignOut();
    // redirect to homepage
    history.push('/')
  };
  const classes = useStyles(open);

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ background: 'black' }}>
        <Toolbar>
          <Hidden mdUp>
            <IconButton
              edge="start"
              className={classes.menuButton}
              onClick={() => setDrawerOpened(true)}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <SwipeableDrawer
            anchor={'left'}
            open={DrawerOpened}
            onClose={() => setDrawerOpened(false)}
            onOpen={() => setDrawerOpened(true)}
          >
            <div style={{ width: 250 }}>
              <List>
                <Link className={classes.leftNavbarRouterLink} to="/">
                  <ListItem button>
                    <ListItemIcon>
                      <ExploreIcon />
                    </ListItemIcon>
                    <ListItemText>Explore</ListItemText>
                  </ListItem>
                </Link>
                <Link className={classes.leftNavbarRouterLink} to="/locations">
                  <ListItem button>
                    <ListItemIcon>
                      <ApartmentIcon />
                    </ListItemIcon>
                    <ListItemText>Buy VRE Locations</ListItemText>
                  </ListItem>
                </Link>
                <Link className={classes.leftNavbarRouterLink} to="/editor">
                  <ListItem button>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>Create</ListItemText>
                  </ListItem>
                </Link>
                <Divider />

                {currentUser ? (
                  <>
                    <Link className={classes.leftNavbarRouterLink} to="/shop">
                      <ListItem button>
                        <ListItemIcon>
                          <ShoppingCartIcon />
                        </ListItemIcon>
                        <ListItemText>Add Credits</ListItemText>
                      </ListItem>
                    </Link>
                    <Link className={classes.leftNavbarRouterLink} to="/setting">
                      <ListItem button>
                        <ListItemIcon>
                          <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText>Settings</ListItemText>
                      </ListItem>
                    </Link>
                    <Link className={classes.leftNavbarRouterLink} to="/orders">
                      <ListItem button>
                        <ListItemIcon>
                          <ListAltIcon />
                        </ListItemIcon>
                        <ListItemText>My Orders</ListItemText>
                      </ListItem>
                    </Link>
                    <Link className={classes.leftNavbarRouterLink} to="/my-locations">
                      <ListItem button>
                        <ListItemIcon>
                          <LocationOnIcon />
                        </ListItemIcon>
                        <ListItemText>My Locations</ListItemText>
                      </ListItem>
                    </Link>
                    <ListItem onClick={handleSignOut} button>
                      <ListItemIcon>
                        <ExitToAppIcon />
                      </ListItemIcon>
                      <ListItemText primary={'Sign Out'} />
                    </ListItem>
                  </>
                ) : (
                  <Link className={classes.leftNavbarRouterLink} to="/signin">
                    <ListItem button>
                      <ListItemIcon>
                        <AccountCircle />
                      </ListItemIcon>
                      <ListItemText>Sign In</ListItemText>
                    </ListItem>
                  </Link>
                )}
              </List>
            </div>
          </SwipeableDrawer>
          <div style={{ flexGrow: '1' }}>
            <FemmecubatorLogo />
          </div>
          <Hidden smDown>
            <Link
              className={classes.navbarRouterLink}
              style={
                window.location.pathname == '/'
                  ? {
                    background: 'white',
                    color: 'black',
                  }
                  : {}
              }
              to="/"
            >
              <Button color="inherit">Explore</Button>
            </Link>
            <Link
              className={classes.navbarRouterLink}
              style={
                window.location.pathname == '/locations'
                  ? {
                    background: 'white',
                    color: 'black',
                  }
                  : {}
              }
              to="/locations"
            >
              <Button color="inherit">Buy VRE Locations</Button>
            </Link>
            <Link
              className={classes.navbarRouterLink}
              style={
                window.location.pathname == '/editor'
                  ? {
                    background: 'white',
                    color: 'black',
                  }
                  : {}
              }
              to="/editor"
            >
              <Button color="inherit">Create</Button>
            </Link>
            {currentUser ? (
              <div>
                <Button
                  style={{ color: 'white' }}
                  aria-controls="menu-credits"
                  onClick={handleMenuCredits}
                  startIcon={!uid && <CircularProgress size={24} />}
                >
                  {!!uid && `${credits || 0} Credits`}
                </Button>

                <Menu
                  id="menu-credits"
                  anchorEl={buyCreditsEl}
                  style={{ top: 32 }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  open={openBuyCredits}
                  onClose={handleCloseCredits}
                >
                  <Link className={classes.leftNavbarRouterLink} to="/shop">
                    <MenuItem onClick={handleCloseCredits}>Add Credits</MenuItem>
                  </Link>
                </Menu>

                {/*<Button
                  aria-controls="menu-credits"
                  aria-haspopup="true"
                  onClick={handleMenuCredits}
                >
                  <Link
                    className={classes.leftNavbarRouterLink}
                    to="/shop"
                    style={{ color: 'white' }}
                  >
                    Add Credits
                  </Link>
                </Button>*/}

                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  style={{ top: 32 }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <Link className={classes.leftNavbarRouterLink} to="/setting">
                    <MenuItem onClick={handleClose}>Settings</MenuItem>
                  </Link>
                  <Link className={classes.leftNavbarRouterLink} to="/my-locations">
                    <MenuItem>My Locations</MenuItem>
                  </Link>
                  <Link className={classes.leftNavbarRouterLink} to="/orders">
                    <MenuItem>My Orders</MenuItem>
                  </Link>
                  <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                </Menu>
              </div>
            ) : (
              <>
                <Link className={classes.navbarRouterLink} to="/signin">
                  <Button color="inherit">Sign In</Button>
                </Link>
              </>
            )}
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withFirebase(Header);
