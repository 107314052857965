import React, { useState, useEffect, Suspense } from 'react';
import { geoToH3 } from 'h3-js';

import Loading from '../Loading';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useLocations from '../common/useLocations'
import useAuthUser from '../common/useAuthUser'
import MapLocations from '../MapLocations'
import LocationsTool from '../LocationsTool'

// const MapLocations = React.lazy(() => import('../MapLocations'));
// const LocationsTool = React.lazy(() => import('../LocationsTool'));

function Locations({
  firebase, setViewState, viewState
}) {
  // destructure latitude and longitude and rename as lat and long
  const { latitude: lat, longitude: long } = viewState;
  const { user, userData = {}, isAdmin } = useAuthUser()
  const [visitedHexes, setVisitedHexes] = React.useState([]);
  const [cartList, setCartList] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [promo, setPromo] = useState("");
  const [masterList, setMasterList] = React.useState([]);
  const [buttonText, setButtonText] = useState('Place Order');
  // confirmation modal state
  const [confirmModal, setConfirmModal] = useState(null);
  // modal
  const [success, setSuccess] = useState(false);

  // info modal visible state
  const [infoModal, setInfoModal] = useState(false);
  const handleModal = (val = false) => setInfoModal(val);
  // auto-retrieve all purchased locations
  const { claimed, error, loading, owned } = useLocations(user?.uid, false)

  /**
   * Calculate hex on map viewState change
   */
  useEffect(() => {
    const hex = geoToH3(lat, long, 6);
    setViewState({ hex });
  }, [viewState.latitude]);

  useEffect(() => {
    const hex = geoToH3(lat, long, 6);
    setVisitedHexes([...visitedHexes, hex]);
  }, [user]);


  const { credits, maptheme } = userData
  // if (!userData || loading || error) return <Loading message={!loading && error} />
  if (!loading && error) return <Loading message={error} />

  return (
    <Suspense fallback={<Loading />}>
      <MapLocations
        master={masterList}
        totalCredits={credits}
        cartList={cartList}
        setCartList={setCartList}
        success={success}
        setSuccess={setSuccess}
        infoModal={infoModal}
        setInfoModal={handleModal}
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        firebase={firebase}
        setButtonText={setButtonText}
        maptheme={maptheme}
        discount={discount}
        setDiscount={setDiscount}
        setPromo={setPromo}
        claimed={claimed}
        owned={owned}
        // getLocations={getLocations}
        loading={loading}
      // setLoading={setLoading}
      />

      <LocationsTool
        style={{ position: 'absolute', zIndex: 10 }}
        totalCredits={credits}
        cartList={cartList}
        setCartList={setCartList}
        success={success}
        setSuccess={setSuccess}
        setInfoModal={handleModal}
        setConfirmModal={setConfirmModal}
        firebase={firebase}
        buttonText={buttonText}
        setButtonText={setButtonText}
        discount={discount}
        setDiscount={setDiscount}
        promo={promo}
        setPromo={setPromo}
      />

      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        transition={Zoom}
        hideProgressBar
        closeOnClick
        newestOnTop
      />
    </Suspense>
  );
}

export default Locations;
