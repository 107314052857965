const INITIAL_STATE = {
 cookiePopup : null,
};

export default function cookiePopupReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'SET_COOKIEPOPUP': {
			return { ...state, ...action.payload };
		}
		default: return state;
	}
}
