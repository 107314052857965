import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logoContainer: {
    flexGrow: 1,
  },
  logo: {
    height: 48,
    width: 48,
    zIndex: 10
  },
  login: {
    display: 'inline',
    textTransform: 'none',
    fontSize: 18,
  },
  drawerItem: {
    '&:hover': {
      background: 'black',
      color: 'white',
    },
    '&:nth-child(1)': {
      marginTop: 84
    },
  },
  drawerText: {
    fontWeight: 700,
    fontSize: 16,
  },
  toolbar: {
    height: '100%',
  },
  selectedHeaderLink: {
    textDecoration: 'none',
    color: '#FFCE00',
    '&:hover': {
      color: '#FFA700',
    },
  },
  headerLink: {
    textDecoration: 'none',
    color: 'white',
    '&:hover': {
      color: 'lightblue',
    },
  },
  headerText: {
    flex: 1,
    display: 'inline',
    fontWeight: 500,
    fontSize: 19,
    padding: '0px 48px',
  },

  titleText: {
    flex: 1,
    display: 'inline',
    fontWeight: 700,
    fontSize: 22,
    padding: '0px 48px',
    color: 'white',
  },
  headerTextContainer: {
    position: 'absolute',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },

    textAlign: 'center',
  },
  credits: {
    textTransform: 'none',
    color: 'white',
    display: 'inline',
    fontWeight: 500,
    fontSize: 19,
    padding: '8px 32px',
    '&:hover': {
      background: '#ffffff32',
      borderRadius: 8,
    },
  },
  userInfo: {
    position: 'absolute',
    right: 64,
  },
  snackbar: {
    background: '#00cb7d',
    borderRadius: 18,
    fontWeight: 700,
    fontSize: 14,
    boxShadow: '0px 3px 6px #00000042',
  },
  menuLink: {
    textDecoration: 'none',
    '&:visited': {
      color: 'black',
    
      visibility: 'hidden',
  },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  adminHeader: {
    background: '#00000033 !important'
  },
  adminHeaderText: {
    flex: 1,
    display: 'inline',
    fontWeight: 500,
    fontSize: 19,
    padding: '0px 8px',
  },

  selectedUserLink: {
    textDecoration: 'none',
    color: '#FFCE00',
    '&:hover': {
      color: '#FFA700',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  headerUserLink: {
    textDecoration: 'none',
    color: 'white',
    '&:hover': {
      color: 'lightblue',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  adminlink: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
	},
	
	logoStyle: {
		fontWeight: 600,
		color: "#FFFEFE",
		textAlign: "left",
	},
	menuButton: {
		fontSize: 20, textTransform: 'capitalize', marginLeft: 38
	},
}));

export default useStyles;
