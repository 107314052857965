import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    bottom: 0,
    boxShadow: '0px 3px 6px #00000096',
    overflowY: 'auto',
    position: 'absolute',
    right: 0,
    top: '4rem',
    transition: 'width .4s',
    width: 325,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  button: {
    height: 55,
    backgroundSize: '330% 100%',
    background:
      'linear-gradient(90deg, rgba(200,200,200,1) 33%, #008873 33%, #008873 66%, #00CB7D 66%)',
    transition: 'color .4s, background-position .5s',
    borderRadius: 18,
    color: 'white',
    margin: '75px 0px 24px 0px',
    width: '100%',
    fontSize: 18,
    fontWeight: 'bold',
    backgroundPosition: '-44% 0%',
    '&:hover': {
      backgroundPosition: '-94% 0%',
    },
  },
  buttonSaving: {
    color: '#008873',
    pointerEvents: 'none',
  },
  disabled: {
    backgroundPosition: '0% 0%',
    '&:hover': {
      backgroundPosition: '0% 0%',
    },
    pointerEvents: 'none',
  },
  cartSpacing: {
    height: 70,
  },
  checkoutSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkoutValue: {
    fontWeight: 800,
  },
  discounts: {
    color: '#3A6FE6',
  },
  divider: {
    height: 1,
    border: 'none',
    background: '#BEBBBB',
    margin: '16px 0px',
  },
  invalidUrl: {
    background: '#e5737f',
  },
  lineItemLabel: {
    fontSize: 16,
    fontWeight: '600',
  },
  orderSummary: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  popUp: {
    fontWeight: 700,
    color: '#0000FF',
    textDecoration: 'underline',
    marginTop: '12px',
  },
  promoButton: {
    textTransform: 'capitalize',
    borderRadius: '0px 8px 8px 0px',
    background: '#EFEFEF',
    fontSize: 18,
    fontWeight: 'bold',
    padding: '4px 24px',
  },
  promoCode: {
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0px 3px 6px #00000028',
    marginBottom: 28,
    borderRadius: 8,
  },
  promoInput: {
    borderRadius: '8px 0px 0px 8px',
    fontSize: 18,
    fontWeight: 'bold',
    paddingLeft: 12,
    paddingTop: 5,
  },
  title: {
    display: 'block',
    fontSize: 32,
    fontWeight: 700,
    //margin: '18px 20px 23px',
    margin: '15px 40px',
  },
  totalCost: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  err: {
    marginLeft: '30px',
    marginRight: '30px',
    textAlign: 'center',
  },
  heading: {
    fontSize: '17.5px',
    flexBasis: '85%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: '15px',
    color: 'gray',
    margin: '0px 7px',
  },
  locatePurcheOrderIcon: {
    margin: 'auto 0',
    marginRight: '0px',
    fontSize: '1.1rem',
    marginRight: '10px',
    fill: 'gray',
  },
  locatePurcheOrderIconActive: {
    margin: 'auto 0',
    marginRight: '0px',
    fontSize: '1.1rem',
    marginRight: '10px',
    fill: 'blue',
  },
  locationItem: {
    '&:hover div:nth-child(2)': {
      display: 'block',
    },
  },
}));

export default useStyles;
