/**
* @name	csvPathsToArr
* @summary converts csv string of relative paths to pages to array and adds leading slash "/" if needed
* 
 * @param	{String} str
 * 
 * @returns {[]String]}
 */
export const csvPathsToArr = (str = '') => str
	.split(',')
	.map(path => {
		path = path.trim()
		if (!path) return
		path = `${path.startsWith('/') ? '' : '/'}${path}`
		return path
	})
	.filter(Boolean)

export const disabledPages = csvPathsToArr(process.env.REACT_APP_DISABLED_PAGES || '')
export const headersData = [
	{ label: "Explore", href: "/" },
	{ label: "Virtual Real Estate", href: "/locations" },
	{ label: "Create", href: "/editor" }
].filter(({ href }) => !disabledPages.includes(href))

export const settingOptions = [
	{ label: "My Orders", href: "/orders" },
	{ label: "My Settings", href: "/setting" },
	// { label: "My Inventory", href: "/inventory" },
	{ label: "My Inventory", href: "/my-locations" },

	//{ label: "FAQ", href: "/" },
].filter(({ href }) => !disabledPages.includes(href))

export const admindrawerData = [
	{ label: 'My Users', href: 'admin-users' },
	{ label: 'My Campaign', href: '/admin-campaign' },
	{ label: 'My Offers', href: '/admin-offers' },
	{ label: 'My Discounts', href: '/admin-discount' },

].filter(({ href }) => !disabledPages.includes(href))