import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import { store, persistor } from './store/store';
import * as serviceWorker from './serviceWorker';
import Axios, { AxiosContext } from './components/api';
import firebase, { FirebaseContext } from './components/Firebase';
import { PersistGate } from 'redux-persist/integration/react';
import { rxPublicConfig } from './components/common/config';

const rootEl =
  process.env.REACT_APP_ENV_Landing === 'YES' ? (
    <div
      style={{
        background: '#EEEEEE',
        height: '100%',
        position: 'fixed',
        width: '100%',
      }}
    >
      <div>
        <br />
        <br />
        <br />
        <br />
        <center>
          <h1 style={{ fontSize: '4rem' }}>Coming soon</h1>
          <h5 style={{ fontSize: '2rem' }}>Technical fixes taking place</h5>
        </center>
      </div>
    </div>
  ) : (
    <React.StrictMode>
      <FirebaseContext.Provider value={firebase}>
        <AxiosContext.Provider value={new Axios()}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </AxiosContext.Provider>
      </FirebaseContext.Provider>
    </React.StrictMode>
  );
ReactDOM.render(rootEl, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

console.log('env', process.env.REACT_APP_ENV);
rxPublicConfig.subscribe(
  (config) => config?.vre && console.info(JSON.stringify(config.vre, null, 4))
);
