/* eslint-disable no-return-assign */
import React, { useState, useEffect, Suspense } from 'react';
import { geoToH3 } from 'h3-js';
import { ToastContainer, Zoom } from 'react-toastify';
import useAuthUser from '../common/useAuthUser'
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../Loading';
import { scaleDefault } from '../Drawer/objectFiles';
import useLocations from '../common/useLocations'
import useObjects from '../common/useObjects'
import MapV2 from '../MapV2'
import Drawer from '../Drawer'
import RightDrawer from '../RightDrawer'

// const MapV2 = React.lazy(() => import('../MapV2'));
// const Drawer = React.lazy(() => import('../Drawer'));
// const RightDrawer = React.lazy(() => import('../RightDrawer'));

function EditorV2({ firebase, viewState, setViewState }) {
  const {
    user,
    userData: {
      maptheme,
      name,
      uid,
    } = {},
  } = useAuthUser()
  const { owned: userHexes } = useLocations(user?.uid, true, true)
  const { objects } = useObjects(user?.uid, true)

  // destructure latitude and longitude and rename as lat and long
  const [selectedObject, setSelectedObject] = useState('')
  const [selectedImage, setSelectedImage] = useState('')
  // array (x,y,z) of rotation, scale, height [Rx,Ry,Rz,Sx,Sy,Sz, H]
  const [draftObjectDetails, setDraftObjectDetails] = React.useState([0, 45, 90, 1, 1, 1, 0, '']);
  const [scale, setScale] = useState(() => scaleDefault)
  const [draftObjectUrl, setDraftObjectUrl] = React.useState('');
  const [color, setColor] = React.useState([255, 255, 255, 255]);
  const [objectType, setObjectType] = React.useState('3DObject');
  const [master, setMasterList] = React.useState([]);
  const [token, setToken] = React.useState('');
  const [label, setLabel] = React.useState('');
  const [des, setDes] = React.useState('');
  const [visitedHexes, setVisitedHexes] = React.useState([]);
  const [displayobj, setDisplayobj] = React.useState(true);
  const [displayProp, setDisplayProp] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [objectUpdate, setObjectUpdate] = React.useState(false); // toggle to update object on change
  const [removeObject, setRemoveObject] = React.useState(false); // toggle to remove object on change
  const [hexRadius, setHexRadius] = React.useState(0);
  const [contentid, setContentid] = React.useState('');
  const [sliderScale, setSliderScale] = React.useState(1);
  const { latitude: lat, longitude: long } = viewState;

  /**
   * Calculate hex on map viewState change
   */
  useEffect(() => {
    let hex = geoToH3(lat, long, 6);
    setViewState({ hex });
  }, [viewState.latitude])

  /**
   * Fetches 3D content from server:
   * - on initial load
   * - if user moves to a new unvisited hex
   */

  useEffect(() => {
    if (user && !visitedHexes.includes(viewState.hex)) {
      let hex = geoToH3(lat, long, 6);
      setVisitedHexes([...visitedHexes, hex])
    }
  }, [user, viewState.hex]);

  return (
    <Suspense fallback={<Loading />}>
      {/* Left sidebar: objects selection */}
      <Drawer
        {...{
          displayobj,
          currentUser: firebase?.auth?.currentUser,
          loading,
          draftObjectDetails,
          objectType,
          selectedObject,
          selectedImage,
          setDraftObjectDetails,
          setLoading,
          setMode: setObjectType,
          setScale,
          setSelectedObject,
          setSelectedImage,
          setDisplayProp,
          hexRadius,
          setContentid,
          setSliderScale,
          setColor,
        }}
      />

      {/* Right sidebar: object properties editor */}
      {/* <RightDrawer
        {...{
          color,
          des,
          displayProp,
          setDisplayProp,
          draftObjectDetails,
          draftObjectUrl,
          label,
          scale,
          setScale,
          selectedObject,
          color,
          setColor,
          setDraftObjectDetails,
          setDraftObjectUrl,
          setDes,
          setLabel,
          removeObject,
          setRemoveObject,
          sliderScale,
          style: {
            position: 'absolute',
            zIndex: 10,
          },
        }}
      /> */}

      <MapV2
        {...{
          color,
          des,
          displayobj,
          displayProp,
          draftObjectDetails,
          draftObjectUrl,
          edit: true,
          label,
          loading,
          maptheme,
          master,
          objects,
          objectType,
          scale,
          setScale,
          selectedImage,
          selectedObject,
          setDraftObjectDetails,
          setColor,
          setDes,
          setDisplayobj,
          setDisplayProp,
          setLabel,
          // setLoading,
          setSelectedImage,
          setSelectedObject,
          token,
          user,
          uid,
          uname: name,
          userHexes,
          removeObject,
          hexRadius,
          setHexRadius,
          contentid,
          setContentid,
          sliderScale,
          setSliderScale,
        }}
      />

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        transition={Zoom}
        hideProgressBar
        closeOnClick
        newestOnTop
      />
    </Suspense>
  );
}

export default EditorV2
