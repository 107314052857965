import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import { setUser } from '../../actions';

const withAuthentication = (Component) => {
  const WithAuthenticationBase = (props) => {
    const [authUser, setAuthUser] = useState({});
    const { firebase, setUser } = props;

    useEffect(() => {
      firebase.auth.onAuthStateChanged(async (auth) => {
        if (auth) {
          auth.getIdToken().then((token) => {
            setUser({ ...auth, token });
          });
          return setAuthUser(auth);
        }
        return setAuthUser(null);
      });
    }, [firebase, firebase.auth]);

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component props={props} />
      </AuthUserContext.Provider>
    );
  };

  return compose(
    withFirebase,
    connect(
      ({ firebaseReducer }) => ({ user: firebaseReducer.user }),
      { setUser },
    ), // shorthand for  => (dispatch) => ({setUser: (user) => dispatch(setUser(user))}))
  )(WithAuthenticationBase);
};

export default withAuthentication;
