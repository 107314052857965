import React, { useEffect } from 'react';
import { Button, Input, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
// import imageFiles from './imageFiles';
import useModels, { scaleDefault, thumbnailDir, _models } from './objectFiles';
import AlertDialog from './infoModal';
import useStyles from './Drawer.styles';
import { getModelInfo } from "../../utils/models";
import { findSliderScale } from "../../utils/scale_helpers";

const loaded = {}
export default function Drawer({
  displayobj,
  draftObjectDetails,
  objectType,
  selectedObject,
  setSelectedObject,
  setDraftObjectDetails,
  setDisplayProp,
  hexRadius,
  setContentid,
  setScale,
  setSliderScale,
  setColor,
}) {

  const classes = useStyles();
  let [keywords, setKeywords] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [models = []] = useModels(keywords)

  const handleSelect = async (modelSrc) => {
    setContentid(''); // clean the last object contentid
    setColor([255, 255, 255, 255]); // clean the last object color

    const {
      scale = scaleDefault,
    } = models.find(x => x.src === modelSrc)
    let { start = 1 } = scale;

    // if we got model dimensions, set default scale based on user limits
    const modelDimensions = await getModelInfo(modelSrc);
    if (modelDimensions) {
      const hexDiameter = hexRadius * 2;

      const maxModelWidth = Math.max(modelDimensions.x, modelDimensions.y);
      console.log(`hexDiameter: ${hexDiameter} maxModelWidth: ${maxModelWidth}`);
      const scaleFactor = hexDiameter / maxModelWidth;

      const localUiScale = findSliderScale(scaleFactor);
      setSliderScale(localUiScale);

      start = Math.floor(scaleFactor * localUiScale) / localUiScale;
      const min = start;

      setScale({ ...scale, start, min });
    }

    const isSelected = selectedObject === modelSrc
    // set default scale on model load
    draftObjectDetails[1] = 0;
    draftObjectDetails[3] = start
    draftObjectDetails[4] = start
    draftObjectDetails[5] = start
    draftObjectDetails[6] = 0
    setDraftObjectDetails([...draftObjectDetails])
    setSelectedObject(!isSelected && modelSrc)
    // setDisplayProp(true)

    if (loaded[modelSrc]) return
    loaded[modelSrc] = loaded[modelSrc] || true
    // show fake loading modal!!! (first time only)
    setOpen(true)
    setTimeout(() => setOpen(false), 2000)
  }

  useEffect(() => {
    // show fake loading modal!!! 
    setOpen(true);
    setTimeout(() => { setOpen(false) }, 3000);
  }, [])

  return (
    <div className={displayobj ? classes.root : classes.hideobjroot} >
      <div className={classes.container}>
        <AlertDialog open={open} setOpen={setOpen} />
        <Typography className={classes.title}>Object Search</Typography>
        <Typography className={classes.infoText}>
          Once you have selected an object, click on the map to place the object and edit properties
          before saving.
        </Typography>

        <div className={classes.objectsContainer}>
          <Input
            className={classes.search}
            type="text"
            disableUnderline
            placeholder="search for object"
            onChange={e => setKeywords(e.target.value)}
            startAdornment={<SearchIcon className={classes.searchIcon} />}
          />

          <div className={classes.list}>

            {objectType !== 'image' &&
              models
                .map((entry, i) => {
                  const { displayName, src, thumbnail } = entry
                  const imageSrc = `${thumbnailDir}${thumbnail}`
                  return (
                    <Button
                      key={src + i}
                      onClick={() => handleSelect(src)}
                      className={`${classes.button} ${selectedObject === src ? classes.selectedButton : ''}`}
                    >
                      <img src={imageSrc} height={20} /> {displayName}
                    </Button>
                  )
                })}

          </div>

        </div>
      </div>
    </div>
  );
}

/*

          {
            currentUser && process.env.REACT_APP_ADMINIDS.split(',').includes(currentUser.uid) ?
              <div className={classes.list}>

                {mode === '3DObject'
                  && objectFilesAdmin.map((i, key) => {

                    //console.log("i.split('.')[0] loop1",i.split('.')[0]);
                    //console.log("i.split('.')[0]",i.split('.')[0]);

                    if (i.toLowerCase().includes(search.toLowerCase())) {
                      return (
                        <Button
                          key={key}
                          onClick={() => handleButtonClick(i)}
                          className={`${classes.button} ${selectedObject === i ? classes.selectedButton : ''
                            }`}
                        >
                          <img
                            src={
                              i.split('.')[0].includes('stadium') || i.split('.')[0].includes('Stadium') || i.split('.')[0].includes('Center') || i.split('.')[0].includes('Arena') || i.split('.')[0].includes('allegiant') ? require('../../img/stadium.png') :
                                i.split('.')[0].includes('garden') || i.split('.')[0].includes('center') ? require('../../img/sport.png') :
                                  i.split('.')[0].includes('luxor') ? require('../../img/cut.jpg') :
                                    i.split('.')[0].includes('highrise') || i.split('.')[0] == 'Damac' ? require('../../img/building.png') :
                                      i.split('.')[0].includes('corp') || i.split('.')[0].includes('capitol') ? require('../../img/box.png') :
                                        i.split('.')[0].includes('met') ? require('../../img/ball.png') :
                                          i.split('.')[0].includes('factory') ? require('../../img/factory.png') :
                                            i.split('.')[0].includes('apartment') ? require('../../img/apartment.png') :
                                              i.split('.')[0].includes('museum') ? require('../../img/museum.png') :
                                                i.split('.')[0].includes('plane') ? require('../../img/plane.png') :
                                                  i.split('.')[0].includes('coin') ? require('../../img/coin.png') :
                                                    i.split('.')[0].includes('ballpark') ? require('../../img/stick.png') :
                                                      i.split('.')[0].includes('Hotel') || i.split('.')[0].includes('Sheraton') || i.split('.')[0].includes('MGM_Grand') || i.split('.')[0].includes('Resorts_Would') ? require('../../img/hotel.png') :
                                                        i.split('.')[0].includes('bridge') || i.split('.')[0].includes('Jumeirah') ? require('../../img/bridge.png') :
                                                          i.split('.')[0].includes('gundam') ? require('../../img/men.png') :
                                                            i.split('.')[0].includes('station') ? require('../../img/station.png') :
                                                              i.split('.')[0].includes('ufo') ? require('../../img/ufo.png') :
                                                                i.split('.')[0].includes('smithsonian') || i.split('.')[0].includes('house') || i.split('.')[0].includes('House') ? require('../../img/house.png') :
                                                                  i.split('.')[0] == 'DubaiMall' ? require('../../img/mall.png') :
                                                                    i.split('.')[0].includes('Burj') || i.split('.')[0].includes('Empire') || i.split('.')[0].includes('Center_part_tower_residense') || i.split('.')[0].includes('Crysler') || i.split('.')[0].includes('Hearst') || i.split('.')[0].includes('Strat') || i.split('.')[0].includes('Times_square4') || i.split('.')[0].includes('One_Vanderbuilt') ? require('../../img/tower.png') :
                                                                      i.split('.')[0].includes('Tower') || i.split('.')[0].includes('Towers') ? require('../../img/itower.png') :
                                                                        i.split('.')[0].includes('Financial') ? require('../../img/finance.png') :
                                                                          i.split('.')[0].includes('Ritossa') ? require('../../img/statue.png') :
                                                                            i.split('.')[0].includes('Sofitel') ? require('../../img/Sofitel.png') :
                                                                              i.split('.')[0].includes('Frame') ? require('../../img/frame.png') :
                                                                                i.split('.')[0].includes('Opus') || i.split('.')[0].includes('Waldorf') ? require('../../img/opus.png') :
                                                                                  i.split('.')[0] == 'Marriott' || i.split('.')[0] == 'Hilton_Dubai' ? require('../../img/building2.png') :
                                                                                    i.split('.')[0] == 'Atlantis_Palm_Dubai' || i.split('.')[0] == 'qasr' ? require('../../img/building3.png') :
                                                                                      i.split('.')[0] == 'Opera_Dubai' || i.split('.')[0] == 'cruise' ? require('../../img/boat.png') :
                                                                                        i.split('.')[0] == 'Atari' ? require('../../img/atari.png') :
                                                                                          i.split('.')[0] == 'Damac_towers' || i.split('.')[0].includes('tower') || i.split('.')[0].includes('WTC') ? require('../../img/towers.png') :
                                                                                            i.split('.')[0] == 'Akoy' || i.split('.')[0].includes('Bellagio') || i.split('.')[0].includes('Carillon') || i.split('.')[0].includes('Loews') || i.split('.')[0].includes('Sony') ? require('../../img/building2.png') :
                                                                                              require('../../img/wall.png')} height={10}
                          />
                          {i.split('.')[0]}
                        </Button>

                      );
                    }
                  })}

              </div>
              :
              <div className={classes.list}>

                {mode === '3DObject' &&
                  objectFiles.map((i, key) => {
                    //console.log("i.split('.')[0] loop2",i.split('.')[0]);
                    //console.log("i.split('.')[0] loop2",i.split('.')[0]);
                    if (i.toLowerCase().includes(search.toLowerCase())) {
                      return (
                        <Button
                          key={key}
                          onClick={() => handleButtonClick(i)}
                          className={`${classes.button} ${selectedObject === i ? classes.selectedButton : ''
                            }`}
                        >

                          <img
                            src={
                              i.split('.')[0].includes('stadium') || i.split('.')[0].includes('Stadium') || i.split('.')[0].includes('Center') || i.split('.')[0].includes('Arena') ? require('../../img/stadium.png') :
                                i.split('.')[0].includes('garden') || i.split('.')[0].includes('center') ? require('../../img/sport.png') :
                                  i.split('.')[0].includes('luxor') ? require('../../img/cut.jpg') :
                                    i.split('.')[0].includes('highrise') || i.split('.')[0] == 'Damac' ? require('../../img/building.png') :
                                      i.split('.')[0].includes('corp') || i.split('.')[0].includes('capitol') ? require('../../img/box.png') :
                                        i.split('.')[0].includes('met') ? require('../../img/ball.png') :
                                          i.split('.')[0].includes('factory') ? require('../../img/factory.png') :
                                            i.split('.')[0].includes('apartment') ? require('../../img/apartment.png') :
                                              i.split('.')[0].includes('museum') ? require('../../img/museum.png') :
                                                i.split('.')[0].includes('plane') ? require('../../img/plane.png') :
                                                  i.split('.')[0].includes('coin') ? require('../../img/coin.png') :
                                                    i.split('.')[0].includes('ballpark') ? require('../../img/stick.png') :
                                                      i.split('.')[0].includes('Hotel') ? require('../../img/hotel.png') :
                                                        i.split('.')[0].includes('bridge') || i.split('.')[0].includes('Jumeirah') ? require('../../img/bridge.png') :
                                                          i.split('.')[0].includes('gundam') ? require('../../img/men.png') :
                                                            i.split('.')[0].includes('station') ? require('../../img/station.png') :
                                                              i.split('.')[0].includes('ufo') ? require('../../img/ufo.png') :
                                                                i.split('.')[0].includes('smithsonian') || i.split('.')[0].includes('house') || i.split('.')[0].includes('House') || i.split('.')[0].includes('cottage') || i.split('.')[0].includes('Cottage') ? require('../../img/house.png') :
                                                                  i.split('.')[0] == 'DubaiMall' || i.split('.')[0].includes('shop') ? require('../../img/mall.png') :
                                                                    i.split('.')[0].includes('Burj') ? require('../../img/tower.png') :
                                                                      i.split('.')[0].includes('Tower') || i.split('.')[0].includes('Towers') ? require('../../img/itower.png') :
                                                                        i.split('.')[0].includes('Financial') ? require('../../img/finance.png') :
                                                                          i.split('.')[0].includes('Ritossa') ? require('../../img/statue.png') :
                                                                            i.split('.')[0].includes('Sofitel') ? require('../../img/Sofitel.png') :
                                                                              i.split('.')[0].includes('Frame') ? require('../../img/frame.png') :
                                                                                i.split('.')[0].includes('Opus') || i.split('.')[0].includes('Waldorf') ? require('../../img/opus.png') :
                                                                                  i.split('.')[0] == 'Marriott' ? require('../../img/building2.png') :
                                                                                    i.split('.')[0] == 'Atlantis_Palm_Dubai' ? require('../../img/building3.png') :
                                                                                      i.split('.')[0] == 'Opera_Dubai' || i.split('.')[0] == 'cruise' ? require('../../img/boat.png') :
                                                                                        i.split('.')[0].includes('Office') || i.split('.')[0].includes('office') ? require('../../img/building3.png') :
                                                                                          require('../../img/wall.png')} height={10}
                          />
                          {i.split('.')[0]}
                        </Button>

                      );
                    }
                  })}

              </div>
          }
*/
