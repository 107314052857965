import { useEffect, useState } from 'react'
import FirestoreHelper from './FirestoreHelper'

/**
 * @name    useObjects
 * @summary React hook to subscribe and retrieve objects collection entries.
 *
 * @param   {String}    userId      logged or target in user's ID. User `null` when user hasn't logged in,
 *                                  if data should only be retrieved after user is logged in.
 * @param   {Boolean}   ownedOnly   (optional) whether to retreive only logged user's locations or all active purchased
 * @param   {Boolean}   subscribe   if falsy, will only retrieve data once
 *
 * @returns {Object}    Result: {error, loading, objects}
 */
export default function useObjects(userId, ownedOnly = true, subscribe = true) {
    const [state, setState] = useState({
        error: '',
        loading: true,
        objects: [],
    })

    useEffect(() => {
        // wait until user is logged in and/or user ID is available
        if (ownedOnly && !userId) return () => { }

        let mounted = true
        let unsubscribe
        const firestore = new FirestoreHelper('objects', 'contentID')
        const func = subscribe
            ? 'subscribe'
            : 'search'
        unsubscribe = firestore[func](
            objects => setState({
                loading: false,
                objects: [...objects],
            }),
            error => setState({ error, loading: false }),
            [
                ownedOnly && ['created_by', '==', userId]
            ],
            9999,
        )
        return () => {
            mounted = false
            unsubscribe && unsubscribe()
        }
    }, [userId])

    return state
}