/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import { Button, Typography, Box, CircularProgress, Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import useStyles from './MapLocations.styles';

const modalText = {
  what: {
    question: 'What can I do with locations?',
    answer:
      'Owning a location allows the placement of content on the map, and will generate shared revenue in the future from paid AR content that is geolocated at the location. Locations may also be sold or auctioned on the Omniscape marketplace with it launches in Summer 2021',
  },
  how: {
    question: 'How do I buy locations?',
    answer:
      'Spend location credits to buy locations.Credits can be purchased by clicking on your credit balance located on the right side of the upper navigation bar.',
    answer2:
      ' You can select individual locations by clicking on the map.To select multiple locations at the same time, change the select tool to Select Multiple Locations.'
  },
};


export const ModalStructure = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.modalWrapper}>
      <div className={classes.modalContainer}>
        {children}
      </div>
    </div>
  );
};

export const InfoModal = ({ info, onCancel }) => {
  const classes = useStyles();
  return (
    <ModalStructure>
      <Typography align="left" className={classes.title}>
        {modalText[info].question}
      </Typography>
      <Typography align="left" className={classes.modalText} variant="body1">
        {info === 'how' ?
          <div>
            {modalText[info].answer}
            <img src={'images/thumbnails/credit.png'} height={30} style={{ verticalAlign: 'middle', padding: 2 }} alt="" />
            {modalText[info].answer2}
            <img src={'images/thumbnails/multipleloc.png'} height={32} style={{ verticalAlign: 'middle', padding: 2 }} alt="" />
          </div>
          : modalText[info].answer}
      </Typography>
      <div className={classes.modalButtonRowRight}>
        <Button className={classes.modalButton} onClick={onCancel}>
          Close
        </Button>
      </div>
    </ModalStructure>
  );
};

export const ConfirmOrderModal = ({ onClick, onCancel }) => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles();
  const btnClass = [
    classes.confirmButton,
    loading && classes.disabled
  ]
    .filter(Boolean)
    .join(' ')
  const btnCancelClass = [
    classes.cancelButton,
    loading && classes.disabled,
  ]
    .filter(Boolean)
    .join(' ')
  return (
    <ModalStructure>
      <Typography align="left" className={classes.title}>
        Click to confirm orders
      </Typography>

      <div className={classes.modalButtonRowCenter}>
        <Button
          variant="contained"
          className={btnCancelClass}
          disabled={loading}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className={btnClass}
          color='primary'
          disabled={loading}
          onClick={async (...args) => {
            try {
              setLoading(true)
              await onClick(...args)
            } catch (err) {
              alert(`Failed to confirm order! ${err}`)
              console.info('ConfirmOrderModal', err)
            } finally {
              setLoading(false)
            }
          }}
        >
          {loading ? 'Confirming...' : 'Confirm'}
        </Button>
      </div>
    </ModalStructure>
  );
};


export const AlertDialog = () => {

  return (
    <div>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogContent>
          <>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress color="inherit" /><br />

            </Box>
            {/* <DialogContentText id="alert-dialog-description" style={{ marginTop: 12, marginBottom: 5 }}>
              Geospatial Synchronization in Progress. Loading…
            </DialogContentText> */}
            <DialogContentText id="alert-dialog-description" style={{ marginTop: 12, marginBottom: 5 }}>
              Geospatial Synchronization in Progress. Loading…
            </DialogContentText>
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
}