/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback } from 'react';
import MapGL, { NavigationControl } from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import Geocoder from 'react-map-gl-geocoder';
import { EditTooltip } from './MapLocations.helpers';
import { GLTFLoader } from '@loaders.gl/gltf';
import { registerLoaders } from '@loaders.gl/core';
import { InfoModal, InfoModal1 } from './InfoModals';
import useStyles from './MapView.styles';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './map.css';
import { toast } from 'react-toastify';
import {
  createSceneGraphColor,
  createImageLayer,
  createGroupedLayers,
} from '../../utils/layer_helpers';
import fire from 'firebase/app';

// Register the proper loader for scenegraph.gltf
registerLoaders([GLTFLoader]);

const MapView = ({ buildings, viewState, setViewState, popupState, setCookiePopup, maptheme }) => {
  const { currentUser } = fire.auth();

  // store object data as deck.gl layers
  const [buildingLayers, setBuildingLayers] = React.useState([]);

  // For popup
  const [showPopup, togglePopup] = React.useState(false);

  // const [infoModal, setInfoModal] = React.useState(false);
  const handleModal = () => togglePopup(false);
  const [popup, setPopup] = useState(false);

  // hover
  const [info, setInfo] = useState('');
  const [object, setObject] = useState({});
  const classes = useStyles();

  const onHover = (info) => {
    if (!info) return setInfo(null);
    return setInfo({
      x: info.x,
      y: info.y,
    });
  };

  // Map Utils
  const mapRef = React.useRef();
  const geocoderContainerRef = React.useRef();
  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
  const handleViewPortChange = (vp) => setViewState({ ...vp, transitionDuration: 0 });
  const onSearchResult = useCallback((vp) => {
    togglePopup(false);
    const geocoderDefaultOverrides = { transitionDuration: 1000, top: 500 };
    return handleViewPortChange({
      ...vp,
      ...geocoderDefaultOverrides,
    });
  }, []);

  useEffect(() => {
    setBuildingLayers(createGroupedLayers(buildings, onHover, null, currentUser?.uid));
  }, [buildings]);

  useEffect(() => {
    const flag = popupState.cookiePopup;
    if (!flag) {
      setPopup(true);
      setCookiePopup({ cookiePopup: 'display' });
    }
  }, []);
  const setSelecttedObject = async (contentID) => {
    const object = buildings.find((x) => x.contentID === contentID);
    object && setObject(object);
  };
  /**
   * Deck.gl supports upto 255 independant layers
   * The following will create multiple groups/pages, only if the total number of layers is over 255
   */
  const limit = 255;
  const numPages = parseInt(buildingLayers.length / limit) + 1;
  const groups = new Array(numPages).fill(0).map((_, p) => {
    const start = p * limit;
    const end = start + limit;
    return buildingLayers.slice(start, end);
  });
  return (
    <div className={classes.root}>
      {popup && !currentUser ? <InfoModal1 setPopup={setPopup} /> : null}
      {/* {EditTooltip(info, object)} */}
      <MapGL
        ref={mapRef}
        {...viewState}
        width="100%"
        height="100%"
        touchRotate
        touchZoom
        onViewportChange={handleViewPortChange}
        mapStyle={maptheme || `mapbox://styles/mapbox/streets-v11`}
        mapboxApiAccessToken={MAPBOX_TOKEN}
      >
        {showPopup && <InfoModal object={object} onCancel={handleModal} />}
        <div className={classes.navigation}>
          <NavigationControl
            style={{
              top: 20,
              right: 20,
            }}
            zoomInLabel={'zoomIn'}
            zoomOutLabel={'zoomOut'}
          />
        </div>
        <div className={classes.geocoder}>
          <Geocoder
            mapRef={mapRef}
            trackProximity
            containerRef={geocoderContainerRef}
            onViewportChange={onSearchResult}
            mapboxApiAccessToken={MAPBOX_TOKEN}
            position="top-left"
            marker={false}
          />
        </div>
        {groups.map((layers, p) => (
          <DeckGL
            key={p + 1}
            style={{ zIndex: p + 1 }}
            useDevicePixels
            viewState={{ ...viewState }}
            layers={layers}
            onClick={(event, info) => {
              if (!event.object) return togglePopup(false);

              if (!info.leftButton && info.rightButton) {
                const id = event?.object?.contentID;
                setSelecttedObject(id);
                togglePopup(true);
              } else if (event.object.url) {
                window.location.href = event.object.url;
                togglePopup(false);
              }
            }}
            onHover={(e) => {
              if (!e.object) return;
              const id = e?.object?.contentID;
              setSelecttedObject(id);
              onHover(e);
            }}
          />
        ))}
      </MapGL>
    </div>
  );
};

export default React.memo(MapView);
