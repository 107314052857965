import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    right: 0,
    position: 'absolute',
    width: 330,
    backgroundColor: 'white',
    boxShadow: '0px 3px 6px #00000096',
    transition: 'width .4s',
    top: '4rem',
    bottom: 0,
    borderBottomLeftRadius: 18,
    overflowY: 'auto',
  },
  hideproproot: {
    display: 'none'
    // right: 0,
    // position: 'absolute',
    // zIndex: 0,
    // width: 330,
    // backgroundColor: 'white',
    // height: '100%',
    // boxShadow: '0px 3px 6px #00000096',
    // transition: 'width .4s',
    // top: 'auto',
    // bottom: 0,
    // borderBottomLeftRadius: 18,
    // overflowY: 'auto',
  },
  container: {
    padding: '2rem',
  },
  title: {
    fontSize: 32,
    fontWeight: 700,
    margin: '18px 0px 18px',
  },
  inputLabel: {
    color: '#020202',
    fontWeight: 700,
    fontSize: 22,
    margin: '18px 0px 8px',
  },
  helperText: {
    fontSize: 16,
    marginBottom: 8,
  },
  count: {
    fontSize: 14,
    margin: '0px 0px 16px 0px',
  },
  objectsContainer: {
    alignItems: 'center',
    textAlign: 'center',
  },
  search: {
    padding: 8,
    border: '1px olid #707070',
    color: 'black',
    fontSize: 14,
    fontWeight: 700,
    width: 182,
    margin: 'auto',
  },
  list: {
    marginTop: 16,
    height: 500,
    overflow: 'scroll',
  },
  box: {
    background: 'red',
    width: 200,
    height: 100,
    marginBottom: 12,
  },
  objectOverlay: {
    background: 'transparent',
    height: 100,
    width: 200,
    position: 'absolute',
    zIndex: 10,
  },
  button: {
    marginTop: 24,
    marginBottom: 500,
    background: '#4caf50',
    width: 200,
    color: 'white',
  },
  input: {
    display: 'inline',
    padding: 0,
    maxWidth: '50',
    color: '#000',
  },
  colorContainer: {
    display: 'flex',
  },
  colorPicker: {
    width: 150,
    height: 39,
    flex: 1,
    borderRadius: 8,
  },
  validUrl: {
    height: 40,
    background: '#81c784',
    borderRadius: 8,
  },
  invalidUrl: {
    height: 40,
    background: '#e5737f',
    borderRadius: 8,
  },
  tf: {
    height: 40,
  },
  saveMessage: {
    paddingTop: 28,
    fontWeight: 700,
  },
  checkIcon: {
    top: 4,
    padding: '0px 2px',
    display: 'inline',
    color: '#4caf50',
    position: 'relative',
  },
  input: {
    padding: '10.5px 14px'
  },
  divmrgn: {
    marginTop: '35px'
  },
  tdslide: {
    padding: '0px',
    width: '184px',
    borderBottom: 'none'
  },
  tdfiled: {
    paddingRight: '0px',
    paddingTop: '0px',
    borderBottom: 'none'
  },
  colortd:{
   paddingTop:0,
   paddingLeft:0,
   border:'none'
  },
  resettd:{
    border:'none',
    paddingTop: 60,
    paddingLeft: 20
  }

}));

export default useStyles;
