import { connect } from 'react-redux';
import { compose } from 'redux'
import { withFirebase } from '../Firebase';
import { withAxios } from '../api';
import { withAuthorization } from '../Session';
import { setViewState, viewState } from '../../actions';
import LocationsTool from './LocationsTool';

const mapState = ({ firebaseReducer, viewStateReducer }) => ({
  user: firebaseReducer.user,
  viewState: viewStateReducer,
})

export default compose(
  withFirebase,
  withAxios,
  connect(
    mapState,
    { setViewState }
  ))(LocationsTool)

