import React, { useState } from 'react'
import { Button, Typography, useMediaQuery } from '@material-ui/core'
import AccordionGroup from '../common/AccordionGroup'
import { ArrowForward } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { grey } from '@material-ui/core/colors'
import BundleList from './BundleList'
import BuyCreditsForm, { inputNames } from './BuyCreditsForm'
import useBundles from './useBundles'

const useStyles = makeStyles(theme => ({
    bundlesContainer: {
        background: 'white',
        padding: '25px 0',
        [theme.breakpoints.up('sm')]: {
            display: 'inline-block',
            borderLeft: '2px solid #80808040',
            width: 'calc(50% - 2px)',
        },
    },
    formContainer: {
        marginBottom: 25,
        [theme.breakpoints.up('sm')]: {
            display: 'inline-block',
            verticalAlign: 'top',
            width: '50%',
        },
    },
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: '0 25px',
        },
    },
}))

const faq = [{
    title: 'What are location credits?',
    description: (
        <ul style={{ margin: 0 }}>
            <li>
                Location credits are used to purchase and trade virtual real estate locations in the Locations tab.
            </li>
            <li>
                Location credits currently come in two types: OmniTokens (recommended) or in-app Credits (deprecating soon).
            </li>
            <li>
                Location credits can be purchased with US Dollars (USD) or Bitcoin SV (BSV) at the end of checkout.
            </li>
        </ul>
    ),
}]

export default function (props) {
    const classes = useStyles()
    const isMobile = useMediaQuery('(max-width:600px)')
    const [selected, setSelected] = useState()
    const [bundles] = useBundles()

    const items = [
        <div className={classes.formContainer}>
            <BuyCreditsForm {...{
                formProps: {
                    style: { padding: 25 }
                },
                onChange: (_, values) => setSelected(values[inputNames.bundle]),
                submitButton: (
                    <Button {...{
                        endIcon: <ArrowForward />,
                        style: { float: 'left' },
                    }}>
                        Checkout now
                    </Button>
                ),
                values: {
                    bundle: selected,
                    bundles,
                },
            }} />
        </div>,
        <div className={classes.bundlesContainer}>
            <BundleList {...{
                bundles,
                onSelect: x => setSelected(x),
                selected,
                setSelected,
            }} />
        </div>,
    ]

    return (
        <div className={classes.root}>
            <div {...{
                style: {
                    margin: 'auto',
                    maxWidth: 900
                },
                ...props,
            }}>
                <Typography variant='h4' style={{ margin: '50px 0 25px' }}>
                    Buy Credits
                </Typography>
                <AccordionGroup items={faq} />
            </div>
            <div style={{
                background: grey[100],
                padding: '25px 0',
            }}>
                <div  {...{
                    style: {
                        margin: 'auto',
                        maxWidth: 900
                    },
                    ...props,
                }}>
                    <div style={{
                        background: grey[300],
                        border: '2px solid #80808040',
                        borderRadius: 5,
                    }}>
                        {isMobile
                            ? items.reverse()
                            : items}
                    </div>
                </div>
            </div>
        </div>
    )
}