import { BehaviorSubject } from 'rxjs'
import { subjectAsPromise } from './subjectAsPromise'
import { rxAuthUser } from './useAuthUser'
import useRxSubject from './useRxSubject'
import { deferred } from './utils'

export const rxAuthToken = new BehaviorSubject()
let intervalId, lastUpdated

/**
 * @name    getToken
 * @summary get the user auth token. If token is not yet set in `rxAuthToken`, will wait until it's set.
 * 
 * @param   {Boolean}   requireLogin (optional) whether to wait until user is logged in
 *                                   Default: true
 * 
 * @returns {String}    token
 */
export const getToken = async (requireLogin = true) => await subjectAsPromise(
    rxAuthToken,
    requireLogin
        ? subjectAsPromise.anyValueSymbol
        : undefined
)[0]

/**
 * @name    updateToken
 * @summary update user auth token and set value to `rxAuthToken`
 * 
 * @param   {Object} user 
 * @param   {Boolean} refresh 
 * 
 * @returns {String} token
 */
export const updateToken = (refresh = true, user = rxAuthUser?.value?.user) => {
    // unset token after user logs out
    if (!user?.uid) {
        rxAuthToken.value && rxAuthToken.next(null)
        return
    }

    // retrive and store user auth token for authentication with Firebase functions
    return user
        .getIdToken(refresh)
        .then(token => {
            lastUpdated = new Date()
            rxAuthToken.next(token)
            return token
        })
        .catch(err => console.error('Failed to update auth token', err))
}

// Automatically update token whenever current user changes
// Automatically refresh token every 30 minutes
rxAuthUser.subscribe(({ user }) => {
    clearInterval(intervalId)
    const update = deferred(updateToken, 100)
    update(
        false, // no need to force refresh token, first time after user logs in
        user,
    )
    // 30 minutes
    const delay = 1000 * 60 * 30
    intervalId = setInterval(() => update(true, user), delay)
})

/**
 * @name    useAuthToken
 * 
 * @returns {String}
 */
export default () => useRxSubject(rxAuthToken)[0]