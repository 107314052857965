import React from 'react'
import { styled } from '@material-ui/core/styles'
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import { ExpandMore } from '@material-ui/icons'

const Accordion = styled((props) => (
    <MuiAccordion elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}))

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMore sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export default function AccordionGroup({ items = [], multi = true }) {
    const [expandedTitles, setExpandedTitles] = React.useState(() =>
        items.map(x => x.expanded && x.title)
            .filter(Boolean)
            .slice(0, multi ? undefined : 1)
    )

    const handleChange = (title) => (event, newExpanded) => {
        const newExpandedTitles = !multi
            ? newExpanded
                ? [title]
                : []
            : newExpanded
                ? [...expandedTitles, title]
                : expandedTitles.filter(t => t !== title)
        setExpandedTitles([...new Set(newExpandedTitles)])
    }

    return (
        <div>
            {items.map(({ description, title }, i) => {
                const expanded = expandedTitles.includes(title)
                return (
                    <Accordion {...{
                        expanded,
                        key: i + title,
                        onChange: handleChange(title),
                    }}>
                        <AccordionSummary>
                            {/* expandIcon={ExpandMore}> */}
                            <Typography>{title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography component='div'>
                                {typeof description === 'function'
                                    ? description()
                                    : description}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    )
}