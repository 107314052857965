import React, { useState, useEffect } from 'react'
import FirestoreHelper from '../common/FirestoreHelper'
import { arrSort } from '../common/utils'

export default function useBundles() {
    const [bundles, setBundles] = useState([])

    useEffect(() => {
        let mounted = true
        const handleResult = bundles => {
            if (!mounted) return

            bundles = Object
                .values(bundles)
                .filter(x => x.credits > 0 && x.price > 0) // ignore the id

            bundles = arrSort(bundles, 'credits')
                .map((bundle, i) => {
                    let { credits = 0, credits_bonus = 0, price = 0 } = bundle
                    // percentage of the `credits_bonus` amount compared to `credits`
                    const creditsBonusPercent = (credits_bonus / credits) || 0
                    const totalCredits = credits + (credits * creditsBonusPercent)
                    const {
                        credits: nextCredits = 0,
                        credits_bonus: nextBonus = 0,
                    } = bundles[i + 1] || {}
                    const min = credits + credits_bonus
                    const max = (nextCredits + nextBonus) || (credits + credits_bonus)
                    const pricePerCredit = price / totalCredits

                    // add extra properties to bundles for use in the frontend
                    return {
                        creditsBonusPercent,
                        // minimun quantity to qualify for current bundle pricing
                        min,
                        // minumum amount USD required to qualify for current bundle pricing
                        // maximum quantity to qualify for current bundle pricing
                        max,
                        // per per credit after bonus is applied
                        pricePerCredit,
                        ...bundle,
                    }
                })
                .filter(bundle => bundle.credits > 0 && bundle.creditsBonusPercent >= 0)

            setBundles(bundles)
        }
        // subscribe and auto-update bundles
        const unsubscribe = new FirestoreHelper('credit_prices', 'id')
            .subscribeById(
                handleResult,
                console.error,
                'prices',
            )
        return () => {
            mounted = false
            unsubscribe()
        }
    }, [])

    return [bundles, setBundles]
}