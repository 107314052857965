import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BehaviorSubject } from 'rxjs'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { Typography } from '@material-ui/core'
import FormBuilder from '../common/form/FormBuilder'
import { findInput, getValues, validateInput } from '../common/form/InputCriteriaHint'
import { arrSort, deferred, isValidNumber } from '../common/utils'
import { STATUS } from '../common/Message'
import PaymentForm, { CREDIT_TYPE } from './PaymentForm'
import useAuthUser from '../common/useAuthUser'
import modalService from '../common/modal/modalService'
import FirestoreHelper from '../common/FirestoreHelper'

export default function BuyCreditsForm(props) {
    const [stripePromise] = useState(() => loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`))
    const rxInputs = useState(() => getRxInputs(props))[0]
    const { user, userData } = useAuthUser()
    const [message, setMessage] = useState()
    const { values: { bundle, bundles } } = props

    useEffect(deferred(() => {
        const changed = !!bundle
            && bundle !== findInput(inputNames.bundle, rxInputs.value).value
        if (!changed) return

        const { credits = 0, credits_bonus = 0 } = bundle
        const inputs = rxInputs.value
        const bundleIn = findInput(inputNames.bundle, inputs)
        const quantityIn = findInput(inputNames.quantity, inputs)
        bundleIn.value = bundle
        quantityIn.value = credits + credits_bonus
        const values = getValues(inputs)
        // manually trigger update
        quantityIn.onChange(values, inputs)
        setTimeout(() => rxInputs.next([...inputs]))
    }, 50), [bundle])

    // set bundles as an input value so that it's easily accessible by other inputs
    useEffect(() => {
        if (!bundles?.length) return

        const input = findInput(inputNames.bundles, rxInputs.value)
        input.value = arrSort(bundles, 'min') // sort by minimun credits
        rxInputs.next([...rxInputs.value])
    }, [bundles])

    // Auto update moneybutton paymail input even if changed externally
    useEffect(() => {
        const { wallet_moneybutton } = userData || {}
        const mbIn = findInput(inputNames.wallet_moneybutton, rxInputs.value)
        const changed = wallet_moneybutton !== mbIn.value
        if (!changed) return

        validateInput(mbIn)
        mbIn.value = wallet_moneybutton
        validateInput(mbIn)
        rxInputs.next([...rxInputs.value])
    }, [userData])

    const handleSubmit = async (valid, values) => {
        if (!valid) return

        const bundle = values[inputNames.bundle]
        const quantity = values[inputNames.quantity]
        const creditType = values[inputNames.creditType]
        const wallet_moneybutton = values[inputNames.wallet_moneybutton]
        const { credits, id, pricePerCredit } = bundle

        try {
            const changed = userData.wallet_moneybutton !== wallet_moneybutton
            if (changed) {
                setMessage({
                    status: STATUS.loading,
                    text: 'Saving your MoneyButton wallet...'
                })
                await new FirestoreHelper('users', 'uid')
                    .save({ wallet_moneybutton }, user.uid, true)
                setMessage(null)
            }
        } catch (err) {
            setMessage({
                status: STATUS.error,
                text: err.message,
            })
        }

        const modalId = 'buy-credits'
        const paymentProps = {
            creditsPurchased: quantity,
            // amout to be paid
            price: eval(
                (quantity * pricePerCredit).toFixed(2),
            ),
            moneytype: creditType,
            onClose: () => modalService.delete(modalId),
            // onSuccess: ok => ok && modalService.delete(modalId),
            selected: id,
            user,
        }

        modalService.set({
            closeButton: null,
            content: (
                <Elements stripe={stripePromise}>
                    <PaymentForm {...paymentProps} />
                </Elements>
            ),
            open: true,
        }, modalId)
    }
    return (
        <FormBuilder {...{
            ...props,
            onSubmit: handleSubmit,
            rxInputs,
            message,
        }} />
    )
}
BuyCreditsForm.defaultProps = {
    decimalPlaces: 4,
}
BuyCreditsForm.propTypes = {
    bundles: PropTypes.array,
    decimalPlaces: PropTypes.number,
    values: PropTypes.object,
}

export const inputNames = {
    bundle: 'bundle',
    bundles: 'bundles',
    creditType: 'creditType',
    quantity: 'quantity',
    summary: 'summary',
    totalCost: 'totalCost',
    wallet_moneybutton: 'wallet_moneybutton',
}
const getRxInputs = (props) => {
    const { decimalPlaces = 4, values } = props
    const rxInputs = new BehaviorSubject([])
    const containerProps = {
        style: {
            background: 'white',
            margin: '0px -25px 0px',
            padding: '15px 25px 0',
        },
    }
    const inputs = [
        {
            containerProps: {
                style: {
                    ...containerProps.style,
                    margin: '-25px -25px 0',
                }
            },
            label: 'Enter how many credits you need',
            name: inputNames.quantity,
            onChange: (values, inputs) => {
                const quantity = values[inputNames.quantity] || 0
                let bundle = values[inputNames.bundle] || {}
                const quantityIn = findInput(inputNames.quantity, inputs)
                const summaryIn = findInput(inputNames.summary, inputs)
                const totalCostIn = findInput(inputNames.totalCost, inputs)
                const bundles = findInput(inputNames.bundles, inputs).value || []
                const globalMax = bundles[bundles.length - 1].max
                const globalMin = bundles[0].min
                const bundleIn = findInput(inputNames.bundle, inputs)
                const valid = globalMin <= quantity && quantity <= globalMax
                quantityIn.valid = valid
                quantityIn.message = !valid && {
                    status: STATUS.error,
                    text: `Please enter a number between ${globalMin} and ${globalMax}`
                }

                const inRange = bundle?.min <= quantity
                    && quantity < bundle?.max
                if (!inRange) {
                    // quantity is out of the range of the currently applied bundle
                    // => change applied bundle
                    bundle = [...bundles]
                        .reverse()
                        .find(({ max, min }) =>
                            min <= quantity && quantity <= max
                        )
                    bundleIn.value = bundle
                }

                const { creditsBonusPercent, pricePerCredit = 0 } = bundle || {}
                // update price
                quantityIn.validation.criterias[1].text = (
                    <Typography key={pricePerCredit} style={{ fontWeight: 'bold' }}>
                        US ${pricePerCredit.toFixed(decimalPlaces)} per credit
                    </Typography>
                )

                const totalCost = eval(
                    (quantity * pricePerCredit)
                        .toFixed(2)
                )
                const originAmount = parseInt(quantity / (1 + creditsBonusPercent))
                const bonusAmount = quantity - originAmount
                // update summary
                summaryIn.content = pricePerCredit > 0 && (
                    <div key={totalCost}>
                        <Typography>
                            PRICE FOR {originAmount} CREDITS
                            <b> {bonusAmount > 0 && `(+${bonusAmount} BONUS)`}</b>
                        </Typography>
                        <Typography variant='h5'>
                            US ${totalCost.toFixed(2)}
                        </Typography>
                    </div>
                )
                // update value only if quantity field was changed
                totalCostIn.value = !totalCostIn.keepUnchanged
                    ? totalCost
                    : totalCostIn.value
                totalCostIn.keepUnchanged = false
                validateInput(totalCostIn)
                // triggers formbuilder update
                return true
            },
            required: true,
            type: 'number',
            validation: {
                criterias: [
                    {
                        // makes sure a valid number is entered
                        text: '', // no need to show a message
                        validate: value => isValidNumber(value) && value > 0,
                    },
                    {
                        hideIcon: true,
                        name: 'price',
                        valid: true,
                    }
                ]
            }, //keep
        },
        {
            containerProps,
            label: 'Or, enter how much you would like to spend',
            name: inputNames.totalCost,
            onChange: (values, inputs, ...args) => {
                const totalCost = values[inputNames.totalCost]
                console.log({ totalCost })
                const bundles = values[inputNames.bundles] || []
                const totalCostIn = findInput(inputNames.totalCost, inputs)
                const quantityIn = findInput(inputNames.quantity, inputs)
                const bundleIn = findInput(inputNames.bundle, inputs)
                const maxCost = bundles[bundles.length - 1].price * 2
                const minCost = bundles[0].price
                // make sure entered amount is within range of smalled and largest bundle
                totalCostIn.validation.criterias = [{
                    hideIcon: true,
                    text: `Please enter an amount between $${minCost} and $${maxCost}`,
                    validate: value => isValidNumber(value)
                        && value >= minCost
                        && value <= maxCost,
                }]
                // validation failed
                if (!validateInput(totalCostIn).valid) return

                // find the appropriate bundle for the amount user wishes to spend
                const bundleToApply = [...arrSort(bundles, 'price')]
                    .reverse()
                    .find(({ price = 0 }) => price <= totalCost)
                const quantity = !bundleToApply
                    ? 0
                    : parseInt(totalCost / bundleToApply.pricePerCredit)
                quantityIn.value = quantity
                values[inputNames.quantity] = quantity
                bundleIn.value = bundleToApply
                values[inputNames.bundle] = bundleToApply

                // used as an indicator that the input change was trigged by totalCost input
                // this will make sure to keep the value unaltered by quantityIn.onChange()
                totalCostIn.keepUnchanged = true
                // trigger onChange on the quantity input so that everything else is also updated accordingly
                return quantityIn.onChange(values, inputs, ...args)
            },
            placeholder: 'Enter how much you would like to spend',
            required: true,
            type: 'number',
            validation: {
                hideOnValid: true,
            },
        },
        {
            containerProps,
            label: 'Credit type',
            name: inputNames.creditType,
            options: [
                {
                    label: (
                        <span>
                            OmniToken (Recommended)
                            <br />
                            <small>
                                <i> - requires <a href="https://moneybutton.com" target="_blank">moneybutton.com</a> account</i>
                            </small>
                        </span>
                    ),
                    value: CREDIT_TYPE.omni_token,
                },
                {
                    label: 'In-app Credits (To be deprecated)',
                    value: CREDIT_TYPE.credit,
                }
            ],
            onChange: (values, inputs) => {
                const mbIn = findInput(inputNames.wallet_moneybutton, inputs)
                const creditType = values[inputNames.creditType]
                mbIn.hidden = creditType !== CREDIT_TYPE.omni_token
                mbIn.required = !mbIn.hidden
                validateInput(mbIn)
                return true
            },
            required: true,
            row: false,
            type: 'radio',
            // value: CREDIT_TYPE.omni_token,
        },
        {
            containerProps: {
                style: {
                    ...containerProps.style,
                    paddingBottom: 15,
                }
            },
            hidden: true,
            label: 'MoneyButton Paymail',
            labelDetails: 'This is where you will receive your OmniTokens',
            name: inputNames.wallet_moneybutton,
            placeholder: 'abc@moneybutton.com',
            type: 'email',
            validation: {
                criterias: [{
                    hideIcon: true,
                    text: 'Please enter a valid moneybutton.com paymail',
                    validate: value => value
                        && value.length > 16
                        && value.endsWith('moneybutton.com')
                }],
                hideOnValid: true,
            },
        },
        {
            containerProps: {
                style: {
                    ...containerProps.style,
                    background: undefined,
                }
            },
            content: <></>,
            name: inputNames.summary,
            type: 'html',
        },
        {
            hidden: true,
            name: inputNames.bundle,
            value: {},
        },
        {
            hidden: true,
            name: inputNames.bundles,
            value: [],
        },
    ]

    rxInputs.next(inputs)
    return rxInputs
}