/* eslint-disable jsx-a11y/anchor-is-valid */
import md5 from 'md5'
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Link,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import useStyles from './LocationsTool.styles';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import PayButton, { ASSET } from '../MoneyButton/PayButton';
import { withFirebase } from '../Firebase';
import { rxPublicConfig } from '../common/config';
import useAuthUser from '../common/useAuthUser';
import useRxSubject from '../common/useRxSubject';
import { deferred } from '../common/utils';

const PAYMENT_METHOD = {
  bsv: 'bsv',
  credit: 'credit',
  omni_token: 'omni_token',
}
// Currency Tickers userd in payment methods
const TICKER = {
  bsv: 'BSV',
  omni_token: 'OmniToken'
}
const ORDER_TYPE = {
  vre_order: 'vre_order'
}

/**
 * @name	copyToClipboard
 * @summary copies text to clipboard.
 * 
 * @param	{String} str 
 */
export const copyToClipboard = str => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

const Discounts = ({ classes, discount }) => (
  <div className={classes.checkoutSection}>
    <Typography className={`${classes.lineItemLabel} ${classes.discounts}`}>Discounts</Typography>
    <Typography className={`${classes.lineItemLabel} ${classes.discounts}`}>{discount} cr</Typography>
  </div>
);

const HelpfulLinks = ({ classes, setInfoModal, history }) => (

  <Box my={2}>
    <Typography onClick={() => setInfoModal('what')} className={classes.popUp}>
      <Link href="#">What can I do with locations?</Link>
    </Typography>
    <Typography onClick={() => setInfoModal('how')} className={classes.popUp}>
      <Link href="#">How do I buy locations?</Link>
    </Typography>
    <Typography className={classes.popUp} onClick={() => history.push('/my-locations')} style={{ cursor: "pointer" }}>
      <Link>My Locations</Link>
    </Typography>
  </Box>
);

const CartCount = ({ classes, cartList, locationCost }) => (
  <>
    <div className={classes.checkoutSection}>
      <Typography className={classes.lineItemLabel}>{`${cartList.length} locations`}</Typography>
      <Typography className={classes.lineItemLabel}>{`${cartList.length * locationCost} cr`}</Typography>
    </div>
  </>
);

const PromoCode = ({ classes, applyPromoCode, setPromo, promo }) => (
  <div className={classes.promoCode}>
    <TextField
      placeholder="Enter promo code"
      className={classes.promoInput}
      value={promo}
      onChange={e => { setPromo(e.target.value) }}
    />
    <Button
      className={classes.promoButton}
      onClick={() => applyPromoCode()}
    >
      Apply
    </Button>
  </div>
);

const CheckoutButton = ({
  buttonReady, buttonText, classes, onClick,
}) => (
  <Button
    className={`${classes.button} ${buttonReady ? '' : classes.disabled}`}
    disabled={!buttonReady}
    onClick={onClick}
    variant="contained"
  >
    {buttonText}
  </Button>
);

const createNConfirmOrder = async (
  axios,
  cartList = [],
  payment = {},
  onSuccess,
  promoCode,
  toastId,
) => {
  // dismiss any existing toast
  let orderId, created
  const { id: paymentId, paymentMethod, txId } = payment || {}

  try {
    // create and validate order
    const orderResult = await axios.createOrder(
      {
        cartList,
        payment,
        promoCode,
      },
    )
    const { data = {} } = orderResult
    orderId = data.orderId
    toast.dismiss(toastId)
    toast.success('Order created. Confirming order.', { toastId })
    created = true
    // finalizing order
    await axios.confirmOrder(orderId)

    purchasedHexes.push(...cartList)
    onSuccess && onSuccess(orderId)

    toast.dismiss(toastId)
    toast.success('Order confirmed. Location NFT(s) will be created shortly.')
  } catch (err) {
    const { code, message } = err
    const action = created
      ? 'confirm'
      : 'create'
    const refundRequired = paymentMethod !== PAYMENT_METHOD.credit
      || (txId && !!paymentId)

    if (!refundRequired) return toast.error(
      `Failed to ${action} order.\n${message}`,
      { autoClose: false },
    )

    const ticker = TICKER[paymentMethod]
    const paymentInfo = [
      orderId && `\nOrderID: ${orderId}`,
      paymentId && `\nPaymentID: ${paymentId}`,
      txId && `\nTransaction ID: ${txId}`,
    ]
      .filter(Boolean)
      .join('')
    const msg = [
      refundRequired
        ? `${ticker} payment was successful but failed to ${action} order.`
        : `Failed to ${action} order.`,
      `\nMessage from server: ${message}`,
      code && `\nError code: ${code}`,
      refundRequired && '\n\nPlease contact support with the following information:',
      paymentInfo,
      paymentInfo && '\n\nPayment information has been copied to clipboard.'
    ]
      .filter(Boolean)
      .join('')

    paymentInfo && copyToClipboard(paymentInfo + `\nMessage from server: ${message}`)

    alert(msg)
  }
}

const applyPromoCode = deferred(async (cartList, promo, axios, setDiscount, setTokenDiscount, notify = true) => {
  const numLocations = cartList.length
  if (numLocations === 0) return

  const toastId = 'promo-code'
  toast.dismiss(toastId)
  notify && toast.info(`Applying promo code: ${promo}`, { toastId })
  const calculateCost = async (paymentMethod) => await axios.calculateCost({
    orderDetails: { locations: cartList },
    orderType: ORDER_TYPE.vre_order,
    paymentMethod,
    promoCode: promo,
  })
  try {
    const [creditResult, tokenResult] = await Promise.all([
      calculateCost(PAYMENT_METHOD.credit),
      calculateCost(PAYMENT_METHOD.omni_token)
    ])
    const { data: [_, warning, discount = 0] } = creditResult
    notify && warning && toast.warn(warning)
    setDiscount(discount)

    const { data: [c, w, omniDiscount] } = tokenResult
    setTokenDiscount([omniDiscount, numLocations])

    toast.dismiss(toastId)
    notify && toast.success(`Promo code "${promo}" applied sucessfully!`, { toastId })
  } catch (err) {
    toast.error(`Failed to apply promo code. \n${err}`)
  }
}, 300)

const purchasedHexes = []
const LocationsTool = (props) => {
  const {
    axios,
    cartList,
    setCartList,
    setInfoModal,
    setConfirmModal,
    buttonText,
    setDiscount,
    discount = 0,
    promo,
    setPromo,
    totalCredits,
  } = props;
  const [buttonReady, setButtonReady] = useState(true);
  const { user } = useAuthUser()
  const [{
    orderLimit = 0,
    locationCost = 0,
  }] = useRxSubject(rxPublicConfig, doc => doc?.vre || {})
  const [
    [
      // discount amount if paid with OmniToken
      tokenDiscount = 0,
      // number of locations used in promocode
      numLocations = 0
    ],
    setTokenDiscount,
  ] = useState([])

  // useEffect(() => {
  //   buttonReady && claimed.length && setTimeout(() => {
  //     const _claimed = claimed.filter(x => !purchasedHexes.includes(x))
  //     if (!_claimed.length) return // locations was purchased by the current user

  //     alert(`The following location${_claimed.length > 1 ? 's' : ''} are being removed from your order because they have just been claimed by another user.\n${_claimed.map(x => '\n' + x)}`)

  //     setCartList(cartList.filter(x => !_claimed.includes(x)))
  //   })
  // }, [claimed, buttonReady])

  /**
   * Calls API server to validate order and complete checkout.
   * This will eventually be replaced with a better
   */
  const handleSubmit = async () => {
    // validation
    const cost = cartList.length * locationCost - discount
    if (cost > totalCredits) return alert('Not enough credits')
    if (cartList.length > orderLimit) return alert(
      `Max purchase size is ${orderLimit} locations`
    )

    // call api
    // disable button while creating order
    setButtonReady(false)

    const toastId = 'payment-credits'
    toast.dismiss(toastId)
    toast.info('Creating order....', { toastId })
    await createNConfirmOrder(
      axios,
      cartList,
      {
        paymentMethod: PAYMENT_METHOD.credit,
      },
      () => {
        setCartList([])
        clearPromoCode()
      },
      promo,
      toastId,
    )
    // call api
    setButtonReady(true)
    setConfirmModal([])
  }

  // Upon payment using OmniToken, create and confirm order without confirm dialog
  const handleBlockchainPayment = (paymentMethod, currency = '') => async (result) => {
    const { id, status, txid: txId } = result || {}
    const toastId = 'payment-omni_token'
    if (!['RECEIVED', 'COMPLETED'].includes(status)) return toast.error('Transaction failed', { toastId })
    if (!txId) return toast.error(`Transaction ${status} but no transaction ID received`)
    if (!id) return toast.error(`Transaction ${status} but no payment ID received`)

    toast.info('Payment successful. Creating order....', { toastId })
    // disable button while creating order
    setButtonReady(false)
    await createNConfirmOrder(
      axios,
      cartList,
      {
        currency,
        id,
        paymentMethod,
        txId,
      },
      () => {
        setCartList([])
        clearPromoCode()
      },
      promo,
      toastId,
    )
    setButtonReady(true)
  }

  const clearPromoCode = () => {
    setPromo('')
    setDiscount(0)
    setTokenDiscount([])
  }

  const classes = useStyles();
  const history = useHistory();
  const amountCredits = (cartList.length * locationCost) - discount
  const amountToken = (cartList.length * locationCost) - tokenDiscount
  const amountUSD = ((amountToken * 5) / 100)
    .toFixed(2)
  const valid = cartList.length > 0 && cartList.length <= orderLimit
  const reApplyPromo = promo && numLocations && numLocations !== cartList.length
  reApplyPromo && applyPromoCode(cartList, promo, axios, setDiscount, setTokenDiscount, false)
  const locationsHash = valid && md5(JSON.stringify(cartList.sort()))
  const bsvCurrency = 'USD'

  return !user ? '' : (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>
          <Typography className={classes.title}>Locations</Typography>
          <HelpfulLinks setInfoModal={setInfoModal} classes={classes} history={history} />
        </div>
        <div>
          <Typography className={classes.orderSummary}>ORDER SUMMARY</Typography>

          <hr className={classes.divider} />

          {cartList.length > 0 && <CartCount {...{ cartList, classes, locationCost }} />}
          <Discounts classes={classes} discount={discount} />

          <div className={classes.cartSpacing} />

          {cartList.length > 0 && (
            <PromoCode
              classes={classes}
              applyPromoCode={() => applyPromoCode(cartList, promo, axios, setDiscount, setTokenDiscount)}
              setPromo={setPromo}
              promo={promo}
            />
          )}

          <hr className={classes.divider} />

          <div className={classes.checkoutSection}>
            <Typography className={classes.totalCost}>TOTAL COST</Typography>
            <Typography className={classes.totalCost}>{`${amountCredits} cr`}</Typography>
          </div>

          {cartList.length > orderLimit
            ? (
              <Typography color="error">
                Maximum {orderLimit} locations allowed per order
              </Typography>
            )
            : (
              <>
                {amountCredits > totalCredits && (
                  <center style={{ margin: '20px 0 -20px' }}>
                    <Typography color="error">
                      Insufficient credits
                    </Typography>
                  </center>
                )}
                <CheckoutButton {...{
                  buttonText,
                  buttonReady: valid && amountCredits <= totalCredits && buttonReady,
                  onClick: () => setConfirmModal([true, handleSubmit]),
                  classes,
                }} />
              </>
            )}

          {valid && (
            <>
              <Typography variant='h6'>
                Buy with {ASSET.OmniToken}:
              </Typography>
              <PayButton {...{
                amount: amountToken,
                asset: ASSET.OmniToken,
                buttonData: {
                  numLocations: cartList.length,
                  locationsHash,
                },
                buttonId: locationsHash,
                locations: cartList,
                onError: console.warn,
                onPayment: handleBlockchainPayment(PAYMENT_METHOD.omni_token),
              }} />
            </>
          )}

          {valid && (
            <>
              <Typography variant='h6'>
                Buy with {ASSET.BSV}:
              </Typography>
              <PayButton {...{
                amount: amountUSD,
                asset: ASSET.BSV,
                buttonData: {
                  numLocations: cartList.length,
                  locationsHash,
                },
                buttonId: locationsHash,
                currency: bsvCurrency,
                label: 'in BSV',
                locations: cartList,
                onError: console.warn,
                onPayment: handleBlockchainPayment(PAYMENT_METHOD.bsv, bsvCurrency),
              }} />
            </>
          )}

        </div>
      </div>
    </div>
  );
};
export default withRouter(withFirebase(LocationsTool));