import React from 'react'
import PropTypes from 'prop-types'
import { arrSort, isFn } from '../common/utils'
import { Button, List, ListItem, Typography } from '@material-ui/core'

/**
 * @name    format
 * @summary formats number into short form. Eg: converts `1000` to "1K"
 * 
 * @param   {Number} num 
 * 
 * @returns {String}
 */
const format = num => {
    const divider = num > 999999 // 1M +
        ? 1000000
        : num > 999 // 1k+
            ? 1000
            : 1
    const suffix = {
        1000000: 'M',
        1000: 'K',
    }[divider] || ''
    return `${parseInt(num / divider)}${suffix}`
}


export default function BundleList(props) {
    const {
        bundles,
        containerProps,
        decimalPlaces,
        selected,
        setSelected,
        onSelect,
    } = props

    return !!bundles?.length && (
        <div {...containerProps}>
            <List style={{ padding: 0 }}>
                <ListItem disablePadding style={{ width: '100%' }}>
                    <Typography
                        component='div'
                        style={{
                            fontWeight: 'bold',
                            textAlign: 'left',
                            width: '50%'
                        }}
                    >
                        Credits
                    </Typography>
                    <Typography
                        component='div'
                        style={{
                            fontWeight: 'bold',
                            textAlign: 'right',
                            width: '50%'
                        }}
                    >
                        {/* Price Per Credit */}
                        Bundle Price (USD)
                    </Typography>
                </ListItem>
                {arrSort(bundles, 'min')
                    .map(bundle => {
                        let {
                            credits,
                            credits_bonus = 0,
                            max,
                            min,
                            pricePerCredit,
                            price = 0,
                        } = bundle
                        const isSelected = selected === bundle

                        return (
                            <ListItem {...{
                                disablePadding: true,
                                disableGutters: true,
                                selected: isSelected,
                                style: {
                                    padding: 0,
                                },
                            }}>
                                <Button {...{
                                    component: 'div',
                                    fullWidth: true,
                                    onClick: () => {
                                        isFn(onSelect) && onSelect(bundle)
                                        setSelected(bundle)
                                    },
                                    style: {
                                        display: 'block',
                                        padding: '15px 25px',
                                        textTransform: 'none',
                                        ...!isSelected
                                            ? {}
                                            : {
                                                background: '#1B2032',
                                                color: 'white',
                                            },
                                    },
                                    type: 'text',
                                }}>
                                    <Typography component='div' style={{ float: 'left' }}>
                                        {/* {format(min)} to {format(max)} */}
                                        {credits + credits_bonus}
                                    </Typography>
                                    <Typography component='div' style={{ float: 'right' }}>
                                        {/* US ${pricePerCredit.toFixed(decimalPlaces)} */}
                                        ${price.toFixed(decimalPlaces)}
                                    </Typography>
                                </Button>
                            </ListItem>
                        )
                    })}
            </List>
        </div >
    )
}
BundleList.propTypes = {
    bundles: PropTypes.arrayOf(
        PropTypes.shape({
            max: PropTypes.number.isRequired,
            min: PropTypes.number.isRequired,
            pricePerCredit: PropTypes.number.isRequired,
        })
    ),
    // number of decimal places for price 
    decimalPlaces: PropTypes.number,
    onSelect: PropTypes.func,
}
BundleList.defaultProps = {
    decimalPlaces: 2,
}
