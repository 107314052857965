/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
import { GLTFLoader } from '@loaders.gl/gltf';
import { registerLoaders } from '@loaders.gl/core';
import { SimpleMeshLayer, ScenegraphLayer } from '@deck.gl/mesh-layers';
import { H3HexagonLayer } from '@deck.gl/geo-layers';
import { v1 } from 'uuid';

import { PlaneGeometry } from '@luma.gl/engine';
import { geoToH3, kRing } from 'h3-js';
import { isArr } from '../components/common/utils';

/**
 * @name    createGroupedLayers
 * @summary creates layers for each model file and places any number of objects using the same file in a single layer
 * 
 * @param   {Array}     objects 
 * @param   {Function}  onHover (optional)
 * @param   {Function}  onClick (optional)
 * @param   {String}    userId  (optional) 
 * 
 * @returns {Array} layers
 */
export const createGroupedLayers = (objects = [], onHover, onClick, userId) => {
  const groups = objects
    .reduce((groups, next) => {
      groups[next.source] = [
        ...groups[next.source] || [],
        next,
      ]
      return groups
    }, {})
  const layers = Object
    .keys(groups)
    .map(key => {
      const groupObjects = groups[key]
      const { type } = groupObjects[0]
      const fn = type === 'image'
        ? createImageLayer
        : createSceneGraphEditor // 3DObject
      return fn(
        groupObjects,
        onHover,
        onClick,
        userId
      )
    })
  return layers
}

/**
 * Creates new 3D object layer
 * @param {object} d data to create layer
 * @param {function} ftn Function passed as on hover property
 */
export const createSceneGraph = (d, ftn = {}) =>
  new ScenegraphLayer({
    id: d.id,
    data: [d],
    sizeScale: 10,
    _lighting: 'pbr',
    scenegraph: d.source,
    pickable: true,
    //onClick: (d) => (d.object.url ? (window.location = d.object.url) : ''),
    getPosition: (d) => d.location,
    getOrientation: (d) => [d.rotation[0], d.rotation[1], d.rotation[2]],
    getScale: (d) => [0.1 * d.scale[0], 0.1 * d.scale[1], 0.1 * d.scale[2]],
    onHover: (d) => (d.object && d.object.url ? ftn(d) : ftn(null)),
  });

/**
 * Creates new 3D object layer with custom color
 * @param {object} d data to create layer
 * @param {function} ftn Function passed as on hover property

 */
export const createSceneGraphColor = (d, ftn = {},) =>
  new ScenegraphLayer({
    id: d.id,
    key: d.id,
    data: [d],
    sizeScale: 10,
    _lighting: 'pbr',
    scenegraph: `./models_sized/${d.source}`,
    pickable: true,
    // onClick: (d) => (d.object.url ? (window.location = d.object.url) : ''),
    getColor: (d) => d.color,
    getPosition: (d) => d.location,
    getOrientation: (d) => [d.rotation[0], d.rotation[1], d.rotation[2]],
    getScale: (d) => [0.1 * d.scale[0], 0.1 * d.scale[1], 0.1 * d.scale[2]],
    onHover: (d) => (d.object && d.object.url ? ftn(d) : ftn(null)),
  });

/**
 * Creates new 3D object layer with custom color
 * @param {object} data data to create layer
 * @param {function} ftn Function passed as on hover property

 */
export const createSceneGraphEditor = (data, onHover, onClick, uid = '') => {
  data = Array.isArray(data)
    ? data
    : [data]
  const { source } = data[0] || {}
  return new ScenegraphLayer({
    _lighting: 'pbr',
    data,
    getColor: d => d.color,
    getOrientation: d => d.rotation.slice(0, 3),
    getPosition: d => d.location,
    getScale: d => d.scale
      .slice(0, 3)
      .map(x => x * 0.1),
    id: source,
    pickable: true,
    // d: objectConfirmation?
    // e: event
    onClick: (d, e) => uid === d?.object?.created_by
      && onClick?.(d, e),
    onHover: (d, e) => uid === d?.object?.created_by
      && onHover?.(d, e),
    onBlurr: (d, e) => console.log('onBlur', { e, d }),
    scenegraph: `./models_sized/${source}`,
    sizeScale: 10,
  })
}

/**
 * Creates new 3D image layer
 * @param {object} data data to create layer
 * @param {function} onHover Function passed as on hover property
 */
export const createImageLayer = (data, onHover) => {
  data = isArr(data)
    ? data
    : [data]
  const { id, source } = data[0] || {}
  return new SimpleMeshLayer({
    id,
    data,
    texture: `./images/${source}`,
    material: false,
    mesh: new PlaneGeometry(),
    pickable: true,
    sizeScale: 10,
    // onClick: (d) => (d.object.url ? (window.location = d.object.url) : ''),
    getPosition: (d) => d.location,
    getOrientation: (d) => [180 + d.rotation[0], d.rotation[1], d.rotation[2]],
    getScale: (d) => [d.scale[0], d.scale[1], d.scale[2]],
    onHover: (d) => onHover?.(
      d?.object?.url
        ? d
        : null
    ),
  });
}

export const createH3Layer = (locations, color = [255, 0, 0]) => {
  return new H3HexagonLayer({
    // id: `h3-hexagon-${v1()}`,
    // data: locations,
    // wireframe: false,
    // filled: true,
    // extruded: false,
    // opacity: 1,
    // coverage: 0.9,
    // getHexagon: (d) => d,
    // getFillColor: color,
    id: `h3-hexagon-${v1()}`,
    data: locations,
    wireframe: true,
    filled: true,
    extruded: true,
    getHexagon: (d) => d,
    getFillColor: color,
    getElevation: d => 2
  });
};


export const createHexList = (location) => {
  if (!location.length > 0) return;
  return [geoToH3(location[1], location[0], 13)];
};

/**
 * @name    geoToHex
 * @summary generates a coordinate to 13 resolution hexagon
 * 
 * @param   {Number} lat 
 * @param   {Number} lon 
 * 
 * @returns {String}
 */
export const geoToHex = ([lon, lat]) => geoToH3(lat, lon, 13)

/**
 * @name    inHexLayer
 * @summary check if coordinate is in the list of hexes
 * 
 * @param   {Array}   coordinate
 * @param   {Number}  coordinate[0] longitude
 * @param   {Number}  coordinate[1] lattitude
 * @param   {Array}   hexList       list of hexes
 *  
 * @returns {Boolean}
 */
export const inHexLayer = (coordinate, hexList = []) => hexList.includes(
  geoToHex(coordinate)
)
