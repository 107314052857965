import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFirebase } from '../Firebase';
import { withAxios } from '../api';
import { withAuthorization } from '../Session';
import Orders from './Orders';

const mapStateToProps = ({ firebaseReducer }) => ({
  user: firebaseReducer.user,
});

export default compose(
  withAuthorization((authUser) => !!authUser),
  withFirebase,
  withAxios,
  connect(
    mapStateToProps,
    null,
  ),
)(Orders);
