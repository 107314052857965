import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";

const padding = isMobile
  ? 25
  : 112
const useStyles = makeStyles((theme) => ({
  root: {
    padding: `100px ${padding}px`,
    //overflow: 'scroll',
  },
  body: {
    height: '200vh',
    overflow: 'scroll',
    paddingBottom: 200,
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    padding: 0,
  },
  titleStats: {
    fontSize: 24,
    position: 'relative',
    bottom: 4,
    paddingLeft: 64,
  },
  titleRow: {
    display: isMobile
      ? 'block'
      : 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    width: '100%',
    lineHeight: 44,
    paddingBottom: isMobile
      ? 0
      : 48,
  },
  orderSection: {
    width: '100%',
    padding: 4,
    overflow: 'auto',

  },
  orderContainer: {
    width: '100%',
    minWidth: 900,
    height: 128,
    border: '1px solid rgba(112,112,112,.41)',
    borderRadius: 18,
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: 20,
    '&:hover': {
      boxShadow: '0px 0px 3px rgba(112,112,112,.61)',
    },
  },
  orderItem: {
    flex: 1,
    minWidth: 225,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  orderLabel: {
    textTransform: 'uppercase',
    fontSize: 14,
    paddingBottom: 28,
  },
  orderValue: {
    fontSize: 14,
    color: '#6A6A6A',
  },
  status: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  completed: {
    color: theme.palette.success.main,
  },
  failed: {
    color: theme.palette.error.main,
  },
  waiting: {
    color: theme.palette.info.main,
  },
  processing: {
    color: theme.palette.warning.main,
  },
  view: {
    fontSize: 12,
    padding: 5,
    width: 80,
    maxWidth: 400,
    background: 'black',
    color: 'white',
    '&:hover': {
      background: '#262cf2',
    },
  },
}));

export default useStyles;
