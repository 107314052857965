import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    root: {
    right: 0,
    top: 0,
    position: 'absolute',
    width: 330,
    backgroundColor: 'white',
    height: '85vh',
    bottom: 0,
    padding: "2rem",
    overflowY: 'auto',
  },
  hideprop: {
    display: 'none',
  },
  editformlabel: {
    fontWeight: 'bold',
    marginTop: 10
  },
  editInputArea: {
    padding: "9.5px 14px",
    width:'250px'
  },
  colorSelector: {
    width: 270,
    height: 39,
    flex: 1,
    borderRadius: 8,
  },
  editslidelabel: {
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 10
  },
  tdslide: {
    padding: '0px',
    width: '184px',
    borderBottom: 'none'
  },
  tdfield: {
    paddingRight: '0px',
    paddingTop: '0px',
    borderBottom: 'none'
  },
}));

export default useStyles;