import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((edit) => ({
  root: {
    height: '100vh',
    position: 'relative',
    margin: () => (edit === true ? '0px 250px' : 0),
  },
  marker: {
    height: 25,
    width: 25,
    borderRadius: 180,
    background: 'red',
  },
  navigation: {
    zIndex: 10,
    position: 'absolute',
    right: 300,
    marginTop: 20,
    marginRight: 40,
  },
  menuContainer: {
    position: 'absolute',
    top: 84,
    display: 'flex',
    // justifyContent: 'flex-start',
  },
  geocoder: {
    // flex: 1,
  },
  selectInput: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  select: {
    position: 'relative',
  },
  selectTool: {
    height: 42,
    //marginTop: 4,
    color: 'white',
    background: 'black',
    zIndex: 10,
    minWidth: 300,
    borderRadius: 18,
    fontSize: 15,
    fontWeight: 700,
    boxShadow: '0px 3px 6px #00000069',
    padding: '8px 16px',
  },
  modalWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 15,
    background: '#00000096',
    transition: 'background .5s',
  },
  modalContainer: {
    background: 'white',
    borderRadius: 18,
    boxShadow: '0px 3px 6px #00000069',
    width: '100%',
    maxWidth: 350,
    padding: '12px 24px',
  },
  modalSaveButton: {
    marginTop: 24,
    marginRight: 24,
    background: '#262cf2',
  },
  modalButton: {
    textTransform: 'none',
  },
  modalButtonRowRight: {
    flex: 1,
    marginTop: 12,
    textAlign: 'right',
  },
  modalButtonRowCenter: {
    flex: 1,
    marginTop: 12,
    width: '100%',
    textAlign: 'center',
  },
  confirmBtn: {
    background: '#00CB7D',
    minWidth: 80,
    // width: '100%',
    borderRadius: 12,
    color: 'white',
    fontWeight: 700,
    margin: '12px 0px',
    '&:hover': {
      background: '#008873',
    },
  },
  cancelBtn: {
    background: '#D3D3D3',
    minWidth: 80,
    // width: '100%',
    borderRadius: 12,
    color: 'white',
    fontWeight: 700,
    margin: '12px 2px',
    '&:hover': {
      background: '#D3D3D3',
    },
  },
  closeButtonContainer: {
    flex: 1,
    textAlign: 'right',
  },
  modalText: {
    fontSize: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    margin: '12px 0px',
  },
}));

export default useStyles;
