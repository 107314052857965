/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import {
  Button, IconButton, Typography, Table, TableContainer,
  TextField, TableRow, TableCell, TableBody,
  FormControlLabel, Switch, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, DialogContentText
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import useStyles from './MapLocations.styles';
import app from 'firebase/app';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ColorPicker from 'material-ui-color-picker';
import Slider from '../Slider';
import { scaleDefault } from '../Drawer/objectFiles';
const convert = require('color-convert');

export const ModalStructure = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.modalWrapper}>
      <div className={classes.modalContainer}>
        {children}
      </div>
    </div>
  );
};

export const InfoModal = ({ onCancel, setDisplayobj, displayobj, displayProp, setDisplayProp }) => {
  const classes = useStyles();
  function objManage() {
    setDisplayobj(false);
    onCancel();
  }
  function objManage1() {
    setDisplayobj(true);
    onCancel();
  }
  function propManage() {
    setDisplayProp(false);
    onCancel();
  }
  function propManage1() {
    setDisplayProp(true);
    onCancel();
  }
  function bothManage() {
    setDisplayobj(false);
    setDisplayProp(false);
    onCancel();
  }
  function bothManage1() {
    setDisplayobj(true);
    setDisplayProp(true);
    onCancel();
  }
  return (
    <ModalStructure>
      <div className={classes.modalButtonRowCenter}>
        <Button className={classes.buttonlabel} variant="outlined" onClick={() => { displayobj ? objManage() : objManage1() }} >
          {displayobj ? 'Hide Search Object' : 'Add Search Object'}
        </Button>
      </div>
      <div className={classes.modalButtonRowCenter} >
        <Button className={classes.buttonlabel} variant="outlined" onClick={() => { displayProp ? propManage() : propManage1() }}>
          {displayProp ? 'Hide Properties' : 'Add Properties'}
        </Button>
      </div>
      {/* <div className={classes.modalButtonRowCenter}>
        <Button className={classes.buttonlabel} variant="outlined" onClick={() => { displayobj && displayProp ? bothManage() : bothManage1() }} >
          {displayobj && displayProp ? 'Hide Search Object & Properties' : 'Add Search Object & Properties'}
        </Button>
      </div> */}
      <div className={classes.modalButtonRowCenter}>
        <Button className={classes.buttonlabel} variant="outlined" onClick={() => onCancel()}>
          Close
        </Button>
      </div>
      <div className={classes.modalButtonRowCenter}>
        {/* <Button className={classes.modalButton} onClick={onCancel}>
          Close
        </Button> */}
      </div>
    </ModalStructure >
  );
};



export const EditObjectModal = (
  {
    despt,
    editname,
    color,
    confirmModal,
    contentid,
    draftObjectDetails,
    name,
    position,
    removeObj,
    scale,
    setColor,
    setConfirmModal,
    setName,
    setUrl,
    setDespt,
    setEditname,
    setDraftObjectDetails,
    url,
  }
) => {

  const [check, setCheck] = React.useState(true);
  const [localColor, setLocalColor] = React.useState(`#${convert.rgb.hex(color)}`);
  const [objectDetails, setObjectDetails] = React.useState(draftObjectDetails);
  const {
    min = scaleDefault.min,
    max = scaleDefault.max,
  } = scale || {}
  const handleSlide = (v, index) => {
    const newState = [...objectDetails];
    const res = v;
    newState[index] = res;
    setObjectDetails(newState);
  };
  const handleAttch = async (scaleNum) => {

    const newState = [...objectDetails];
    newState[3] = scaleNum;
    newState[4] = scaleNum;
    newState[5] = scaleNum;
    setObjectDetails(newState);
  }
  const handleColor = (c) => {
    // return if undefined
    if (!c) return;
    setLocalColor(c);
    // if hex
    if (c[0] === '#') return setColor([...convert.hex.rgb(c), 255]);
    // if rgba
    const returnColor = c
      .slice(5, -1)
      .split(', ')
      .map((i) => i * 1);
    returnColor[3] = Math.floor(returnColor[3] * 255);
    return setColor(returnColor);
  };
  function cancel() {
    setEditname(false)
    setConfirmModal(false)
  }
  function handleUpdate() {
    app.firestore()
      .collection('objects')
      .doc(contentid)
      .update({
        label: name,
        url,
        description: despt,
        color,
        rotation: [
          objectDetails[0],
          objectDetails[1],
          objectDetails[2],
        ],
        scale: [
          objectDetails[3], // x
          objectDetails[4], // y
          objectDetails[5], // z
        ],
        location: [position[0], position[1], objectDetails[6]],
      })
      .then(res => {
        toast.dark(`Object properties edited sucessfully !!`)
        setEditname(false)
        setConfirmModal(false)
      })
      .catch(err => toast.error(err.message))
  }
  const classes = useStyles();

  return (
    <div>
      <Dialog open={confirmModal} onClose={cancel}
      >
        <DialogTitle className={classes.editTitle}>
          {editname ? "Edit Object Properties" : null}
        </DialogTitle>
        <DialogContent>
          {
            editname ?
              <>
                <div>
                  <Typography className={classes.editformlabel}>Label</Typography>
                  <TextField
                    InputProps={{ classes: { input: classes.editInputArea } }}
                    value={name}
                    onChange={e => setName(e.target.value)}
                    type="text"
                    variant="outlined"
                  />
                </div>
                <div>
                  <Typography className={classes.editformlabel}>Url</Typography>
                  <TextField
                    InputProps={{ classes: { input: classes.editInputArea } }}
                    value={url}
                    onChange={e => setUrl(e.target.value)}
                    type="text"
                    variant="outlined"
                    placeholder="https://example.com"
                  />
                </div>
                <div>
                  <Typography className={classes.editformlabel}>Description</Typography>
                  <TextField
                    InputProps={{ classes: { input: classes.editInputArea } }}
                    value={despt}
                    onChange={e => setDespt(e.target.value)}
                    type="text"
                    variant="outlined"
                  />
                </div>
                <div>
                  <Typography className={classes.editformlabel}>Color</Typography>
                  <ColorPicker
                    className={classes.colorSelector}
                    name="color"
                    value={localColor}
                    TextFieldProps={{
                      variant: 'outlined',
                      style: { background: localColor, color: localColor, padding: 4 },
                      className: classes.input,
                    }}
                    InputProps={{ classes: { input: classes.input } }}
                    onChange={handleColor}
                  />
                </div>
                <div>
                  <Typography className={classes.editslidelabel} >Position</Typography>
                  <Slider
                    label="Height"
                    min={0}
                    max={100}
                    value={objectDetails[6]}
                    handleChange={(e, v) => handleSlide(v, 6)}
                    valueLabelDisplay="auto"
                    getAriaValueText={objectDetails[6]}
                  />
                </div>
                <div>
                  <Typography className={classes.editslidelabel} >Rotation</Typography>
                  <Slider
                    label="x-axis"
                    min={0}
                    max={360}
                    step={1}
                    value={objectDetails[0]}
                    handleChange={(e, v) => handleSlide(v, 0)}
                    valueLabelDisplay="auto"
                    getAriaValueText={objectDetails[0]}
                  />
                  <Slider
                    label="y-axis"
                    min={0}
                    max={360}
                    step={1}
                    value={objectDetails[1]}
                    handleChange={(e, v) => handleSlide(v, 1)}
                    valueLabelDisplay="auto"
                    getAriaValueText={objectDetails[1]}
                  />
                  <Slider
                    label="z-axis"
                    min={0}
                    max={360}
                    step={1}
                    value={objectDetails[2]}
                    handleChange={(e, v) => handleSlide(v, 2)}
                    valueLabelDisplay="auto"
                    getAriaValueText={objectDetails[2]}
                  />
                </div>
                <div>
                  <Typography className={classes.editformlabel} >Scale   <AttachFileIcon />
                    <FormControlLabel style={{ marginBottom: '10px' }}
                      control={
                        <Switch
                          checked={check}
                          onChange={() => { setCheck(check ? false : true) }}
                          color="primary"
                        />
                      }
                    />

                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row" align="left" className={classes.tdslide}>
                            <Slider
                              label="x-axis"
                              min={min}
                              max={max}
                              step={(max - min) / 100}
                              value={objectDetails[3]}
                              handleChange={(e, v) => { check ? handleAttch(v) : handleSlide(v, 3) }}
                              valueLabelDisplay="auto"
                              getAriaValueText={objectDetails[3]}
                            />
                          </TableCell>
                          <TableCell align="left" className={classes.tdfield}>
                            <TextField
                              type='number'
                              min={min}
                              max={max}
                              step={(max - min) / 100}
                              value={objectDetails[3]}
                              onChange={e => { check ? handleAttch(e.target.value) : handleSlide(e.target.value, 3) }}
                              variant="outlined"
                              inputProps={{
                                style: {
                                  padding: `12.5px 0px`,
                                  textAlign: 'center',
                                  fontSize: 'small',
                                  width: '70px'
                                }
                              }}
                            />

                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row" align="left" className={classes.tdslide}>
                            <Slider
                              label="y-axis"
                              min={0.01}
                              max={10}
                              step={0.01}
                              value={objectDetails[4]}
                              handleChange={(e, v) => { check ? handleAttch(v) : handleSlide(v, 4) }}
                              valueLabelDisplay="auto"
                              getAriaValueText={objectDetails[4]}
                            />
                          </TableCell>
                          <TableCell align="left" className={classes.tdfield}>
                            <TextField
                              type='number'
                              min={0.01}
                              max={10}
                              step={0.01}
                              value={objectDetails[4]}
                              onChange={e => { check ? handleAttch(e.target.value) : handleSlide(e.target.value, 4) }}
                              variant="outlined"
                              inputProps={{
                                style: {
                                  padding: `12.5px 0px`,
                                  textAlign: 'center',
                                  fontSize: 'small',
                                  width: '70px'
                                }
                              }}
                            />

                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row" align="left" className={classes.tdslide}>
                            <Slider
                              label="z-axis"
                              min={0.01}
                              max={10}
                              step={0.01}
                              value={objectDetails[5]}
                              handleChange={(e, v) => { check ? handleAttch(v) : handleSlide(v, 5) }}
                              valueLabelDisplay="auto"
                              getAriaValueText={objectDetails[5]}
                            />
                          </TableCell>
                          <TableCell align="left" className={classes.tdfield}>
                            <TextField
                              type='number'
                              min={0.01}
                              max={10}
                              step={0.01}
                              value={objectDetails[5]}
                              onChange={e => { check ? handleAttch(e.target.value) : handleSlide(e.target.value, 5) }}
                              variant="outlined"
                              inputProps={{
                                style: {
                                  padding: `12.5px 0px`,
                                  textAlign: 'center',
                                  fontSize: 'small',
                                  width: '70px'
                                }
                              }}
                            />

                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </>
              :
              <>
                <div className={classes.modalButtonRowCenter}>
                  <Button className={classes.buttonlabel} variant="outlined" onClick={() => setEditname(true)}>
                    {'Edit Object Properties'}
                  </Button>
                </div>
                <div className={classes.modalButtonRowCenter} >
                  <Button className={classes.buttonlabel} variant="outlined" onClick={() => removeObj()}>
                    Remove Object
                  </Button>
                </div>
                <div className={classes.modalButtonRowCenter}>
                  <Button className={classes.buttonlabel} variant="outlined" onClick={() => { setConfirmModal(false) }}>
                    Back to Map
                  </Button>
                </div>
              </>

          }
        </DialogContent>
        <DialogActions mt={3} padding={5}>
          {
            editname ?
              <>
                <Button onClick={cancel} color="primary">
                  Close
                </Button>
                <Button onClick={handleUpdate} color="primary" autoFocus>
                  Done
                </Button>
              </>
              : null
          }

        </DialogActions>
      </Dialog>
    </div>
  );
}

export const DeleteObjectConfirmation = ({ open, setOpen, setValue }) => {
  const handleClose = (answer) => {
    if (answer === 'yes') {
      setValue(true);
    } else {
      setValue(false);
    }
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete Object?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this object?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose("yes")} color="secondary" autoFocus>
            Yes
          </Button>
          <Button onClick={() => handleClose("no")} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
