import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((edit) => ({
  root: {
    height: '100vh',
    position: 'relative',
    margin: () => (edit === true ? '0px 250px' : 0),
  },
  marker: {
    height: 25,
    width: 25,
    borderRadius: 180,
    background: 'red',
  },
  navigation: {
    zIndex: 10,
    position: 'absolute',
    right: 10,
    top: 20,
    marginRight: 40,
  },
  menuContainer: {
    position: 'absolute',
    top: 20,
    display: 'flex',
    // justifyContent: 'flex-start',
  },
  geocoder: {
    // flex: 1,
  },
  selectInput: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  select: {
    position: 'relative',
  },
  selectTool: {
    height: 42,
    //marginTop: 4,
    color: 'white',
    background: 'black',
    zIndex: 10,
    minWidth: 300,
    borderRadius: 18,
    fontSize: 15,
    fontWeight: 700,
    boxShadow: '0px 3px 6px #00000069',
    padding: '8px 16px',
  },
  modalWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 15,
    background: '#00000096',
    transition: 'background .5s',
  },
  modalContainer: {
    background: 'white',
    borderRadius: 18,
    boxShadow: '0px 3px 6px #00000069',
    width: '100%',
    maxWidth: 350,
    padding: '12px 24px',
  },
  modalSaveButton: {
    marginTop: 24,
    marginRight: 24,
    background: '#262cf2',
  },
  modalButton: {
    textTransform: 'none',
  },
  modalButtonRowCenter1: {
    flex: 1,
    marginTop: 10,
    textAlign: 'center',
  },
  modalButtonRowCenter: {
    flex: 1,
    marginTop: 12,
    width: '100%',
    textAlign: 'center',
  },
  confirmBtn: {
    background: '#00CB7D',
    minWidth: 80,
    // width: '100%',
    borderRadius: 12,
    color: 'white',
    fontWeight: 700,
    margin: '12px 0px',
    '&:hover': {
      background: '#008873',
    },
  },
  cancelBtn: {
    background: '#D3D3D3',
    minWidth: 80,
    // width: '100%',
    borderRadius: 12,
    color: 'white',
    fontWeight: 700,
    margin: '12px 7px',
    '&:hover': {
      background: '#D3D3D3',
    },
  },
  closeButtonContainer: {
    flex: 1,
    textAlign: 'right',
  },
  modalText: {
    fontSize: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    margin: '12px 0px',
  },
  button: {
    marginTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 6,
    paddingBottom: 6,
    marginBottom: 5,
    fontSize: 12,
    maxWidth: 400,
    background: 'black',
    color: 'white',
    '&:hover': {
      background: '#262cf2',
    },
  },
  buttonlabel: {
    width: 223,
    fontSize: 12,
    maxWidth: 410,
    background: 'black',
    color: 'white',
    '&:hover': {
      background: '#262cf2',
    },
  },

  editformlabel: {
    fontWeight: 'bold',
    marginTop: 10,
  },
  editslidelabel: {
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 10,
  },
  editInputArea: {
    padding: '9.5px 14px',
    width: '250px',
  },
  colorSelector: {
    width: 270,
    height: 39,
    flex: 1,
    borderRadius: 8,
  },
  tdslide: {
    padding: '0px',
    width: '184px',
    borderBottom: 'none',
  },
  tdfield: {
    paddingRight: '0px',
    paddingTop: '0px',
    borderBottom: 'none',
  },
  editTitle: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bolder',
  },
}));

export default useStyles;
