
export const noop = () => {}

export const setUser = (user) => ({
  type: 'SET_USER',
  payload: user,
})

export const setViewState = (viewState) => ({
  type: 'SET_VIEWSTATE',
  payload: viewState
})

export const setCookiePopup = (popupState) => ({
	type: 'SET_COOKIEPOPUP',
	payload: popupState
})