import app from 'firebase/app';

const dbRestrictedAreas = app.firestore().collection('restricted_areas');

/*
Function to know if a set of XY coordinates are inside a rectangle (and polygons in the future)
coordinates is an object {x , y}
region_coordinates is an array of objects [{x , y},{x , y}]
*/
export const isInsideRegion = (coordinates, region_coordinates, type = 'rectangle') => {
  let x1 = region_coordinates[0].x;
  let y1 = region_coordinates[0].y;
  let x2 = region_coordinates[1].x;
  let y2 = region_coordinates[1].y;

  if (
    coordinates.x >= x1 &&
    coordinates.y >= y1 &&
    coordinates.x <= x2 &&
    coordinates.y <= y2 &&
    type == 'rectangle'
  ) {
    return true;
  }
  // if its a polygon ... apply a different logic

  return false;
};

export const getRestrictedAreas = async () => {
  const areas = await dbRestrictedAreas.where('enabled', '==', true).get();
  const result = [];
  areas.forEach((area) => {
    result.push(area.data());
  });
  return result;
};
