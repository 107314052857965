// import fs from 'fs'
// import fetch from 'node-fetch'
// import config from './config'

// const { debug, verbose } = config

/**
 * @name    addTs
 * @summary adds created and updated timestamp to a document/object
 * 
 * @param   {Object} doc 
 * 
 * @returns {Object} doc
 */
export const addTs = doc => {
    const now = new Date()
    doc.ts_created = doc.ts_created || now
    doc.ts_updated = now
}

/**
 * @name    arrSort
 * @summary sort an array of objects by key
 * 
 * @param   {Array} arr 
 * @param   {String} key 
 * @returns 
 */
export const arrSort = (arr, key) => [...arr]
    .sort((a, b) => a[key] > b[key] ? 1 : -1)

/**
 * @name    arrUnique
 * @summary creates a new array of unique values.
 *          Can be used to combine multiple arrays and/or values into single array of unique values.
 * 
 * @param  {...any} args 
 * 
 * @returns {Array}
 */
export const arrUnique = (...args) => Array.from(new Set([...args].flat()))

/**
 * @name	deferred
 * @summary returns a function that invokes the callback function after certain delay/timeout
 * 
 * @param	{Function}	callback 	function to be invoked after timeout
 * @param	{Number}	delay		(optional) timeout duration in milliseconds.
 * 									Default: 50
 * @param	{*}			thisArg		(optional) the special `thisArgs` to be used when invoking the callback.
 * 
 * @returns {Function}
 */
export const deferred = (callback, delay, thisArg) => {
    if (!isFn(callback)) return // nothing to do!!
    let id
    return (...args) => {
        if (id) clearTimeout(id)
        id = setTimeout(() => callback.apply(thisArg, args), delay || 50)
    }
}

/** 
 * @name deferredPromise
 * @summary the adaptation of the `deferred()` function tailored for Promises.
 * @description The main difference is that deferredPromise is to be used with promises 
 * and there is no specific time delay. The last/only promise in an on-going promise pool will be handled.
 * The time when a supplied promise is resolved is irrelevant. 
 * Once a promise is handled all previous ones will be ignored and new ones will be added to the pool.
 *
 * Params: 	No parameter accepted
 * 
 * @example Explanation & example usage:
 * <BR>
 * ```javascript
 *    const df = deferredPromise()
 *    const delayer = delay => new Promise(r => setTimeout(() => r(delay),  delay))
 *    df(delayer(5000)).then(console.log)
 *    df(delayer(500)).then(console.log)
 *    df(delayer(1000)).then(console.log)
 *    setTimeout(() => df(delayer(200)).then(console.log), 2000)
 * ```
 * 
 * @returns {Function} callback accepts only one argument and it must be a promise
*/
export const deferredPromise = () => {
    let ids = []
    const done = (cb, id) => function () {
        const index = ids.indexOf(id)
        // Ignore if:
        // 1. this is not the only/last promise
        // 2. if a successor promise has already resolved/rejected
        if (index === -1 || index !== ids.length - 1) return
        // invalidates all unfinished previous promises
        ids = []
        cb.apply(null, arguments)
    }
    return promise => new Promise((resolve, reject) => {
        const id = Symbol()
        ids.push(id)
        try {
            promise.then(
                done(resolve, id),
                done(reject, id),
            )
        } catch (err) {
            reject(err)
        }
    })
}

// /**
//  * @name    delayUntilResumed
//  * @summary waits until file named `pause` is removed.
//  * 
//  * @param   {String} debugTag (optional)
//  */
// export const delayUntilResumed = async (debugTag = '') => {
//     let paused
//     while (fileExists('paused')) {
//         paused = true
//         log(debugTag, 'Service paused. Waiting 1 minute to check again.')
//         await delay(60 * 1000)
//     }
//     paused && log(debugTag, 'Service resumed.')
// }

/**
 * @name    delay
 * @summary returns a promise that resolves after given duration
 * 
 * @param   {Number} duration duration in milliseconds
 */
export const delay = async (duration = 100) => new Promise(r => setTimeout(() => r(), duration))

// export const fileExists = path => {
//     // only for use on nodejs
//     try {
//         require('fs').existsSync(path)
//     } catch (err) {
//         return false
//     }
// }

export const isArr = x => Array.isArray(x)
export const isBool = x => typeof x === 'boolean'
export const isDefined = x => x !== undefined && x !== null
export const isFn = x => typeof x === 'function'
export const isMap = x => x instanceof Map
export const isObj = x => !!x && typeof x === 'object' && !isArr(x)
export const isSet = x => x instanceof Set
export const isStr = x => typeof x === 'string'
export const isSubjectLike = x => isObj(x) && isFn(x.subscribe)
export const isURL = x => {
    try {
        new URL(x)
        return true
    } catch (err) {
        return false
    }
}
export const isValidNumber = x => typeof x == 'number' && !isNaN(x) && isFinite(x)
export const hasValue = x => {
    try {
        if (!isDefined(x)) return false
        switch (typeof x) {
            case 'string': return isStr(x) && !!x.trim()
            case 'number': return isValidNumber(x)
            // for both array and object
            case 'object':
                if (isArr(x)) return x.length > 0
                if (isMap(x) || isSet(x)) return x.size > 0
                return Object.keys(x).length > 0
            case 'boolean':
            default: return true // already defined
        }
    } catch (_) {
        return false
    }
}
// /**
//  * @name    getBSVAddress
//  * @summary retrieves a new BSV address for supported paymail handle
//  * 
//  * @param   {String} paymail    Paymail handle. Eg: `user@handcash.com`.
//  *                              If BSV address supplied, it will be returned as is.
//  *                              If invalid BSV address supplied, it will throw an error.
//  * @param   {Number} timeout    (Optional) request timeout duration in milliseconds.
//  *                              Default: `10000`
//  * 
//  * @returns {String}            BSV address 
//  */
// export const getBSVAddress = async (paymail, timeout = 10000) => {
//     if (!isStr(paymail)) throw new Error('Invalid paymail handle')

//     const url = `https://api.polynym.io/getAddress/${paymail}`
//     const options = { method: 'GET', timeout }
//     const result = await fetch(url, options)
//     const { address, error } = await result.json()

//     if (error || !address) throw new Error('No address found')

//     return address
// }

// /**
//  * @name    log
//  * @summary shorthand for console.log but will only print if SSMB debug environment variable is set to `"true"`
//  * 
//  * @param   {...any} args 
//  * 
//  * 
//  * @returns {Boolean}   if printed
//  */
// export const log = (...args) => debug && console.log(
//     new Date().toISOString(),
//     ...args,
// ) || debug

// /**
//  * @name    log
//  * @summary sugar for `log` but will only print when verbose mode is on
//  *
//  * @param   {...any} args
//  * 
//  * @returns {Boolean}   if printed
//  */
// export const logV = (...args) => debug && verbose && log(...args) || verbose

/**
 * @name	className
 * @summary formats supplied value into CSS class name compatible string for React
 * 
 * @param	{Object|Array} value 
 * 
 * @returns	{String}
 * 
 * @example ```JavaScript
 * const isSection = false
 * const isIcon = true
 * const withBorder = false
 * const str = className([
 *     'ui',
 *     { section: isSection, icon: isIcon },
 *     withBorder && 'bordered',
 * ])
 * 
 * // expected result: 'ui icon'
 * ```
 */
export const className = value => {
    if (isStr(value)) return value
    if (isObj(value)) {
        // convert into an array
        value = Object.keys(value)
            .map(key => !!value[key] && key)
    }
    if (!isArr(value)) return ''
    return value
        .filter(Boolean)
        .map(x => !isObj(x) ? x : className(x))
        .join(' ')
}