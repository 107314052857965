import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((edit) => ({
  root: {
    height: '100vh',
    position: 'relative',
    margin: (edit) => (edit == true ? '0px 250px' : 0),
  },
  marker: {
    height: 25,
    width: 25,
    borderRadius: 180,
    background: 'red',
  },
  navigation: {
    zIndex: 10,
    position: 'absolute',
    right: 0,
    top: 10,
  },
  geocoder: {
    top: 10,
    zIndex: 10,
  },
  toolTip: {
    position: 'absolute',
    zIndex: 10,
    background: 'white',
    color: 'black',
    fontWeight: 800,
    fontSize: 14,
    border: '1px solid black',
    borderRadius: 25,
    padding: '0px 8px',
  },
  confirmation: {
    position: 'absolute',
    zIndex: 10,
    background: 'white',
    borderRadius: 8,
    padding: 0,
    fontSize: 14,
  },
  saveIcon: {
    color: '#4caf50',
  },
  moveIcon: {
    color: '#2196f3',
  },
  cancelIcon: {
    color: '#f44336',
  },
}));

export default useStyles;
