import React from 'react';
import { Typography } from '@material-ui/core/';

export default ({ message }) => (
  <div style={{
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }}>
    <Typography>{message || 'Loading...'}</Typography>
  </div>
)