import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

const config = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    if ((process.env.REACT_APP_ENV || '').includes('staging')) app.analytics();

    this.auth = app.auth();
    this.firestore = app.firestore();
    this.provider = new app.auth.GoogleAuthProvider();

  }

  /**
   *
   *  *** AUTH API ***
   *
   * /

  /**
   * Allows a user to sign in a user who is signed up with Firebase
   * @param  {*} email
   * @param  {*} password
   */
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  /**
   * Creates a new user in Firebase
   * @param  {*} email
   * @param  {*} password
   */
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  getFireStore = () => app.firestore();


  /**
   * Signs out an authenticated user
   * @param  {} =>this.auth.signOut(
   */
  doSignOut = () => this.auth.signOut();

  /**
   * Save structures to firebase
   */
  saveCity = (cityData) =>
    this.firestore.collection('city').doc('city').set(cityData, { merge: true });

  saveLocations = (hexes) =>
    this.firestore.collection('city').doc('locations').set(hexes, { merge: true });

  loadCity = () => this.firestore.collection('city').doc('city').get();

  signInWithGoogle = () => this.auth.signInWithPopup(this.provider);
}

// Initialize Firebase
const instance = new Firebase()
export default instance
