import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { BehaviorSubject } from 'rxjs'
import app from 'firebase/app'
import 'firebase/auth'
import { Box, Button } from '@material-ui/core'
import {
    passwordCriterias,
    validateInput,
} from '../common/form/InputCriteriaHint'
import firebase from '../Firebase'
import Message, { STATUS } from '../common/Message'
import useRxSubject from '../common/useRxSubject'
import FormInput from '../common/form/FormInput'
import modalService from '../common/modal/modalService'

/**
 * @name    verifyCurrentPassword
 * @summary check if entered password is the current password by re-authenticating with it
 * 
 * @param   {String} email 
 * @param   {String} password 
 * 
 * @returns {Boolean}
 */
const verifyCurrentPassword = async (email, password) => {
    const credential = await app
        .auth
        .EmailAuthProvider
        .credential(
            email,
            password,
        )
    return new Promise((resolve, reject) => {
        app.auth().onAuthStateChanged(async (user) => {
            try {
                await user.reauthenticateWithCredential(credential)
                resolve(user)
            } catch (err) {
                resolve(false)
            }
        })
    })
}

/**
 * @name    reAuthenticateGoogle
 * @summary force re-authenticate using Google
 * 
 * @returns {Object} user
 */
const reAuthenticateGoogle = async () => {
    await firebase.signInWithGoogle()

    return new Promise(resolve => {
        app.auth()
            .onAuthStateChanged(async (user) =>
                resolve(user)
            )
    })
}
/**
 * @name    checkPwProvider
 * @sumamry check if user has set a password
 * 
 * @param   {Object} user current user
 * 
 * @returns {Boolean}
 */
const checkPwProvider = user => user.providerData
    .find(x => x.providerId === 'password')

export default function PasswordResetForm(props) {
    const { user, values: { email } = {} } = props
    const gotPwProvider = checkPwProvider(user)
    const [message, setMessage] = useState(null)
    const [rxInputs] = useState(() =>
        new BehaviorSubject([
            // hide current password field if user signed up using Google and setting up password for the first time
            {
                label: 'Current password',
                name: 'passwordOld',
                // force trigger validation on new pw
                onChange: (_, inputs) => {
                    const pw = findInput('passwordNew', inputs)
                    validateInput(pw)
                    return inputs
                },
                required: true,
                type: 'password',
                value: '',
            },
            {
                label: 'New password',
                name: 'passwordNew',
                // force trigger validation on pw repeat
                onChange: (_, inputs) => {
                    const pwr = findInput('passwordRepeat', inputs)
                    validateInput(pwr)
                    return inputs
                },
                required: true,
                type: 'password',
                validation: {
                    criterias: [
                        ...passwordCriterias,
                        // force user to enter a different password than current one
                        gotPwProvider && {
                            text: 'Please enter a new password',
                            validate: value => findInput('passwordOld').value !== value,
                        },
                    ].filter(Boolean),
                    title: 'Please enter a password matching the following criteria: ',
                },
                value: '',
            },
            {
                label: 'Repeat new password',
                name: 'passwordRepeat',
                required: true,
                type: 'password',
                validation: {
                    hideOnValid: true,
                    criterias: [{
                        text: 'Passwords do not match',
                        valid: false,
                        validate: value => findInput('passwordNew').value === value,
                    }],
                },
                value: '',
            },
        ])
    )
    let [inputs] = useRxSubject(rxInputs, x => (x || []))
    // hide current password field if first time setting a password
    inputs = inputs.filter(x =>
        x.name !== 'passwordOld' || gotPwProvider
    )
    const findInput = (name, _inputs = inputs) => _inputs.find(x => x.name === name)

    if (!inputs?.length) return ''

    async function handleSubmit() {
        let msg = gotPwProvider
            ? 'Verifying existing password'
            : `Please verify your Google account by re-authenticating using ${email}`

        try {
            let authUser = user
            const passwordNew = findInput('passwordNew').value

            setMessage({
                status: STATUS.loading,
                text: msg,
            })
            if (gotPwProvider) {
                const passwordOld = findInput('passwordOld').value
                authUser = await verifyCurrentPassword(email, passwordOld)
                if (!authUser) return setMessage({
                    status: STATUS.error,
                    text: 'Please retry with your current password!'
                })
            } else {
                const ok = await new Promise(resolve => {
                    modalService.confirm({
                        confirmButton: 'Proceed',
                        content: msg,
                        maxWidth: 'xs',
                        onConfirm: ok => resolve(ok),
                        title: '',
                    }, 're-auth-google')
                })
                if (!ok) return setMessage(null)
                // first time setting password -> prompt user to re-authenticate using the same Google account
                authUser = await reAuthenticateGoogle()
                // If use logged in using a different Google account
                if (authUser.email !== email) return alert(
                    'Unable update your password! You have logged in using a different Google account'
                )
            }

            setMessage({
                status: STATUS.loading,
                text: 'Updating password...',
            })
            // attempt to update user's password
            await authUser.updatePassword(passwordNew)
            setMessage({
                status: STATUS.success,
                text: 'Password updated successfully!'
            })
        } catch (err) {
            msg = `Failed to update password. ${err.message}`
            if (msg.includes('The popup has been closed by the user')) {
                // user close the Google login window without verifying their Google account
                msg = `In order to set a new password you must re-authenticate using ${email}`
            }
            setMessage({
                status: STATUS.error,
                text: msg,
            })
        }
    }
    const loading = message && message.status === STATUS.loading
    const submitDisabled = !!inputs.find(x => !x.valid) || loading
    return (
        <Box
            component='form'
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete='off'
        >
            {inputs.map(input => {
                let { name, onChange } = input

                return (
                    <FormInput {...{
                        ...input,
                        onChange: e => {
                            const { value } = e.target
                            const input = findInput(name)
                            input.value = value
                            validateInput(input)
                            rxInputs.next([...(onChange?.(value, inputs) || inputs)])
                            // clear message
                            message && setMessage(null)
                        }
                    }} />
                )
            })}
            {message && <Message {...message} />}
            <div style={{
                cursor: loading ? 'progress' : '',
                padding: '15px 0 10px 0',
                textAlign: 'right',
            }}>
                <Button
                    disabled={submitDisabled}
                    variant='contained'
                    color='primary'
                    onClick={handleSubmit}
                    style={{ margin: '0 0 0 auto' }}
                >
                    Update Password
                </Button>
            </div>
        </Box>
    )
}
PasswordResetForm.propTypes = {
    // current user
    user: PropTypes.object.isRequired,
    // input values. user data from users collection
    values: PropTypes.shape({
        email: PropTypes.string,
    }).isRequired,
}