/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { compose } from 'redux';
import { geoToH3, h3ToGeo } from 'h3-js';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import Geocoder from 'react-map-gl-geocoder';

// material ui assets
import { IconButton } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

// deck.gl + loaders
import { GLTFLoader } from '@loaders.gl/gltf';
import { registerLoaders } from '@loaders.gl/core';
import { SimpleMeshLayer, ScenegraphLayer } from '@deck.gl/mesh-layers';
import { PlaneGeometry } from '@luma.gl/engine';

// deck.gl helpers
import {
  createH3Layer,
  createHexList,
  createSceneGraphEditor,
  createImageLayer,
  inHexLayer,
  geoToHex,
  createGroupedLayers,
} from '../../utils/layer_helpers';

// other helpers
import { EditTooltip } from './MapV2.helpers';

// styles
import useStyles from './MapV2.styles';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './map.css';
import app from 'firebase/app';
import { DeleteObjectConfirmation, EditObjectModal, InfoModal } from './InfoModals';
import { Button, CircularProgress, Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';

import EditObjectBar from './EditObjectBar';
import { getHexRadius, getModelInfo } from '../../utils/models';
import { getLimitDistance } from '../../utils/hex_traversals';
import { update } from 'lodash';
import { findSliderScale } from "../../utils/scale_helpers";
import { useKeyPress } from "../../hooks/useKeyPress";

// Register the proper loader for scenegraph.gltf
registerLoaders([GLTFLoader]);

// default settings for map
const initialViewState = {
  longitude: -122.41669,
  latitude: 37.7853,
  zoom: 13,
  pitch: 60,
  bearing: 90,
  mapStyle: 'mapbox://styles/mapbox/satellite-v9',
};

const colors = {
  green: [76, 175, 80],
  red: [244, 67, 54],
};
const MapV2 = ({
  axios,
  color = [255, 255, 255, 255],
  setColor,
  edit,
  selectedObject,
  draftObjectDetails,
  setDraftObjectDetails,
  draftObjectUrl,
  objects,
  objectType,
  selectedImage,
  setSelectedImage,
  setSelectedObject,
  master,
  user,
  viewState,
  setViewState,
  label,
  setLabel,
  des,
  setDes,
  maptheme,
  uname,
  displayobj,
  setDisplayobj,
  displayProp,
  setDisplayProp,
  loading,
  setLoading,
  scale,
  setScale,
  userHexes, // all location hexes owned by current user
  removeObject,
  hexRadius,
  setHexRadius,
  contentid,
  setContentid,
  sliderScale,
  setSliderScale,
}) => {
  // state
  let [Rx, Ry, Rz, Sx, Sy, Sz, H] = draftObjectDetails || [0, 0, 0, 0, 0, 0, 0];
  H *= 1; // addresses render bug where height must be a number and not a string

  /**
   * For storing map view settings
   */
  // const [viewport, setViewport] = React.useState(initialViewState);
  const [search, setSearch] = React.useState(null);
  /**
   * Edit mode has two settings:
   * - Place: Lets user hover on map to position object
   * - Edit: Content is fixed in place. User can move cursor freely
   *   to update content properties or do something else
   */
  const [mode, setMode] = React.useState();

  /**
   * Critical to modify/delete mechanism.
   * This state is populated when user clicks on rendered object to modify/edit.
   * Contains click event data as well as detailed object data
   */
  const [objectConfirmation, setObjectConfirmation] = React.useState({});

  /**
   * Used to determine if draft content layer should be visible
   */
  const [hideDraft, setHideDraft] = React.useState(false);

  // Attributes assigned to currently edited object
  const [draftPos, setDraftPos] = React.useState([0, 0]);
  const [draftColor, setDraftColor] = React.useState(null); //[255, 255, 0]

  /**
   * Deck.gl layer state data
   */
  // let [objectLayers, setObjectLayers] = React.useState([]);

  /**
   * Deck.gl hexagon layer data
   * - List represents an array of H3 Hexagons
   * - Layer represents the deck.gl layer created for those hexagons
   */
  const [usedHexes, setUsedHexes] = React.useState([]);
  const [hexLayer, setHexLayer] = React.useState([]);
  // master Unused, remove?????????
  const [masterList, setMasterList] = useState([]);
  const [masterLayer, setMasterLayer] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [doDeleteObject, setDoDeleteObject] = useState(false);
  const [showUpdateOptions, setShowUpdateOptions] = useState(false);

  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [despt, setDespt] = useState('');
  const [position, setPosition] = useState('');
  const [editname, setEditname] = useState(false);
  const [displayEditBar, setDisplayEditBar] = useState(false);
  const [objectInfo, setObjectInfo] = useState(null);
  const [userHexesLayer, setUserHexesLayer] = useState([]);
  const [previousObjectId, setPreviousObjectId] = useState(null);
  const updatingObject = useRef(false);

  const escape = useKeyPress('Escape');

  const classes = useStyles(edit);

  /**
   * Required items to get map working
   * - mapRef and geocoderContainerRef used to create the search bar (aka Geocoder)
   * - Mapbox API Token
   * - handleViewportChange used to adjust view on map pan, zoom, scroll
   * - onSearchResult used to update map after clicking on search bar result
   */
  const mapRef = React.useRef();
  const geocoderContainerRef = React.useRef();
  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

  const handleViewPortChange = (vp) => setViewState({ ...vp, transitionDuration: 0 });

  const onSearchResult = (vp) => {
    const { latitude, longitude } = vp;
    setSearch({ latitude, longitude });
    setViewState({ ...vp, zoom: viewState.zoom, transitionDuration: 0 });
  };
  // const [hideModal, setHideModal] = React.useState(false);
  // const SelectTool = (props) => {
  //   const classes = useStyles();
  //   return (
  //     <div>
  //       <Button
  //         {...props}
  //         aria-controls="customized-menu"
  //         aria-haspopup="true"
  //         variant="contained"
  //         color="primary"
  //         onClick={() => setHideModal(true)}
  //       >
  //         Manage UI
  //       </Button>
  //     </div>
  //   );
  // };

  /**
   * Event Handlers
   */

  /**
   * Lifecycle: User positioned object on map
   * Called when user clicks the green check to save an object.
   */
  const handleSave = async (objectCoords = null) => {
    const toastId = 'save-object';
    try {
      setLoading?.(true);
      toast.dismiss(toastId);

      const { coordinate, object } = objectCoords || objectConfirmation || {};
      /**
       * Prevents objects from being placed in the same location.
       * Location is defined as the resolution 13 hexagon space (??????????????????)
       * associated with a particular coordinate pair. Hexagon spaces
       * come from deck.gl's H3HexagonLayer
       */
      const inHex = inHexLayer(coordinate, usedHexes);
      if (inHex) return;

      toast.info('Saving object...', { toastId });

      /**
       * Constructs JSON object to represent the 3d object to
       * be placed using the current properties set by user
       * ( position, rotation, scale, etc.)
       */
      const isImage = objectType === 'image';
      const entry = {
        ...object,
        location: [...coordinate, H],
        source: isImage ? selectedImage : selectedObject,
        rotation: [Rx, Ry, Rz],
        scale: [Sx, Sy, Sz],
        type: isImage ? 'image' : '3DObject',
        url: draftObjectUrl,
      };
      if (!isImage) entry.color = color || [255, 255, 255];

      /**
       * If object already exists, then this 'save' action will
       * behave as an update to the data already in database. ContentID
       * is a unique object identifier to enable this
       */
      if (object) {
        entry.contentID = object.contentID;
        entry.created_by = user.uid;
      }
      const { location, source } = entry;
      /**
       * Creates or updates object on server via axios API call.
       * On success, retrieves a contentID. Adds toAdd object with contentID
       * to the map and resets the editor.
       */
      //console.log(geoToH3(toAdd.location[1], toAdd.location[0], 6))
      const a = source && geoToHex(location);
      const filterValue = userHexes.filter((hex) => hex === a);
      if (!filterValue.length) {
        setObjectConfirmation({});
        setSelectedObject('');
        toast.dismiss('not-owned');
        toast.error('Please select a location you have already purchased', { toastId });
        return;
      }

      entry.objectDoc = {
        ...entry,
        h3: geoToH3(location[1], location[0], 6),
        uid: user.uid,
        created_by: user.uid,
        ts: new Date(),
        label: label,
        description: des,
        owner: uname,
      };
      await axios.addToMap(entry);
      setObjectConfirmation({});
      setSelectedObject('');
      setSelectedImage('');
      setLabel('');
      setMode('place');
      setSelectedObject('');
      toast.dismiss(toastId);
      toast.dark('Object added successfully!', { toastId });
    } catch (err) {
      toast.dismiss(toastId);
      toast.error(err.message || err, { toastId });
    }
    setDisplayProp(false);
    setDisplayEditBar(false);
    setLoading?.(false);
    setColor([255, 255, 255, 255]);
  };

  /**
   * Lifecycle: User positioned object on map
   * Should delete building locally if not saved.
   * Should delete building from db if saved.
   */
  const handleCancel = async () => {
    // reset controls
    const doDelete = !!objectConfirmation?.object?.contentID;
    doDelete &&
      (await axios
        .removeFromMap(objectConfirmation)
        .catch((e) => toast.error('Unable to delete item. Please try again')));

    doDelete && setMode('place');
    setDisplayProp(false);
    setDisplayEditBar(false);
    setDraftObjectDetails([0, 45, 90, 1, 1, 1, 0, '']);
    setObjectConfirmation({});
    setSelectedObject('');
  };

  /**
   * Lifecycle: User positioned object on map
   *
   * Called when user clicks the blue move arrow.
   * Allows user to move cursor to position object
   */
  const handleMove = async () => {
    setMode('place');

    // set scaling of object to 1 hex
    const modelDimensions = await getModelInfo(updatingObject.current.source);
    if (modelDimensions) {
      const hexDiameter = hexRadius * 2;

      const maxModelWidth = Math.max(modelDimensions.x, modelDimensions.y);
      const scaleFactor = Math.floor((hexDiameter / maxModelWidth) * sliderScale) / sliderScale;

      const newObjectDetails = [...draftObjectDetails];
      newObjectDetails[3] = scaleFactor;
      newObjectDetails[4] = scaleFactor;
      newObjectDetails[5] = scaleFactor;
      setDraftObjectDetails(newObjectDetails);

      setScale({
        ...scale,
        min: scaleFactor,
        max: scaleFactor,
      });
    }
  };

  /**
   * Lifecycle: Before user has positioned object on map
   * Called when user clicks on map to position object.
   */
  const handleMapClick = (e) => {
    if (updatingObject.current && mode === 'place') {
      const owned = inHexLayer(draftPos, userHexes);
      toast.dismiss('not-owned');
      if (!owned)
        return toast.warn('Please select a location you have already purchased', {
          toastId: 'not-owned',
        });
      setObjectLimitScale(e);
      setMode('edit');
      return;
    }

    if (mode === 'edit' || !selectedObject) return;
    if (e.layer !== null && !e.object?.contentID?.includes('draft')) return;

    const { object } = objectConfirmation; // undefined value if new object

    // prevent user from placing object if the location selected is not owned by the user
    // ToDo: improve this to check the entire area of the selected object.
    // Currently it only checks if center hex is purchased by the user.
    const owned = inHexLayer(draftPos, userHexes);
    toast.dismiss('not-owned');
    if (!owned)
      return toast.warn('Please select a location you have already purchased', {
        toastId: 'not-owned',
      });

    setObjectLimitScale(e);

    // returns if user clicks on object instead of map

    // saves temp event data such as location into memory
    setObjectConfirmation({ ...e, object });

    // save automatically if user clicks on map
    setMode('edit');
    handleSave({ ...e, object });
  };

  /**
   * Lifecycle: Before user has positioned object on map
   *
   * Called as user moves mouse over map.
   *
   * Creates a temporary 3D Object at the coordinate where
   * user cursor is hovering.
   *
   * Creates a H3Hexagon on the "ground" where user cursor is hovering
   * since 3D objects are able to float in the air.
   *
   */
  const handleMapHover = (e) => {
    const { coordinate, object: { contentID } = {} } = e;
    if (!coordinate) return;
    if (mode !== 'place') {
      setHexLayer([]);
      return;
    };
    const isInUsed = inHexLayer(coordinate, usedHexes);
    const isAvailable = !isInUsed && inHexLayer(coordinate, userHexes);

    setHexLayer(createH3Layer([geoToHex(e.coordinate)], isAvailable ? colors.green : colors.red));

    setDraftColor(!isAvailable && colors.red);
    setDraftPos(e.coordinate);
    const hide = !!contentID && !contentID?.includes('draft');
    setHideDraft(hide);
  };

  const LeftAlignedBtn = withStyles({
    root: {
      justifyContent: 'flex-start',
    },
  })(Button);

  const GreenButton = withStyles({
    root: {
      color: '#357a38',
    },
  })(LeftAlignedBtn);
  /**
   * When an object is placed, this renders a small toolbar
   * with options to save, move or delete object
   * TODO: Allow movement when updating object, not just placing
   */
  function RenderConfirmationPopup({ isPlacing }) {
    return (
      <div
        className={classes.confirmation}
        style={
          isPlacing
            ? { left: objectConfirmation.x, top: objectConfirmation.y }
            : { left: objectInfo.x, top: objectInfo.y }
        }
      >
        {isPlacing && (
          <GreenButton
            onClick={handleSave}
            size="small"
            color="success"
            startIcon={<CheckBoxIcon />}
          >
            Place Object
          </GreenButton>
        )}
        {/* <LeftAlignedBtn
          onClick={handleMove}
          size="small"
          color="primary"
          startIcon={<OpenWithIcon />}
        >
          Move Object
        </LeftAlignedBtn> */}
        <LeftAlignedBtn
          onClick={handleCancel}
          size="small"
          color="secondary"
          startIcon={<ClearIcon />}
        >
          Cancel
        </LeftAlignedBtn>
      </div>
    );
  }

  function UpdateOptions() {
    return (
      <div
        className={classes.deleteIcon}
        style={{ left: objectInfo?.x + 10, top: objectInfo?.y - 30 }}
      >
        <OpenWithIcon onClick={handleMove} />
        <ClearIcon color="secondary" onClick={removeObj} />
      </div>
    );
  }
  /**
   * Lifecycle: Applies only to content that has already been rendered.
   *
   * This function is passed during creation of the deck.gl layer.
   * Function is called when user hovers over object.
   *
   * Note that there is a condition not in this function ( see utils )
   * such that users can only edit their own content, thus this function
   * will only return true if the user owns the content.
   *
   */
  const handleObjectHover = (object, e) => {
    e.preventDefault();
    const hide = !!object && !object?.contentID?.includes('draft');
    // setHideDraft(hide)
  };

  const setObjectLimitScale = async (info) => {
    let modelDimensions = null;
    modelDimensions = await getModelInfo(info.object.source);
    if (modelDimensions) {
      const hexDiameter = hexRadius * 2;

      const distanceToOutsideBounds = getLimitDistance(info.object.location, userHexes, hexRadius);
      const maxModelWidth = Math.max(modelDimensions.x, modelDimensions.y);

      const minScale = hexDiameter / maxModelWidth;
      const maxScale = 2 * distanceToOutsideBounds / maxModelWidth;

      const localUiScale = findSliderScale(maxScale);
      setSliderScale(localUiScale);

      setScale({
        ...scale,
        min: Math.floor(minScale * localUiScale) / localUiScale,
        max: Math.floor(maxScale * localUiScale) / localUiScale,
      });
    }
  };

  /**
   * Lifecycle: Applies only to content that has already been rendered.
   *
   * This function is passed during creation of the deck.gl layer.
   * Function is called when user clicks over object with intent to
   * modify/delete content.
   *
   * Note that there is a condition not in this function ( see utils )
   * such that users can only edit their own content, thus this function
   * will only return true if the user owns the content.
   *
   * High-level what this is doing:
   * - Click on rendered content returns 'info' data containing all information
   *   about that content and stores. This data gets stored
   * - Removes content from view using contentID
   * - Creates a new draft object on map with matching properties
   *   including contentID
   * - Puts editor into edit mode so that user can move mouse to place object
   */
  const handleObjectClick = (info, e) => {
    // grab contentID
    setObjectInfo(info);

    console.log('info: ', info);
    const { object } = info;
    if (!object) return;
    const { contentID } = object;

    if (contentID.includes('draft')) return;

    if (previousObjectId && contentID !== previousObjectId) {
      cancelEditObjectBar(true);
    };
    setPreviousObjectId(contentID);

    updatingObject.current = objects.find((object) => object.contentID === contentID) ?? false;

    // remove contentID from building and buildingLayers
    // const before = buildings.length;
    // const filteredBuildings = buildings.filter((i) => i.contentID !== contentID);
    // const after = filteredBuildings.length;
    // console.log('Removed ', (before - after), ' building.');
    // setBuildings([...filteredBuildings]);

    setName(object.label);
    setUrl(object.url);
    setContentid(contentID);
    // populate draft and drawer fields with info.object
    setDraftObjectDetails([
      object.rotation[0],
      object.rotation[1],
      object.rotation[2],
      object.scale[0],
      object.scale[1],
      object.scale[2],
      object.location[2],
      object.url,
    ]);
    setColor(object.color);
    setPosition(object.location);
    setDespt(object.description);
    // if (object.type === 'image') {
    //   setSelectedImage(object.source);
    //   setSelectedObject(object.source);
    // } else {
    //   setSelectedObject(object.source);
    //   setSelectedImage(object.source);
    // }
    // setObjectConfirmation(info);
    // setMode('place');
    setDisplayEditBar(true);
    // setConfirmModal(true)
    // // reset editor
    // setHideDraft(false);

    setObjectLimitScale(info);
    setShowUpdateOptions(true);
  };

  /**
   * Called when page loads hexagon data from remote source
   */
  useEffect(() => {
    if (master) {
      const list = Object.keys(master);
      setMasterList(list);
      setMasterLayer([createH3Layer(list, [128, 128, 128])]);
    }
  }, [master]);

  useEffect(() => {
    !selectedObject && setObjectConfirmation({});
    setHideDraft(!selectedObject);
    setMode(selectedObject ? 'place' : 'edit');
  }, [selectedObject]);

  useEffect(() => {
    // create a hexagon layer using all the hexes owned by the user
    setUserHexesLayer(createH3Layer(userHexes, [33, 150, 243]));
  }, [userHexes]);

  useEffect(() => {
    setUsedHexes(objects.map((x) => geoToHex(x.location)));
  }, [objects]);

  const onCancel = () => {
    setDisplayEditBar(false);
    setConfirmModal(false);
    setEditname(false);
  };

  function removeObj() {
    setDeleteConfirmModal(true);
    setDisplayEditBar(false);
    setShowUpdateOptions(false);
  }

  useEffect(() => {
    if (doDeleteObject) {
      console.log('deleting object');
      app
        .firestore()
        .collection('objects')
        .doc(contentid)
        .delete()
        .then((res) => {
          toast.dark('Object removed suscessfully!');
          // setConfirmModal(false)
        })
        .catch((err) => toast.error(err.message));
      setDoDeleteObject(false);
    }
  }, [doDeleteObject]);

  const handleGeocoderViewportChange = useCallback((newViewport) => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };
    // const { latitude, longitude } = newViewport;
    // setSearch({ latitude, longitude });
    return handleViewPortChange({
      ...newViewport,
      ...geocoderDefaultOverrides,
    });
  }, []);

  /**
   * Creates new deck.gl layer to place a 3D object on the map
   */
  const objectLayers = createGroupedLayers(
    [
      (selectedObject || selectedImage) &&
      !hideDraft && {
        color: (mode === 'place' && [255, 255, 255, 100]) || color,
        contentID: `draft-${objectType}-layer`,
        created_by: user?.uid,
        location: [...draftPos, H],
        rotation: [Rx, Ry, Rz],
        scale: [Sx, Sy, Sz],
        source: selectedObject || selectedImage,
        type: objectType,
      },
      ...objects,
    ].filter(Boolean),
    handleObjectHover,
    handleObjectClick,
    user?.uid
  );

  const cancelEditObjectBar = (changingObject = false, previousObjectDetails = null) => {
    setEditname(false);
    setMode("edit");
    if (!changingObject) {
      if (previousObjectDetails?.current) {
        console.log("previous", previousObjectDetails.current);
        console.log("updating", updatingObject.current);
        const [rx, ry, rz, sx, sy, sz, px, py, h] = [...previousObjectDetails.current];
        updatingObject.current.scale = [sx, sy, sz];
        updatingObject.current.rotation = [rx, ry, rz];
        updatingObject.current.location = [px, py, h];
      }
      setDisplayEditBar(false);
    }
    updatingObject.current = false;
    setShowUpdateOptions(false);
  }

  /**
   * Updates the object on real time
   */
  useEffect(() => {
    if (selectedObject) {
      updatingObject.current = false;
      return;
    };
    if (!updatingObject.current) return;

    updatingObject.current.scale[0] = Sx;
    updatingObject.current.scale[1] = Sy;
    updatingObject.current.scale[2] = Sz;

    updatingObject.current.rotation[0] = Rx;
    updatingObject.current.rotation[1] = Ry;
    updatingObject.current.rotation[2] = Rz;

    if (mode === 'place') {
      updatingObject.current.location[0] = draftPos[0];
      updatingObject.current.location[1] = draftPos[1];
      updatingObject.current.color[4] = 100;
    } else {
      updatingObject.current.location[0] = updatingObject.current.location[0];
      updatingObject.current.location[1] = updatingObject.current.location[1];
      updatingObject.current.color[4] = 255;
    }

    updatingObject.current.location[2] = H;

  }, [draftObjectDetails, selectedObject, updatingObject, draftPos]);

  useEffect(() => {
    if (displayProp) {
      removeObj();
    }
  }, [removeObject]);

  useEffect(() => {
    console.log('object state changed', objects);
  }, [objects]);

  useMemo(() => {
    // get one of the user hexes to get the radius
    const hex = userHexes[0];

    const radius = getHexRadius(hex);
    setHexRadius(radius);
  }, [userHexes]);

  useEffect(() => {
    if (escape && selectedObject) {
      handleCancel();
    }
    if (escape && displayEditBar) {
      cancelEditObjectBar();
    }
  }, [escape]);

  useEffect(() => {
    if (!displayEditBar) {
      updatingObject.current = false;
    }
  }, [displayEditBar]);

  return (
    <div
      className={
        !displayobj && displayProp
          ? classes.hideobjroot
          : displayobj && !displayProp
            ? classes.hideproproot
            : !displayobj && !displayProp
              ? classes.hidebothroot
              : classes.root
      }
    >
      {/* {objectConfirmation.x && <RenderConfirmationPopup isPlacing />} */}
      {showUpdateOptions && <UpdateOptions />}
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <ReactMapGL
          ref={mapRef}
          {...viewState}
          maxZoom={20}
          minZoom={13}
          width="100%"
          height="100%"
          touchRotate // used on mobile
          touchZoom // used on mobile
          mapboxApiAccessToken={MAPBOX_TOKEN}
          onViewportChange={handleViewPortChange}
          mapStyle={maptheme || `mapbox://styles/mapbox/streets-v11`}
          mapboxApiAccessToken={
            'pk.eyJ1IjoicnJpY2U5MTkiLCJhIjoiY2pqZ2dnY2kyMHB2djN2bzQ1ZGZvajR0eiJ9.kVpZH3MhHkOa1BEI1v4R8g'
          }
        >
          {search && (
            <Marker latitude={search.latitude} longitude={search.longitude}>
              <div className={classes.marker} />
            </Marker>
          )}
          <div className={classes.navigation}>
            <NavigationControl />
          </div>

          <div className={classes.geocoder}>
            <Geocoder
              mapRef={mapRef}
              trackProximity
              containerRef={geocoderContainerRef}
              onViewportChange={handleGeocoderViewportChange}
              mapboxApiAccessToken={MAPBOX_TOKEN}
              position="top-left"
              marker={false}
            />
          </div>

          {/* <div className={classes.divdropdown}>
              <SelectTool className={classes.select} />
            </div> */}
          <DeckGL
            // style={{ zIndex: 1 }}
            useDevicePixels
            viewState={{ ...viewState }}
            maxZoom={20}
            minZoom={13}
            layers={[userHexesLayer, mode === 'place' && hexLayer, ...masterLayer].filter(Boolean)}
          />
          <DeckGL
            // style={{ zIndex: 2 }}
            useDevicePixels
            viewState={{ ...viewState }}
            maxZoom={20}
            minZoom={13}
            layers={[...objectLayers].filter(Boolean)}
            getTooltip={({ object = {} }) => {
              const { contentID, created_by } = object;
              if (!contentID || user?.uid !== created_by) return;
              // if (!contentID.includes('draft') && selectedObject == '') return 'Edit';
              // if (mode === 'place' && draftColor === colors.green) return 'Click to place object';
            }}
            onClick={handleMapClick}
            onHover={handleMapHover}
          />
        </ReactMapGL>
      )}
      {displayEditBar && (
        <EditObjectBar
          setName={setName}
          url={url}
          setUrl={setUrl}
          despt={despt}
          setDespt={setDespt}
          name={name}
          setEditname={setEditname}
          removeObj={removeObj}
          displayEditBar={displayEditBar}
          setDisplayEditBar={setDisplayEditBar}
          contentid={contentid}
          color={color}
          setColor={setColor}
          draftObjectDetails={draftObjectDetails}
          setDraftObjectDetails={setDraftObjectDetails}
          position={position}
          scale={scale}
          hexRadius={hexRadius}
          objectInfo={objectInfo}
          setShowUpdateOptions={setShowUpdateOptions}
          updatingObject={updatingObject}
          setContentid={setContentid}
          sliderScale={sliderScale}
          cancel={cancelEditObjectBar}
        />
      )}
      <DeleteObjectConfirmation
        open={deleteConfirmModal}
        setOpen={setDeleteConfirmModal}
        setValue={setDoDeleteObject}
      />
      <div className={classes.toggleObjectIcon} onClick={() => setDisplayobj(!displayobj)}>
        {displayobj ? (
          <ArrowBackIosIcon onClick={() => setDisplayobj(false)} />
        ) : (
          <ArrowForwardIosIcon onClick={() => setDisplayobj(true)} />
        )}
      </div>
    </div>
  );
};

export default React.memo(MapV2);
