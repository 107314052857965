import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Grid, Slider as Slide, Typography } from '@material-ui/core';

const styles = {
  type: {
    fontSize: 18,
    fontWeight: 700,
  },
};

export const StyledSlider = withStyles(() => ({
  rail: {
    color: '#3f51b5',
    height: 2,
  },
  track: {
    height: 2,
  },
}))(Slide);

const Slider = ({ label, min = 0, max = 100, value, handleChange, step = 1, track }) => (
  <Grid container spacing={2}>
    <Grid item>
      <Typography style={styles.type}>{label}</Typography>
    </Grid>
    <Grid item xs>
      <StyledSlider
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        track={track}
        aria-labelledby={`${label}-slider`}
      />
    </Grid>
  </Grid>
);

export default Slider;
