import React from 'react'
import { Link, Typography } from '@material-ui/core';

export default function Copyright(props) {
    return (
        <Typography
            align='center'
            color='text.secondary'
            style={{
                marginTop: 50,
            }}
            variant='body2'
            {...props}
        >
            {'Copyright © '}
            <Link
                color='inherit'
                href='https://transmira.com/'
                underline='always'
            >
                Transmira
            </Link>
            {` ${new Date().getFullYear()}.`}
        </Typography>
    )
}