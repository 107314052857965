/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactMapGL, { Marker, NavigationControl } from "react-map-gl";
import DeckGL from "@deck.gl/react";
import Geocoder from "react-map-gl-geocoder";

import { GLTFLoader } from "@loaders.gl/gltf";
import { registerLoaders } from "@loaders.gl/core";

import { makeStyles } from '@material-ui/styles'
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import './map.css'

import { ScenegraphLayer } from "@deck.gl/mesh-layers";

// Register the proper loader for scenegraph.gltf
registerLoaders([GLTFLoader]);

const initialViewState = {
  longitude: -122.41669,
  latitude: 37.7853,
  zoom: 13,
  pitch: 60,
  bearing: 90,
  mapStyle: "mapbox://styles/mapbox/streets-v11"

};

const useStyles = makeStyles((edit) => ({
  root: {
    height: "100vh",
    position: "relative",
    margin: edit => edit == true ? '0px 250px' : 0,
  },
  marker: {
    height: 25,
    width: 25,
    borderRadius: 180,
    background: 'red',
  },
  navigation: { zIndex: 10, position: "absolute", right: 0, margin: 10 },
  geocoder: { zIndex: 10, display: "none" },
  toolTip: {
    position: 'absolute',
    zIndex: 10,
    background: 'white',
    color: 'black',
    pointerEvents: 'none',
    fontWeight: 800,
    fontSize: 14,
    border: '1px solid black',
    borderRadius: 25,
    padding: '0px 8px'
  }
}));

const MapVirtualGoods = ({ goods }) => {
  // Map Utils
  const mapRef = React.useRef();
  const geocoderContainerRef = React.useRef();
  const MAPBOX_TOKEN = "pk.eyJ1IjoidHVyY2siLCJhIjoiY2syZ20yYTZsMGVleDNpczE0eWRkN2tnaSJ9.TL4UOnh7QU1teqEzRJPdag";

  const [viewport, setViewport] = React.useState(initialViewState);
  const [search, setSearch] = React.useState(null);
  const [info, setInfo] = useState({})
  const [goodsLayer, setGoodsLayer] = useState([])

  // styles
  const classes = useStyles();


  const handleViewPortChange = (vp) => setViewport({ ...viewport, ...vp, transitionDuration: 0 });

  const onSearchResult = (vp) => {
    const { latitude, longitude } = vp;
    setSearch({ latitude, longitude });
    setViewport({ ...viewport, ...vp, transitionDuration: 0 });
  };
  /**
   * Tooltip rendered when hovering over object
   */
  function renderTooltip() {
    return info && (
      <div
        className={classes.toolTip}
        style={{
          left: info.x,
          top: info.y
        }}>
        {info.type}
      </div>
    );
  }

  /**
   * Creates deck.gl layer to render virtual goods
   * @param {array} d 
   */
  const createSceneGraph = (d) => new ScenegraphLayer({
    id: `goods-layer-${Math.random()}`,
    data: d,
    sizeScale: 3,
    _lighting: "pbr",
    scenegraph: `./models_sized/omnicoin.glb`,
    pickable: true,
    getPosition: (d) => [d.location.longitude, d.location.latitude],
    getOrientation: (d) => [0, 45, 90],
    onHover: (info) => info.object ? onHover(info) : onHover(null),
  });

  /**
   * Called on hover of virtual goods to supply tool tip data
   * @param {object} info 
   */
  const onHover = (info) => {
    if (!info) return setInfo(null)
    return setInfo({
      type: info.object.icon,
      x: info.x,
      y: info.y
    })
  }

  useEffect(() => {
    if (goods) {
      setGoodsLayer(createSceneGraph(goods))
    }
  }, [goods])

  return (
    <div className={classes.root}>
      {renderTooltip()}
      <ReactMapGL
        ref={mapRef}
        {...viewport}
        width="100%"
        height="100%"
        touchRotate
        touchZoom
        mapboxApiAccessToken={MAPBOX_TOKEN}
        onViewportChange={handleViewPortChange}
      >
        {search &&
          <Marker latitude={search.latitude} longitude={search.longitude}>
            <div className={classes.marker} />
          </Marker>
        }
        <div className={classes.navigation}>
          <NavigationControl />
        </div>
        <div className={classes.geocoder}>
          <Geocoder
            mapRef={mapRef}
            trackProximity
            containerRef={geocoderContainerRef}
            onViewportChange={onSearchResult}
            mapboxApiAccessToken={MAPBOX_TOKEN}
            position="top-left"
          />
        </div>
        <DeckGL
          style={{ zIndex: 1 }}
          useDevicePixels={true}
          viewState={{ ...viewport }}
          layers={[goodsLayer]}
        />
      </ReactMapGL>
    </div>
  );
};

export default MapVirtualGoods;
