import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 25,
    width: 550,
  },
  rootMobile: {
    width: '100%',
  },
  bsvContainer: {
    // fix for unwanted scrollbar due to fixed MoneyButton swipe button size!!
    maxWidth: '100%',
    overflowX: 'hidden',
  },
  label: {
    fontSize: 19,
    fontWeight: 700,
    marginBottom: 5,
    marginTop: 12,
  },
  stripeContainer: {
    minWidth: 265,
  },
  title: {
    fontSize: 19,
    fontWeight: 700,
    marginBottom: 12,
    overflow: 'hidden',
  },
  email: {
    borderRadius: 18,
  },
  submit: {
    marginTop: 8,
    borderRadius: 18,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  successButtons: {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 700,
    border: '1px solid #c8c8c8',
    borderRadius: '18px',
    width: '40%'
  },
  lineItemButtonMobileBSV: {
    minWidth: 130,
  },
}));

export default useStyles;