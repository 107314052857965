import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { BehaviorSubject } from 'rxjs'
import Message, { STATUS } from '../common/Message'
import { Box, Button, Link } from '@material-ui/core'
import { usernameApi } from '../api/axios'
import FirestoreHelper from '../common/FirestoreHelper'
import FormInput from '../common/form/FormInput'
import { fillInputs, findInput, getValues, validateInput } from '../common/form/InputCriteriaHint'
import { rxAuthUser } from '../common/useAuthUser'
import useRxSubject from '../common/useRxSubject'
import { deferred, deferredPromise, isFn, isSubjectLike } from '../common/utils'
import modalService from '../common/modal/modalService'
import FormBuilder from '../common/form/FormBuilder'

const themes = {
    Light: 'mapbox://styles/mapbox/light-v10',
    Dark: 'mapbox://styles/mapbox/dark-v10',
    Outdoors: 'mapbox://styles/mapbox/outdoors-v11',
    Satellite: 'mapbox://styles/mapbox/satellite-v9',
    Omniscape: 'mapbox://styles/rrice919/ck457zmrs0mw51cqwngq3qf43',
    Streets: 'mapbox://styles/mapbox/streets-v11',
}
const userNameCriterias = [
    {
        regex: new RegExp(/^.{3,16}$/),
        text: 'Between 3-16 characters',
        valid: false,
    },
    {
        text: 'Starts with a letter',
        regex: new RegExp(/^[a-z]+/i),
    },
    {
        text: 'Ends with a letter or number',
        regex: new RegExp(/^.*([a-z0-9])$/i),
    },
    {
        text: 'Only contains letters, numbers, underscores and dashes',
        regex: new RegExp(/^[a-z]+([\_\-a-z0-9])*$/i), // new RegExp(/^[a-z]+([_-]?[a-z0-9])*$/i),
    },
]
export const inputNames = {
    email: 'email',
    maptheme: 'maptheme',
    username: 'username',
    wallet_moneybutton: 'wallet_moneybutton',
}

export default function AccountSettingsForm(props) {
    let {
        closeOnSubmit,
        modalId,
        onChange,
        onSubmit,
        // this allows the access of rxInputs externally, however, all inputs are set internally
        // if any input is provided externally, it will be overriden
        // rxInputs: rxInputsOriginal,
        submitButton,
        values: userData = {},
    } = props
    const [message, setMessage] = useState(null)
    const usernameRef = useRef(null)
    const [rxInputs] = useState(getRxInputs(props, usernameRef))
    // Use the value of rxInputs and auto update state whenever rxInputs change is triggered
    const [inputs] = useRxSubject(rxInputs, x => x || [])

    // fill values in inputs whenever userData changes
    useEffect(deferred(() => {
        const _inputs = fillInputs(inputs, userData)
        // (re-)validate inputs
        _inputs.forEach(x => validateInput(x))
        rxInputs.next([..._inputs])
    }, 50), [userData])

    // const handleChange = name => e => {
    //     const { value } = e.target
    //     const input = findInput(name, inputs)
    //     const { onChange: inputOnChange } = input
    //     input.value = value
    //     validateInput(input)

    //     isFn(inputOnChange) && inputOnChange(value, inputs)
    //     rxInputs.next([...inputs])
    //     // clear message
    //     message && setMessage(null)
    //     if (!isFn(onChange)) return

    //     const allValid = inputs.every(x => x.valid)
    //     const values = getValues(inputs)
    //     onChange(allValid, values, inputs, name)
    // }
    const handleSubmit = async (...args) => {
        console.log('handleSubmit', { args })
        const [allOk, values, inputs] = args
        // all inputs not valid or something is loading or submit button is disabled
        if (!allOk) return

        try {
            // clear username field message
            findInput(inputNames.username, inputs).message = null
            rxInputs.next([...inputs])
            setMessage({
                status: STATUS.loading,
                text: 'Saving...'
            })
            // const values = inputs.reduce((values, { name, value }) => {
            //     values[name] = value
            //     return values
            // }, {})

            // update username if changed
            const { username } = values
            if (username && userData.username !== username) {
                await usernameApi.update(
                    username,
                    userData.uid,
                )
            }

            const otherValues = { ...values }
            delete otherValues.username // firestore rule should prevent changing username
            await new FirestoreHelper('users', 'uid')
                .save(otherValues, userData.uid, true)
            setMessage({
                status: STATUS.success,
                text: 'Saved!',
            })
            // auto hide message after 2 seconds
            setTimeout(() => {
                setMessage(null)

                if (closeOnSubmit && modalId) modalService.delete(modalId)
            }, 2000)
            isFn(onSubmit) && onSubmit(...args)
        } catch (err) {
            console.log('submitError', err)
            setMessage({
                status: STATUS.error,
                text: err.message,
            })
        }
    }


    return (
        <FormBuilder {...{
            ...props,
            formProps: {
                style: {
                    boxShadow: 'none',
                },
                ...props.formProps,
            },
            rxInputs,
            message,
            onSubmit: handleSubmit,
            submitButton,
        }} />
    )
}
AccountSettingsForm.defaultProps = {
    submitButton: 'Save',
}
AccountSettingsForm.propTypes = {
    // (optional) if on a modal, close after successful submission of the form
    closeOnSubmit: PropTypes.bool,
    // (optional) props to be supplied to the form/root element.
    formProps: PropTypes.object,
    // (optional) list of input names to hide
    hiddenInputs: PropTypes.array,
    // (optional) If opened on a modal, modal ID should be passed in
    modalId: PropTypes.string,
    // (optional) callback triggered whenever any input's value is changed
    // Arguments:
    //      @valid  bool:   whether all input fields are valid
    //      @values object: values of all input fields
    //      @inputs array:  all inputs fields
    //      @name   string: name of the input that triggered the change
    onChange: PropTypes.func,
    // (optional) callback triggered whenever submit button is clicked
    onSubmit: PropTypes.func,
    rxInputs: PropTypes.shape({
        next: PropTypes.func.isRequired,
    }),
    style: PropTypes.object,
    // Submit button text. If falsy will hide the submit button.
    // Default: 'Save'
    submitButton: PropTypes.any,
    // input values. Current user data from users collection
    values: PropTypes.shape({
        email: PropTypes.string,
        maptheme: PropTypes.string,
        uid: PropTypes.string,
        username: PropTypes.string,
    }).isRequired,
}

const getRxInputs = (props, usernameRef) => () => {
    let {
        modalId,
        // this allows the access of rxInputs externally, however, all inputs are set internally
        // if any input is provided externally, it will be overriden
        rxInputs,
        values: userData = {},
    } = props
    rxInputs = isSubjectLike(rxInputs)
        ? rxInputs
        : new BehaviorSubject()
    // if invoked multiples times will only resolve with the last result
    const deferredChecker = deferredPromise()
    const handleCheckUsername = deferred(async (_, inputs) => {
        const input = findInput(inputNames.username, inputs)
        const { valid, value: username } = input
        const isUnchanged = username === userData.username
        input.message = null
        const skipCheck = !username || !valid || isUnchanged
        // skip checking username availability if not valid, empty username or unchanged
        if (skipCheck) return rxInputs.next([...rxInputs.value])

        const doCheckUsername = async () => {
            input.error = true
            input.loading = true
            input.message = {
                status: STATUS.loading,
                text: 'Checking username...',
            }
            rxInputs.next([...rxInputs.value])

            const { user: { uid } = {} } = rxAuthUser.value
            try {
                const available = await usernameApi
                    .checkAvailability(username, uid || '')
                return available
            } catch (err) {
                input.message = {
                    status: STATUS.error,
                    text: err.message,
                }
                return false
            }
        }
        const handleResult = available => {
            input.loading = false
            input.error = !available
            let text = `Username ${available ? '' : 'not'} available!`
            // if username is not available generate random usernames by taking the letters entered username
            if (!available) {
                // exclude all digits from currently entered username
                const usernameBase = [...(username.matchAll(/[a-z_-]+/ig))]
                    .map(x => x[0])
                    .join('')
                // generate random usernames using current one as a suggestion
                const suggestions = new Array(3)
                    .fill(0)
                    .map(() => {
                        const randomInt = parseInt(Math.random() * 1000) + 1
                        const randomName = `${usernameBase}${randomInt}`
                        return randomName
                    })
                const handleSetSuggestion = username => e => {
                    e.preventDefault()
                    e.stopPropagation()
                    input.value = username
                    rxInputs.next([...rxInputs.value])
                    handleCheckUsername(username, inputs)

                    // re-focus input
                    usernameRef.current.focus()
                }
                text = (
                    <div>
                        {text} Try one of the following:<br />
                        {suggestions.map((username, i) => (
                            <>
                                {i !== 0 && ' | '}
                                <Link {...{
                                    onClick: handleSetSuggestion(username),
                                    style: { cursor: 'pointer' },
                                    underline: 'always',
                                }}>
                                    {username}
                                </Link>
                            </>
                        ))}
                    </div>
                )
            }
            input.message = {
                hideOnBlur: input.validation.hideOnBlur,
                text,
                status: available
                    ? STATUS.success
                    : STATUS.error,
            }
            rxInputs.next([...rxInputs.value])
        }
        const promise = doCheckUsername()
            .catch(err => console.warn(err))
        deferredChecker(promise)
            .then(handleResult)
    }, 300)
    const inputs = [
        {
            fullWidth: true,
            label: 'Username',
            name: inputNames.username,
            placeholder: 'Enter an username',
            required: true,
            onChange: (...args) => {
                const [_, inputs] = args
                // immediately mark the field as invalid and delay 300 ms before validating username
                const input = findInput(inputNames.username, inputs)
                // manually set the input field as invalid while username is being checked using backend endpoint
                input.error = true
                handleCheckUsername(...args)
                return true
            },
            inputRef: usernameRef,
            type: 'text',
            validation: {
                criterias: [...userNameCriterias],
                hideOnValid: false,
                hideOnBlur: !modalId, // if opened on modal always show the error message
                title: 'Please enter an username matching the following criteria:'
            },
            value: '',
        },
        {
            disabled: true,
            fullWidth: true,
            label: 'E-mail',
            name: inputNames.email,
            readOnly: true,
            type: 'email',
            value: '',
        },
        {
            fullWidth: true,
            label: 'MoneyButton Paymail',
            name: inputNames.wallet_moneybutton,
            placeholder: 'Enter your paymail',
            required: false,
            type: 'text',
            validation: {
                criterias: [{
                    text: 'Please enter a valid @moneybutton.com paymail',
                    validate: value => value.length >= 18 && value.endsWith('@moneybutton.com'),
                }],
                hideOnValid: true,
                hideOnBlur: true,
            },
            value: '',
        },
        {
            fullWidth: true,
            label: 'Theme',
            multiple: false,
            name: inputNames.maptheme,
            options: Object
                .keys(themes)
                .map(text => ({
                    text,
                    value: themes[text],
                })),
            placeholder: 'Select a theme',
            required: true,
            type: 'select',
            value: themes.Light,
        },
    ]

    rxInputs.next(inputs)
    return rxInputs
}