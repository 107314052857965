import { useEffect, useState } from 'react'
import { BehaviorSubject, Subject } from 'rxjs'
import { isFn } from './utils'

/**
 * @name    useRxSubject
 * @summary simple React hook to use RxJS subject's value and automatically unsubscribe when component is unmounted.
 * 
 * @param   {BehaviorSubject|Subject|*} rxSubject
 * @param   {Function}  valueModifier (optional) callback to alter the result without changing the subject's value.
 * 
 * @returns {Array} [value, setValue]
 */
export default function useRxSubject(rxSubject, valueModifier) {
    const getValue = value => isFn(valueModifier)
        ? valueModifier(value)
        : value
    const [value, setValue] = useState(
        getValue(rxSubject?.value)
    )

    useEffect(() => {
        let mounted = true
        let sub
        try {
            sub = rxSubject.subscribe(newValue =>
                mounted // prevents state update when component is unmounted
                && setValue(getValue(newValue))
                // && value !== newValue // prevents state update if value is not changed
            )
        } catch (err) {
            console.error('Failed to subscribe to subejct', { err, rxSubject })
        }
        return () => {
            mounted = false
            sub && sub.unsubscribe()
        }
    }, [])

    return [value, setValue]
}