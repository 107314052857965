import React, { useEffect } from 'react';
import { compose } from 'redux';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Switch,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Typography,
  TableContainer,
  FormControlLabel,
  IconButton,
} from '@material-ui/core';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';

import ColorPicker from 'material-ui-color-picker';
import isUrl from 'is-url';
import { withFirebase } from '../Firebase';
import Slider from '../Slider';
import useStyles from './RightDrawer.styles';
import './index.css';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { scaleDefault } from '../Drawer/objectFiles';

const convert = require('color-convert');

const useHelperTextStyles = makeStyles(() => ({
  root: {
    fontStyle: 'italic',
    marginBottom: 5
  }
}));

const RightDrawer = (props) => {
  const {
    scale: { max = scaleDefault.max, min = 1 / sliderScale, start = scaleDefault.start },
    draftObjectDetails,
    setDraftObjectDetails,
    color,
    setColor,
    draftObjectUrl,
    setDraftObjectUrl,
    label,
    setLabel,
    des,
    setDes,
    displayProp,
    setDisplayProp,
    sliderScale,
    objectUpdate,
    removeObject,
    setRemoveObject,
  } = props;
  const step = 1 / sliderScale;

  const helperTextStyles = useHelperTextStyles();
  const [localColor, setLocalColor] = React.useState('#fff');
  const [validUrl, setValidUrl] = React.useState(0);
  const [linkScales, setLinkScales] = React.useState(true);

  const handleSlide = (scaleNum, index) => {
    const newState = [...draftObjectDetails];
    newState[index] = scaleNum;
    setDraftObjectDetails(newState);
  };

  const handleAttach = (scaleNum) => {
    if (scaleNum <= max) {
      const newState = [...draftObjectDetails];
      newState[3] = scaleNum;
      newState[4] = scaleNum;
      newState[5] = scaleNum;
      setDraftObjectDetails(newState);
    }
  };

  const handleColor = (c) => {
    // return if undefined
    if (!c) return;
    setLocalColor(c);
    // if hex
    if (c[0] === '#') return setColor([...convert.hex.rgb(c), 255]);
    // if rgba
    const returnColor = c
      .slice(5, -1)
      .split(', ')
      .map((i) => i * 1);
    returnColor[3] = Math.floor(returnColor[3] * 255);
    return setColor(returnColor);
  };

  const handleUrl = (e) => {
    const urlString = e.target.value;
    setDraftObjectUrl(urlString);
    if (urlString === '') return setValidUrl(0);
    if (isUrl(urlString)) return setValidUrl(1);
    return setValidUrl(-1);
  };
  const handleLabel = (e) => setLabel(e.target.value);
  const handleDes = (e) => setDes(e.target.value);

  useEffect(() => {
    if (color.filter((x) => x !== 255).length === 0) {
      setLocalColor('#fff');
    }
  }, [color]);

  const classes = useStyles();

  return (
    <div className={displayProp ? classes.root : classes.hideproproot}>
      <div className={classes.container}>
        <Typography className={classes.title}>Properties</Typography>
        <Typography className={classes.inputLabel}>Position</Typography>
        <Slider
          label="Height"
          min={0}
          max={100}
          value={draftObjectDetails[6]}
          handleChange={(e, v) => handleSlide(v, 6)}
          valueLabelDisplay="auto"
          getAriaValueText={draftObjectDetails[6]}
        />

        <Typography className={classes.inputLabel}>Rotation</Typography>

        <Slider
          label="axis"
          min={-180}
          max={180}
          step={5}
          value={draftObjectDetails[1]}
          handleChange={(e, v) => handleSlide(v, 1)}
          valueLabelDisplay="auto"
          getAriaValueText={draftObjectDetails[1]}
          track={false}
        />
        <Typography className={classes.inputLabel}>
          Scale
        </Typography>
        <div>
          <IconButton onClick={() => setLinkScales(!linkScales)}>
            {linkScales ? <LinkIcon /> : <LinkOffIcon />}
          </IconButton> Link Axes: {linkScales ? 'On' : 'Off'}
        </div>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" align="left" className={classes.tdslide}>
                  <Slider
                    label="x-axis"
                    min={min}
                    max={max}
                    step={step}
                    value={draftObjectDetails[3]}
                    handleChange={(e, v) => {
                      linkScales ? handleAttach(v) : handleSlide(v, 3);
                    }}
                    valueLabelDisplay="auto"
                    getAriaValueText={draftObjectDetails[3]}
                  />
                </TableCell>
                <TableCell align="left" className={classes.tdfiled}>
                  <TextField
                    type='number'
                    min={min}
                    max={max}
                    step={step}
                    value={draftObjectDetails[3] * sliderScale}
                    onChange={(e) => {
                      linkScales
                        ? handleAttach(e.target.value / sliderScale)
                        : handleSlide(e.target.value / sliderScale, 3);
                    }}
                    variant="outlined"
                    inputProps={{
                      style: {
                        padding: `12.5px 0px`,
                        textAlign: 'center',
                        fontSize: 'small'
                      }
                    }}
                  />

                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="left" className={classes.tdslide}>
                  <Slider
                    label="y-axis"
                    min={min}
                    max={linkScales ? max : Math.max(max, 1)}
                    step={step}
                    value={draftObjectDetails[4]}
                    handleChange={(e, v) => {
                      linkScales ? handleAttach(v) : handleSlide(v, 4);
                    }}
                    valueLabelDisplay="auto"
                    getAriaValueText={draftObjectDetails[4]}
                  />
                </TableCell>
                <TableCell align="left" className={classes.tdfiled}>
                  <TextField
                    type='number'
                    min={min}
                    max={linkScales ? max : Math.max(max, 1)}
                    step={step}
                    value={draftObjectDetails[4] * sliderScale}
                    onChange={(e) => {
                      linkScales
                        ? handleAttach(e.target.value / sliderScale)
                        : handleSlide(e.target.value / sliderScale, 4);
                    }}
                    variant="outlined"
                    inputProps={{
                      style: {
                        padding: `12.5px 0px`,
                        textAlign: 'center',
                        fontSize: 'small'
                      }
                    }}
                  />

                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="left" className={classes.tdslide}>
                  <Slider
                    label="z-axis"
                    min={min}
                    max={max}
                    step={step}
                    value={draftObjectDetails[5]}
                    handleChange={(e, v) => {
                      linkScales ? handleAttach(v) : handleSlide(v, 5);
                    }}
                    valueLabelDisplay="auto"
                    getAriaValueText={draftObjectDetails[5]}
                  />
                </TableCell>
                <TableCell align="left" className={classes.tdfiled}>
                  <TextField
                    type='number'
                    min={min}
                    max={max}
                    step={step}
                    value={draftObjectDetails[5] * sliderScale}
                    onChange={(e) => {
                      linkScales
                        ? handleAttach(e.target.value / sliderScale)
                        : handleSlide(e.target.value / sliderScale, 5);
                    }}
                    variant="outlined"
                    inputProps={{
                      style: {
                        padding: `12.5px 0px`,
                        textAlign: 'center',
                        fontSize: 'small'
                      }
                    }}
                  />

                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography className={classes.inputLabel}>Label</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={label}
          onChange={handleLabel}
          helperText='optional'
          FormHelperTextProps={{
            classes: {
              root: helperTextStyles.root
            }
          }}
        />
        <Typography className={classes.inputLabel}>Description</Typography>
        <TextField
          variant="outlined"
          size="small"
          value={des}
          onChange={handleDes}
          inputProps={{ maxLength: 120 }}
        />

        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.colortd} >
                <Typography className={classes.inputLabel}>Color</Typography>
                <ColorPicker
                  className={classes.colorPicker}
                  name="color"
                  value={localColor}
                  TextFieldProps={{
                    variant: 'outlined',
                    style: { background: localColor, color: localColor, padding: 4 },
                    className: classes.input,
                  }}
                  InputProps={{ classes: { input: classes.input } }}
                  onChange={handleColor}
                  helperText='optional'
                  FormHelperTextProps={{
                    classes: {
                      root: helperTextStyles.root
                    }
                  }}
                />
              </TableCell>
              <TableCell className={classes.resettd}>
                <Button size="small" variant="contained" color="primary"
                  onClick={() => { setColor([255, 255, 255, 255]); setLocalColor('#fff') }}>Reset</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <div className={classes.divmrgn} />
        <Typography className={classes.inputLabel}>Add a link</Typography>
        <Typography className={classes.helperText}>
          Other visitors will be able to travel to this link when clicking on your object. This link
          can be a web-based VR world or a website.
        </Typography>

        <TextField
          placeholder="https://example.com"
          variant="outlined"
          size="small"
          className={validUrl === 0 ? '' : validUrl === 1 ? classes.validUrl : classes.invalidUrl}
          value={draftObjectUrl}
          onChange={handleUrl}
          helperText='optional'
          FormHelperTextProps={{
            classes: {
              root: helperTextStyles.root
            }
          }}
        />

        <Button fullWidth variant="outlined" color="primary" onClick={() => setDisplayProp(false)} style={{ margin: "0.3rem 0 1rem 0" }}>Close</Button>
      </div>
    </div >
  );
};

export default withFirebase(RightDrawer);
