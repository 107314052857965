/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Button, Typography, TextField } from '@material-ui/core';
import useStyles from './MapLocations.styles';

const modalText = {
  what: {
    question: 'What can I do with locations?',
    answer:
      'Owning a location allows you to control 3D content and makes you eligible to receive revenue share from paid content placed at your locations.',
  },
  how: {
    question: 'How do I buy locations?',
    answer:
      'By default, you can select individual locations by clicking on the map. To select multiple at a time, change the select tool to Select Locations by Region',
  },
};

export const ModalStructure = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.modalWrapper}>
      <div className={classes.modalContainer}>
        {children}
      </div>
    </div>
  );
};

export const InfoModal = ({ info, onCancel }) => {
  const classes = useStyles();
  return (
    <ModalStructure>
      <Typography align="left" className={classes.title}>
        {modalText[info].question}
      </Typography>
      <Typography align="left" className={classes.modalText} variant="body1">
        {modalText[info].answer}
      </Typography>
      <div className={classes.modalButtonRowRight}>
        <Button className={classes.modalButton} onClick={onCancel}>
          Close
        </Button>
      </div>
    </ModalStructure>
  );
};

export const ConfirmOrderModal = ({ loc, onClick, onCancel, name, setName }) => {
  const classes = useStyles();
  return (
    <ModalStructure>
      <div>
        <Typography>Name</Typography>
        <TextField
          className={classes.field}
          value={name}
          onChange={e => setName(e.target.value)}
          type="text"
          variant="outlined"
        />
      </div>
      <div className={classes.modalButtonRowCenter}>
        <Button className={classes.cancelBtn} onClick={onCancel}>
          Close
        </Button>
        <Button className={classes.confirmBtn} onClick={onClick}>
          Done
        </Button>

      </div>
    </ModalStructure>
  );
};
