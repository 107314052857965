import React, { useState, useEffect, Suspense } from 'react';
import { geoToH3 } from 'h3-js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, Zoom } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Loading from '../Loading';
import MapLocations from '../MapLocations2'
import LocationsTool from '../LocationsTool2'

// const MapLocations = React.lazy(() => import('../MapLocations2'));
// const LocationsTool = React.lazy(() => import('../LocationsTool2'));

function Locations({ match, axios, firebase, setViewState, viewState, user }) {
  let hex = match.params.hex;
  // destructure latitude and longitude and rename as lat and long
  const { latitude: lat, longitude: long } = viewState;
  const [name, setName] = React.useState('');
  const [loc, setLoc] = React.useState(null);
  const [token, setToken] = useState('');
  const [visitedHexes, setVisitedHexes] = React.useState([]);
  const [cartList, setCartList] = useState([]);
  const [masterList, setMasterList] = React.useState([]);
  const [totalCredits, setTotalCredits] = useState(0);
  const [maptheme, setMaptheme] = React.useState('');
  //clickedLocation
  const [search, setSearch] = useState(null);

  //clickedLocationResult
  const [searchResult, setSearchResult] = useState(null);

  // modal
  const [success, setSuccess] = useState(false);

  // info modal visible state
  const [infoModal, setInfoModal] = useState(false);
  const handleModal = (val = false) => setInfoModal(val);

  // confirmation modal state
  const [confirmModal, setConfirmModal] = useState(null);
  const [locations, setLocations] = useState([]);

  const [cartLayer, setCartLayer] = useState([]);

  const [geocoderInput, setgeocoderInput] = useState('');
  async function getLocations() {
    const hex = geoToH3(lat, long, 6);
    setVisitedHexes([...visitedHexes, hex]);
    return axios
      .getLocations({ lat, long })
      .then((res) => setMasterList(res.data))
      .catch((err) => console.log(err));
  }

  /**
   * Calculate hex on map viewState change
   */
  useEffect(() => {
    const hex = geoToH3(lat, long, 6);
    setViewState({ hex });
  }, [viewState.latitude]);

  /**
   * Fetches h3 hex locations from server:
   * - on initial load
   * - if user moves to a new unvisited hex
   */
  useEffect(() => {
    if (user && user.token && !visitedHexes.includes(viewState.hex)) {
      getLocations();
    }
  }, [user, viewState.hex]);

  /**
   * Triggers data reload on successful location purchase
   *
   * Fetches h3 hex on viewState.refresh key == true
   * This change is triggered by completed location orders
   */
  useEffect(() => {
    if (viewState.refresh) {
      getLocations();
      setViewState({ refresh: false });
    }
  }, [viewState.refresh]);

  useEffect(() => {
    const locations = [];
    const user = firebase.auth.currentUser;
    if (user) {
      firebase.firestore
        .collection('users')
        .doc(user.uid)
        .onSnapshot((userData) => {
          setTotalCredits(userData.data().credits);
          setMaptheme(userData.data().maptheme);
          user.getIdToken(true).then((id) => setToken(id));
        });

      firebase.firestore
        .collection('vre_orders')
        .where('customer', '==', `${user.uid}`)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            locations.push(doc.data());
          });
        })
        .catch(function (error) {
          // console.log("Error getting documents: ", error);
        });

      setLocations(locations);
    }
  }, [firebase.auth.currentUser]);

  return (
    <Suspense fallback={<Loading />}>
      <MapLocations
        master={masterList}
        totalCredits={totalCredits}
        cartList={cartList}
        setCartList={setCartList}
        success={success}
        setSuccess={setSuccess}
        infoModal={infoModal}
        setInfoModal={handleModal}
        confirmModal={confirmModal}
        token={token}
        setConfirmModal={setConfirmModal}
        setCartLayer={setCartLayer}
        cartLayer={cartLayer}
        name={name}
        setName={setName}
        setLoc={setLoc}
        loc={loc}
        search={search}
        setSearch={setSearch}
        maptheme={maptheme}
        setSearchResult={setSearchResult}
        geocoderInput={geocoderInput}
      />

      <LocationsTool
        style={{ position: 'absolute', zIndex: 10 }}
        setCartList={setCartList}
        token={token}
        setCartLayer={setCartLayer}
        setSearch={setSearch}
        setViewState={setViewState}
        viewState={viewState}
        hex={hex}
        searchResult={searchResult}
        setSearchResult={setSearchResult}
        setgeocoderInput={setgeocoderInput}
      />

      {/* <ToastContainer
        position="bottom-left"
        autoClose={3000}
        transition={Zoom}
        hideProgressBar
        closeOnClick
        newestOnTop
      /> */}
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        transition={Zoom}
        hideProgressBar
        closeOnClick
        newestOnTop
      />
    </Suspense>
  );
}

export default withRouter(Locations);
