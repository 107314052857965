/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Button,Typography, 
         TableContainer, Table, TableBody, TableRow, TableCell,
         Dialog, DialogTitle, DialogContent, DialogActions  } from '@material-ui/core';

import useStyles from './MapLocations.styles';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const modalText = {
  what: {
    question: 'What can I do with locations?',
    answer:
      'Owning a location allows the placement of content on the map, and will generate shared revenue in the future from paid AR content that is geolocated at the location. Locations may also be sold or auctioned on the Omniscape marketplace with it launches in Summer 2021',
  },
  how: {
    question: 'How do I buy locations?',
    answer:
      'Locations are acquired by spending location credits. You can select individual locations by clicking on the map. To select multiple locations at the same time, change the select tool to Select Multiple Locations',
  },
};
export const ModalStructure = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.modalWrapper}>
      <div className={classes.modalContainer}>
        {children}
      </div>
    </div>
  );
};

export const InfoModal = ({ object, onCancel }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={true}
      onClose={() => onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title"><div className={classes.title}>{"Object's Details :"}</div></DialogTitle>
      <DialogContent>
      <TableContainer>
           <Table>
             <TableBody>
               <TableRow>
                 <TableCell align="left" className={classes.labelCell}>ID : </TableCell>
                 <TableCell align="left" className={classes.valueCell}>{object.contentID}</TableCell>
               </TableRow>
               <TableRow>
                 <TableCell align="left" className={classes.labelCell}>Label : </TableCell>
                 <TableCell align="left" className={classes.valueCell}>{object.label ? object.label : 'Not provided'}</TableCell>
               </TableRow>
               <TableRow>
                 <TableCell align="left" className={classes.labelCell}>Link : </TableCell>
                 <TableCell align="left" className={classes.valueCell}>{object.url ? <a href={object.url}>{object.url}</a> : 'Not provided'}</TableCell>
               </TableRow>
               <TableRow>
                 <TableCell align="left" className={classes.labelCell}>Description : </TableCell>
                 <TableCell align="left" className={classes.valueCell}>{object.description ? object.description : 'Not provided'}</TableCell>
               </TableRow>
               <TableRow>
                 <TableCell align="left" className={classes.labelCell}>Owner : </TableCell>
                 <TableCell align="left" className={classes.valueCell}>{object.owner ? object.owner : 'Not provided'}</TableCell>
              </TableRow>
            </TableBody>
           </Table>
       </TableContainer>
      </DialogContent>
      <DialogActions>
         <Button onClick={onCancel} > Close </Button>
      </DialogActions>
    </Dialog>
  );
};
export const InfoModal1 = ({ setPopup}) => {
  const classes = useStyles(); 
  return (
    <ModalStructure>
    <Typography align="left" className={classes.title}>
     Accept cookies 
    </Typography>
    <Typography align="left" className={classes.modalText} variant="body1">
      This website uses cookies to personalize content and analyze traffic in order to offer you a better experience.
      Please accept all cookies...
    </Typography>
    <div className={classes.modalButtonRowRight}>
				<Button className={classes.modalButton} onClick={() => setPopup(false)}>
        Okay
      </Button>
    </div>
  </ModalStructure>
  );
};
