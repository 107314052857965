import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import './App.css';
import { withAuthentication } from './components/Session';
import Header from './components/Header';
import Loading from './components/Loading';
// theming
import themeOverrides from './constants/theme';
import app from 'firebase/app';
import 'firebase/auth';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import { csvPathsToArr, disabledPages } from './components/Header/Header.options';
import useAuthUser from './components/common/useAuthUser';
import AccountSettingsForm, { inputNames as acsInputNames } from './components/Setting/AccountSettingsForm';
import ModalsContainer from './components/common/modal/ModalsContainer';
import modalService from './components/common/modal/modalService';
import useRxSubject from './components/common/useRxSubject';
import { rxHeaderEnabled } from './components/common/config';
import SignIn from './components/SignIn'
import SignUp from './components/SignUp'
import Viewer from './components/Viewer'
import Locations from './components/Locations'
import EditorV2 from './components/EditorV2'
import VirtualGoods from './components/VirtualGoods'
import Shop from './components/Shop'
import Orders from './components/Orders'
import Setting from './components/Setting'
import MyLocations from './components/My Locations'
import Inventory from './components/Inventory'

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: 'c',
});

const theme = createTheme(themeOverrides);

// const AdminSignIn = React.lazy(() => import('./components/Admin/Admin-SignIn'));
// const Campaign = React.lazy(() => import('./components/Admin/Campaign'));
// const Offer = React.lazy(() => import('./components/Admin/Offer'));
// const Users = React.lazy(() => import('./components/Admin/Users'));
// const Discount = React.lazy(() => import('./components/Admin/Discounts'));
const routes = {
  '/editor': {
    Component: EditorV2,
    componentProps: {},
    exact: true,
    globalScroller: false,
  },
  '/locations': {
    Component: Locations,
    componentProps: {},
    exact: true,
    globalScroller: false,
  },
  '/goods': {
    Component: VirtualGoods,
    componentProps: {},
    exact: true,
  },
  '/signin': {
    exact: true,
    Component: SignIn,
    componentProps: {},
  },
  '/signup': {
    exact: true,
    Component: SignUp,
    componentProps: {},
  },
  '/signup?/invite_id=id': {
    exact: true,
    Component: SignUp,
    componentProps: {},
  },
  '/shop': {
    Component: Shop,
    componentProps: {},
    exact: true,
  },
  '/orders': {
    Component: Orders,
    componentProps: {},
    exact: true,
  },
  '/setting': {
    Component: Setting,
    componentProps: {},
    exact: true,
    // requireLogin: true,
  },
  '/setting/security': {
    Component: Setting,
    componentProps: {
      tab: 'security'
    },
    exact: true,
    // requireLogin: true,
  },
  '/my-locations': {
    Component: MyLocations,
    componentProps: {},
    exact: true,
    globalScroller: false,
  },
  '/my-locations/:orderId': {
    Component: MyLocations,
    componentProps: {},
    exact: true,
    globalScroller: false,
  },
  '/my-locations/:orderId/:locationId': {
    Component: MyLocations,
    componentProps: {},
    exact: true,
    globalScroller: false,
  },
  '/inventory': {
    Component: Inventory,
    componentProps: {},
    exact: true,
  },
  '/': {
    Component: Viewer,
    componentProps: {},
    exact: true,
    globalScroller: false,
  },
}
window.modalService = modalService
const IHeader = () => {
  const [show] = useRxSubject(rxHeaderEnabled, show => {
    // update body style
    document.body.style.marginTop = !show
      ? '0px'
      : '64px'
    return show
  })
  return !show ? '' : <Header />
}
function App() {
  const { user, userData = {} } = useAuthUser()
  const hideOnMobile = csvPathsToArr(process.env.REACT_APP_HIDE_ON_MOBILE);
  const { uid, username, wallet_moneybutton } = userData

  useEffect(() => {
    if (!!uid && !username) {
      const inputsToShow = [
        acsInputNames.username,
        // if user not already set a moneybutton wallet, show it
        !wallet_moneybutton && acsInputNames.wallet_moneybutton
      ].filter(Boolean)
      const hiddenInputs = Object.values(acsInputNames)
        .filter(x => !inputsToShow.includes(x))

      // prompt user to complete registration if not already done so
      const modalId = 'AccountSettingsForm'
      const modalProps = {
        closeButton: null,
        content: (
          <AccountSettingsForm {...{
            closeOnSubmit: true,
            hiddenInputs,
            modalId,
            values: userData,
          }} />
        ),
        subtitle: '',
        title: {
          children: 'Complete your registration',
          style: { paddingnBottom: 0 },
        },
      }
      modalService.set(modalProps, modalId)
    }
  }, [uid])

  return (
    <div>
      <Router>
        <StylesProvider generateClassName={generateClassName}>
          <MuiThemeProvider theme={theme}>
            <Switch>
              <Suspense fallback={<Loading />}>
                {Object.keys(routes)
                  .filter(path => !!path && !disabledPages.includes(path))
                  .map((path, i) => {
                    let {
                      exact,
                      Component,
                      componentProps = {},
                      requireLogin = false,
                      globalScroller = true,
                    } = routes[path]

                    // experimental
                    // if (!user && requireLogin) {
                    //   Component = SignIn
                    //   componentProps = {
                    //     redirectTo: path,
                    //   }
                    // }
                    return (
                      <Route exact={exact} key={i + path} path={path}>
                        <div style={{ overflowY: `${globalScroller ? 'auto' : 'none'}`, height: `${IHeader() !== '' ? 'calc(100vh - 64px)' : '100vh'}` }}>
                          {isMobile && hideOnMobile.includes(path)
                            ? <MobilePlaceHolder />
                            : (
                              <>
                                <IHeader />
                                <Component {...componentProps} />
                              </>
                            )}
                        </div>
                      </Route>
                    );
                  })}

                {/*  disabled admin pages
              
              <Route exact path="/admin-signin">
                <AdminSignIn />
              </Route>
              <Route exact path="/admin-campaign">
                {
                  currentUser && adminIds.includes(currentUser.uid) ?
                    <Campaign /> : <Redirect to="/admin-signin" />
                }
              </Route>

              <Route exact path="/admin-offers">
                {
                  currentUser && adminIds.includes(currentUser.uid) ?
                    <Offer /> : <Redirect to="/admin-signin" />
                }
              </Route>
              <Route exact path="/admin-users">
                {
                  currentUser && adminIds.includes(currentUser.uid) ?
                    <Users /> : <Redirect to="/admin-signin" />
                }
              </Route>
              <Route exact path="/admin-discount">
                {
                  currentUser && adminIds.includes(currentUser.uid) ?
                    <Discount /> : <Redirect to="/admin-signin" />
                }
              </Route> */}
              </Suspense>
            </Switch>
          </MuiThemeProvider>
        </StylesProvider>
      </Router>

      {/* Container global/default modalService instance modals */}
      <ModalsContainer rxModals={modalService.rxModals} />
    </div>
  )
}
const MobilePlaceHolder = () => (
  <div>
    <h1
      style={{
        color: 'red',
        fontSize: '150%',
        margin: '200px 25px',
        textAlign: 'center',
      }}
    >
      This page is not available on mobile phones. Please use a computer
      to access this page.
    </h1>
  </div>
)

export default withAuthentication(App);
