import { useEffect, useState } from 'react'
import { deferred } from './utils';
import FirestoreHelper from './FirestoreHelper';

/**
 * @name    useLocations
 * @summary React hook to subscribe and retrieve purchasedLocations collection entries.
 *
 * @param   {String}    userId      logged or target in user's ID. User `null` when user hasn't logged in,
 *                                  if data should only be retrieved after user is logged in.
 * @param   {Boolean}   ownedOnly   (optional) whether to retreive only logged user's objects or all objects
 * @param   {Boolean}   onlyHex     whether to return location entry (object) or only the location hex (string)
 * @param   {Boolean}   subscribe   if falsy, will only retrieve data once
 * 
 * @returns {Object|String}    Result: {claimed, error, loading, owned}
 */
export default function useLocations(userId, ownedOnly = false, onlyHex = true, subscribe = true) {
    const [state, setState] = useState({
        claimed: [], // claimed by others
        error: '',
        loading: true,
        owned: [], // owned by user
    })

    useEffect(() => {
        let mounted = true
        let unsubscribe
        const claimed = new Map()
        const owned = new Map()
        const firestore = new FirestoreHelper('purchasedLocations', 'id')
        const ownedWhere = ownedOnly && userId && ['ownerID', '==', userId]
        const mapToArr = map => Array
            .from(map)
            .map(([_, value]) => value)
        const updateState = deferred((loading = false, error = '') =>
            setState({
                claimed: mapToArr(claimed),
                error,
                loading,
                owned: mapToArr(owned),
            }),
            50,
        )
        const handleError = err => updateState(false, err.message)
        const handleResult = result => {
            if (!result.length) return

            result.forEach(entry => {
                const { locationHex: hex, ownerID } = entry
                const value = onlyHex
                    ? hex
                    : entry
                ownerID === userId
                    ? owned.set(hex, value)
                    : claimed.set(hex, value)
            })
            updateState()

            subscribe && observeNewlyCreated()
        }
        const observeNewlyCreated = (ts_created = new Date()) => {
            unsubscribe && unsubscribe()
            const where = [
                ['ts_created', '>', ts_created],
                ownedWhere,
            ]
            unsubscribe = firestore.subscribe(
                handleResult,
                handleError,
                where,
            )
        }

        const loading = ownedOnly
            ? !!userId
            : userId != null
        if (!loading) {
            updateState(false)
        } else {
            firestore
                .search([
                    ownedWhere,
                    ['expiryDate', '>', new Date()],
                ])
                .then(handleResult)
                .catch(handleError)
        }
        return () => {
            mounted = false
            unsubscribe && unsubscribe()
        }
    }, [userId])

    return state
}