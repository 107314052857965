/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  TablePagination,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  IconButton,
  Paper,
  CircularProgress,
  TableSortLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import useStyles from './Orders.styles';
import { useHistory, withRouter } from 'react-router-dom';
import moment from 'moment';
import { arrSort } from '../common/utils';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const getTotalLocations = (orders) => orders.reduce((acc, order) => acc + order.locationsCount || acc, 0);

const OrderItem = (props) => {

  const { status, orderDate, locationsCount, orderNumber } = props;
  const history = useHistory();
  const classes = useStyles(props);

  return (
    <div className={classes.orderContainer} onClick={() => history.push(`/my-locations/${orderNumber}`)}>
      <div className={classes.orderItem}>
        <div className={classes.orderLabel}>Status</div>
        <div className={`${classes.orderValue} ${classes.status} ${classes[status]}`}>{status}</div>
      </div>
      <div className={classes.orderItem}>
        <div className={classes.orderLabel}>order placed</div>
        <div className={classes.orderValue}>{orderDate !== "Invalid Date" ? orderDate : "N/A"}</div>
      </div>

      <div className={classes.orderItem}>
        <div className={classes.orderLabel}>locations purchased</div>
        <div className={classes.orderValue}>{locationsCount}</div>
      </div>

      <div className={classes.orderItem} style={{ flex: 2 }}>
        <div className={classes.orderLabel}>order</div>
        <div className={classes.orderValue}>{orderNumber}</div>
      </div>
    </div>
  );
};
// 0aa4fd60-c154-11ea-aa1c-8d603b2160c0
const Orders = ({ user, axios }) => {
  const [orders, setOrders] = useState([]);
  const [totalLocations, setTotalLocations] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortDirection, setSortDirection] = useState('desc') // latest first
  const [sortKey, setSortKey] = useState('order_confirmed_at')
  const headersTitles = ['ORDER', 'ORDER PLACED', 'LOCATION PURCHASED', 'STATUS', 'TRANSACTION'];
  const titleSortKeys = ['id', 'order_confirmed_at_sort', 'locationsCount', 'status', 'txId']

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const classes = useStyles();
  const setOrdersSorted = (orders, sortKey, sortDirection) => {
    orders = orders.sort((a, b) =>
      a[sortKey] > b[sortKey] ? 1 : -1
    )
    if (sortDirection === 'desc') orders = orders.reverse()
    setOrders(orders)
  }

  useEffect(() => {
    let mounted = true
    setIsLoading(true);
    async function fetchData() {
      await axios
        .getOrders({})
        .then((res) => {
          if (!mounted) return
          setIsLoading(false);
          let totalLocationCounts = 0;
          let orders = (Array.isArray(res) ? res : [])
            .filter((order) => order.order_confirmed_at !== '')
            .map(order => {
              const {
                order_confirmed_at = '',
                order_details: {
                  locations = []
                } = {},
              } = order
              order.locationsCount = locations.length
              const isTS = typeof order_confirmed_at === 'object'
                && order_confirmed_at._seconds
              order.order_confirmed_at_sort = isTS
                ? `${order_confirmed_at._seconds}`
                : `${new Date(order_confirmed_at).getTime()}`

              totalLocationCounts += order.locationsCount
              return order
            })
          setOrdersSorted(orders, sortKey, sortDirection)
          setTotalLocations(totalLocationCounts)
          // setOrdersSorted(orders, sortKey, sortAsc);
        })
        .catch(() => {
          mounted && setIsLoading(false);
        });
    }
    if (user) {
      fetchData();
    }
    return () => mounted = false
  }, [user])

  function Row(props) {
    const { row: order } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const class1 = useStyles();
    const history = useHistory();
    const {
      locationsCount,
      status,
      id,
      order_details,
      order_confirmed_at,
      txId,
    } = order;
    const titles = ['ID', 'Hex Value', 'Action'];
    let dateStr = typeof order_confirmed_at !== 'object'
      ? order_confirmed_at
      : moment.unix(order_confirmed_at._seconds)
        .local()
        .format('ddd MMM DD YYYY, hh:mm:ss A')

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {id}
          </TableCell>
          <TableCell align="center">{dateStr}</TableCell>
          <TableCell align="center">{locationsCount}</TableCell>
          <TableCell align="center">{status.split('_')[1]}</TableCell>
          <TableCell align="center">
            {txId && (
              <a
                href={`https://whatsonchain.com/tx/${txId}`}
                target='_blank'
              >
                View On-Chain Data
              </a>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Locations
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {
                        titles.map(op => <TableCell key={op}>{op}</TableCell>)
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order_details.locations.map((locationHex, index) => (
                      <TableRow key={locationHex}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell>{locationHex}</TableCell>
                        <TableCell>
                          <button
                            className={class1.view}
                            onClick={() => history.push(`/my-locations/${id}/${locationHex}`)}
                          >
                            View
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleRow}>
        <Typography className={classes.title}>My Orders</Typography>
        {!isLoading &&
          (<>
            <Typography className={classes.titleStats}>
              <strong>
                {orders.length}
                &nbsp;Orders
              </strong>
            </Typography>
            <Typography className={classes.titleStats}>
              <strong>
                {totalLocations}
                &nbsp;Locations
              </strong>
            </Typography>
          </>)
        }
      </div>

      {orders && orders.length ?
        <Paper>
          <TableContainer>
            <Table aria-label="collapsible table" style={{ minWidth: 800 }}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  {headersTitles.map((title, i) => {
                    const active = sortKey === titleSortKeys[i]
                    return (
                      <TableCell {...{
                        align: title == 'ORDER' ? 'left' : 'center',
                        sortDirection: active && sortDirection,
                      }}>
                        <TableSortLabel {...{
                          active,
                          direction: sortDirection,
                          onClick: () => {
                            let newSortDirection = !active || sortDirection !== 'asc'
                              ? 'asc'
                              : 'desc'
                            const newSortKey = titleSortKeys[i]
                            setSortDirection(newSortDirection)
                            setSortKey(newSortKey)
                            setOrdersSorted(orders, newSortKey, newSortDirection)
                          },
                        }}>
                          {title}
                        </TableSortLabel>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row = {}, i) => (
                    <Row {...{ key: row.id + i, row }} />
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={orders.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
        :
        (
          <Grid container direction="row" justify="center" alignItems="center">
            {isLoading ? <CircularProgress color="inherit" /> : <h1>No Orders!</h1>}
          </Grid>
        )
      }
    </div>
  );
};

export default withRouter(Orders);
