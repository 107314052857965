import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MoneyButton from '@moneybutton/react-money-button'

// Omniscape's paymail address
const omniscapePaymail = process.env.REACT_APP_MB_Paymail
// Client ID of the MoneyButton App
const clientIdentifier = process.env.REACT_APP_MB_ClientIdentifier
const OmniTokenPaymail = process.env.REACT_APP_MB_Asset_OmniToken
export const ASSET = {
    BSV: 'BSV',
    // local alias for OmniToken asset paymail
    OmniToken: 'OmniToken',
}

function PayButton(props) {
    const {
        amount,
        asset = ASSET.OmniToken,
        buttonId,
        buttonData,
        currency,
        label,
        onError,
        onLoad,
        onPayment,
    } = props
    const [loaded, setLoaded] = useState(false)

    // only show button when amount is greater than 0
    if (!amount || amount < 0) return ''

    const btnProps = {
        buttonData: typeof buttonData === 'string'
            ? buttonData
            : JSON.stringify(buttonData || ''),
        buttonId,
        clientIdentifier,
        label,
        onError,
        onLoad: () => {
            setLoaded(true)
            onLoad && onLoad(true)
        },
        onPayment,
        successMessage: 'Payment successful!',
    }

    switch (asset) {
        case ASSET.BSV:
            btnProps.amount = amount
            btnProps.currency = currency
            btnProps.to = omniscapePaymail
            break
        case ASSET.OmniToken:
            btnProps.outputs = [{
                amount,
                asset: OmniTokenPaymail,
                to: omniscapePaymail,
            }]
            break
        default:
            return 'Invalid asset'
    }

    return (
        <div>
            {!loaded && 'Loading...'}
            <div style={{ display: loaded ? 'block' : 'none' }}>
                <MoneyButton {...btnProps} />
            </div>
        </div>
    )
}
PayButton.propTypes = {
    amount: PropTypes.oneOfType([
        PropTypes.string, // for BSV
        PropTypes.number, // for OmniToken
    ]).isRequired,
    asset: PropTypes.string, // BSV or OmniToken
    buttonId: PropTypes.string,
    buttonData: PropTypes.any, // will be stringified if non-string supplied
    currency: PropTypes.string, // only applicable if asset is 'BSV'
    label: PropTypes.string,
    onError: PropTypes.func,
    onPayment: PropTypes.func.isRequired,
}

export default React.memo(PayButton)