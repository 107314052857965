import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((edit) => ({
  root: {
    height: '100vh',
    position: 'relative',
    margin: (edit) => (edit == true ? '0px 330px 0px 296px' : 0),
  },
  hideobjroot: {
    height: '100vh',
    position: 'relative',
    margin: '0px 330px 0px 0px',
  },
  hideproproot: {
    height: '100vh',
    position: 'relative',
    margin: '0px 0px 0px 296px',
  },
  hidebothroot: {
    height: '100vh',
    position: 'relative',
    margin: '0px 0px 0px 0px',
  },
  marker: {
    height: 25,
    width: 25,
    borderRadius: 180,
    background: 'red',
  },
  navigation: {
    zIndex: 8,
    position: 'absolute',
    right: 10,
    marginTop: 20,
    marginRight: 40,
  },
  geocoder: {
    left: 100,
    zIndex: 10,
    fontWeight: 700,
    display: 'none',
  },
  toolTip: {
    position: 'absolute',
    zIndex: 10,
    background: 'white',
    color: 'black',
    // pointerEvents: 'none',
    fontWeight: 800,
    fontSize: 14,
    border: '1px solid black',
    borderRadius: 25,
    padding: '0px 8px',
  },
  confirmation: {
    position: 'absolute',
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    borderRadius: 8,
    // pointerEvents: 'none',
    padding: '0.3rem',
    fontSize: 14,
  },
  selectInput: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  select: {
    position: 'relative',
  },
  selectTool: {
    height: 42,
    //marginTop: 4,
    color: 'white',
    background: 'black',
    zIndex: 10,
    minWidth: 300,
    borderRadius: 18,
    fontSize: 15,
    fontWeight: 700,
    boxShadow: '0px 3px 6px #00000069',
    padding: '8px 16px',
  },
  divdropdown: {
    right: '1px',
    zIndex: 8,
    position: 'absolute',
    marginTop: '192px',
    marginRight: '40px',
  },
  toggleObjectIcon: {
    position: 'absolute',
    left: '40px',
    top: '50vh',
  },
  deleteIcon: {
    position: 'absolute',
    cursor: 'pointer',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.1rem',
    borderRadius: '0.5rem',
    opacity: 0.3,
    zIndex: 9999,
  },
}));

export default useStyles;
