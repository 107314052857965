import { load } from '@loaders.gl/core';
import { GLTFLoader } from '@loaders.gl/gltf';
import app from 'firebase/app';
import 'firebase/storage';
import { exactEdgeLength, geoToH3, getH3UnidirectionalEdgesFromHexagon, h3ToGeo } from "h3-js";

/**
 * Get the base dimensions of a model
 * @param {string} src - the model src
 */
export const getModelInfo = async (src) => {
  if (!src) return null;

  const storage = app.storage();
  const modelRef = storage.ref('vre_models');

  // console.log("model src: ", src);

  let url = modelRef.child(src);
  if (!url) {
    return null;
  }

  url = await url.getDownloadURL() 

  const gltf = await load(url, GLTFLoader);
  // console.log(gltf);

  // get accessors index with max and min
  let aIndex = -1;
  gltf.accessors.forEach((buffer, i) => {
    if (buffer.max && buffer.min && aIndex === -1) {
      aIndex = i;
    }
  });

  // get model dimensions from buffer accessors.
  let dimensions = {
    x: 0,
    y: 0,
    z: 0
  };

  if (aIndex === -1) {
    return null;
  } else {
    dimensions.x = gltf.accessors[aIndex].max[0] - gltf.accessors[aIndex].min[0];
    dimensions.y = gltf.accessors[aIndex].max[1] - gltf.accessors[aIndex].min[1];
    dimensions.z = gltf.accessors[aIndex].max[2] - gltf.accessors[aIndex].min[2];
  }

  // console.log("model dimensions in meters at scale: 1", dimensions);

  // get index of scalings
  let sIndex = -1;
  gltf.scene.nodes.forEach((node, i) => {
    if (node.scale && node.mesh && sIndex === -1) {
      sIndex = i;
    }
  });

  // apply any scalings
  if (sIndex !== -1) {
    const scale = gltf.scene.nodes[sIndex].scale;
    dimensions.x *= scale[0];
    dimensions.y *= scale[1];
    dimensions.z *= scale[2];
    // console.log(`Applied Scale of ${scale}`, dimensions);
  }
  
  return dimensions;
};

/**
 * Gets the radius of a single hexagon in meters.
 * @param {string} hex - the hexagon id
 */
export const getHexRadius = (hex) => {
  const [edgeId] = getH3UnidirectionalEdgesFromHexagon(hex);

  // since a hexagon is an array of 6 equilateral triangles, the edge length can be taken as the radius of the hexagon
  const edge = exactEdgeLength(edgeId, "m"); 
  // console.log("edge length", edge);

  return edge;
}

/**
 * Get the scaled dimensions of a model in meters.
 * @param {string} modelSrc - the model src
 * @param {number} scale - the scale to apply to the model
 */
export const getScaledDimensions = (modelSrc, scale) => {
  const dimensions = getModelInfo(modelSrc); 
  dimensions.x *= scale;
  dimensions.y *= scale;
  dimensions.z *= scale;
  // console.log("scaled model dimensions", dimensions);
  return dimensions;
}

/**
 * Get the id of the center hexagon of a model.
 * @param {number} x - the x coordinate of the model
 * @param {number} y - the y coordinate of the model
 */
export const getCenterHex = (x, y) => {
  const defaultResolution = 13;
  const hexagon = geoToH3(x, y, defaultResolution);
  return hexagon;
};

/**
 * Get the id of the center hexagon of a model.
 * @param {number} x - the x coordinate of the model
 * @param {number} y - the y coordinate of the model
 */
export const getHexCoordinates = (hex) => {
  const coordinates = h3ToGeo(hex);
  // lat and lng are reversed
  return [coordinates[1], coordinates[0]];
};
