import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar, Drawer, Menu, MenuItem, Modal,
  ButtonBase, Typography
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { withStyles } from '@material-ui/styles';
import useStyles from './Header.styles';
import { ReactComponent as OmniscapeLogo } from '../../logo.svg';

export const CustomDrawer = withStyles(() => ({
  paper: {
    paddingTop: 84,
    zIndex: 9,
    boxShadow: '0px 3px 6px #00000069',
    left: (props) => (props.open === true ? 0 : -305),
    transition: 'left .4s',
    width: 300,
    borderBottomRightRadius: 18,
    top: 'auto',
    bottom: 0,
  },
}))(Drawer);

export const CustomAppBar = withStyles(() => ({
  root: {
    position: 'absolute',
    height: 84,
    background: 'black',
    color: 'white',
    borderRadius: '0px 0px 18px 18px',
    boxShadow: '0px 3px 12px #00000069',
  },
}))(AppBar);

export const noop = () => { };

export const CreditsMenu = ({ anchorEl, credits, handleClose }) => (
  <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
    <MenuItem>{`${credits} credits`}</MenuItem>
    <MenuItem>
      <Link style={{ textDecoration: 'none' }} to="/shop">Buy Location Credits</Link>
    </MenuItem>
  </Menu>
);

export const AccountMenu = ({ anchorEl, handleSignOut, handleClose, invitationurl }) => {
  const classes = useStyles();
  const [show, setShow] = useState(false)
  const [text, setText] = useState(false)
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {
          window.location.href.includes('admin') ?

            <MenuItem>
              <Link className={classes.menuLink} href="#" to="" onClick={handleSignOut}>
                Sign out
              </Link>
            </MenuItem>

            :
            <div>
              {/* <MenuItem onClick={() => setShow(true)}>
                Invitation Link
      </MenuItem> */}
              <MenuItem>
                <Link className={classes.menuLink} to="/orders">
                  My Orders
                </Link>
              </MenuItem >
              <MenuItem>
                <Link className={classes.menuLink} to="/setting">
                  My Settings
                </Link>
              </MenuItem>
              <MenuItem>
                <Link className={classes.menuLink} to="/inventory">
                  My Inventory
                </Link>
              </MenuItem>
              <MenuItem>
                <Link className={classes.menuLink} to="/">
                  FAQ
                </Link>
              </MenuItem>
              <MenuItem>
                {/* <Link style={{ textDecoration: 'none' }} to="/shop">
        Sign out
      </Link>  */}
                <Link className={classes.menuLink} href="#" to="" onClick={handleSignOut}>
                  Sign out
                </Link>
              </MenuItem>
            </div>
        }
      </Menu >
      <Modal
        open={show}
        onClose={() => { setShow(false); setText(false) }}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <h2>Invitation Link</h2>
          <tr>
            <p>
              <td>
                {`${invitationurl}`}
              </td>
              <td>
                <FileCopyIcon
                  onClick={() => {
                    navigator.clipboard.writeText(invitationurl)
                    setText(true)
                  }} />
                <small>{text ? 'copied!!' : ''}</small>
              </td>
            </p>
          </tr>
        </div>
      </Modal>
    </>
  );
};
export const FemmecubatorLogo = () => {
  const { logoStyle } = useStyles();

  return (
    <a href="/" style={{ textDecoration: 'none' }}>
      <ButtonBase focusRipple className={logoStyle}>
        <OmniscapeLogo width={45} height={45} />
        <Typography style={{ marginLeft: "1rem" }}>
          OMNISCAPE <sup>&beta;eta</sup>
        </Typography>
      </ButtonBase>
    </a>
  );
}

