import React, { useState, useEffect } from 'react';
import {
  Button,
  CircularProgress,
  Link,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { featureToH3Set, h3ToFeature } from 'geojson2h3';
import { geoToH3, h3ToGeo } from 'h3-js';
import useStyles from './LocationsTool.styles';
import EditIcon from '@material-ui/icons/Edit';
import {
  Grid,
  List,
  Avatar,
  ListItem,
  IconButton,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
} from '@material-ui/core';

import PinDropIcon from '@material-ui/icons/PinDrop';
import { createH3Layer } from '../../utils/layer_helpers';
import CheckIcon from '@material-ui/icons/Check';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import app from 'firebase/app';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import useAuthUser from '../common/useAuthUser';
import LocationSearchingOutlinedIcon from '@material-ui/icons/LocationSearchingOutlined';
import './PseudoClases.css';
import { isFirstDayOfMonth } from 'date-fns/esm';
import Axios from '../api/axios';
import { Link as RouterLink } from 'react-router-dom';
const axios = new Axios();
const dbLocations = app.firestore().collection('purchasedLocations');
const dbOrders = app.firestore().collection('vre_orders');
const customStyles = makeStyles((theme) => ({
  // container: {
  //   paddingLeft: '0px',
  //   paddingRight: '0px'
  // },
  // field: {
  //   width: '50vw',
  //   maxWidth: 400,
  // },
}));

/**
 * @name    getLocations
 * @summary get purchased locations either by orderId.
 * If orderId not supplied will retrieve all locaions owned by current user
 *
 * @param   {String} orderId
 */
const getLocations = async (orderId, userId) => {
  if (!userId && !orderId) return [];

  const data = [];
  // retrieve all completed orders by user (required for txId)
  let txIds = new Map();
  const ordersResult = await (orderId
    ? dbOrders.where('id', '==', orderId)
    : dbOrders.where('customer', '==', userId)
  ).get();

  ordersResult.forEach((doc) => txIds.set(doc.id, doc.data().txId));
  const locationsResult = await (orderId
    ? dbLocations.where('orderID', '==', orderId)
    : dbLocations.where('ownerID', '==', userId)
  ).get();

  const pad = (d) => `${d < 10 ? '0' : ''}${d.toString()}`;

  locationsResult.forEach((doc) => {
    const { expiryDate, id, locationHex, name, orderID } = doc.data();
    const t = new Date(1970, 0, 1);
    t.setSeconds(expiryDate.seconds);
    let day = t.getDate();
    let month = t.getMonth() + 1;
    let year = t.getFullYear();
    const date = pad(day) + '/' + pad(month) + '/' + year;
    data.push({
      date,
      id,
      locationHex,
      name,
      orderID,
      txId: txIds.get(orderID),
    });
  });
  console.log({ data })

  return data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
};

const getPurchaseOrders = async (userID) => {
  if (!userID) return [];
  const ordersResult = await dbOrders.where('customer', '==', userID).get();

  const result = {};
  ordersResult.forEach((o) => {
    let order = o.data();
    result[order.id] = {
      id: order.id,
      name: order.name == undefined ? 'Unnamed Locations' : order.name,
    };
  });
  console.log({ result })
  return result;
};

const LocationsTool = ({
  setCartList,
  setSearch,
  setViewState,
  viewState,
  setCartLayer,
  searchResult,
  setSearchResult,
  setgeocoderInput,
}) => {
  const params = useParams();
  const { locationId, orderId } = params;
  const [selected, setSelected] = useState(locationId);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedIsGroup, setSelectedIsGroup] = useState(false);
  const { user } = useAuthUser();
  const [locations, setLocations] = React.useState([]);
  const [purchasedOrders, setpurchasedOrders] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const customStyle = customStyles();
  const [locationName = '', setLocationName] = useState('');
  const [editableId, setEditableId] = useState('');

  const getLocationsWIthCoordinates = (locations) => {
    return locations.map((location) => {
      let coords = h3ToGeo(location.locationHex);
      let coords_json = { x: coords[1], y: coords[0] };
      return { ...coords_json, ...location };
    });
  };

  useEffect(() => {
    let mounted = true;
    displaylocation(selected);
    setIsLoading(true);
    if (user?.uid) {
      // retrieve purchased location entries from database

      getPurchaseOrders(user.uid)
        .then((data) => {
          setpurchasedOrders(data);
        })
        .catch((err) => toast.error(err.message));
      getLocations(orderId, user.uid)
        .then((data) => {
          data = getLocationsWIthCoordinates(data);

          mounted && setLocations(data);
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setIsLoading(false));
    }

    return () => (mounted = false);
  }, [user]);

  const displaylocation = async (locationId, group = false) => {
    if (!locationId) return;
    let latLong = await h3ToGeo(locationId);
    let vp = { latitude: latLong[0], longitude: latLong[1] };
    setSearch(vp);
    // ---------- BEGIN OF OMNI-1052 ----------------------

    let specificLocation = locations.filter((location) => location.locationHex === locationId)[0];
    let locationList;
    if (group) {
      locationList = locations
        .filter((location) => location.orderID === specificLocation.orderID)
        .map((location) => location.locationHex);
    } else {
      locationList = [locationId];
    }
    //const newList = [locationId];
    const newList = locationList;
    // ---------- END OF OMNI-1052 ----------------------

    setCartList(newList);
    setCartLayer([createH3Layer(newList, [255, 183, 77])]);
    setViewState({ ...vp, zoom: 18, transitionDuration: 0 });
  };
  const handleOrderUpdateName = async (id, name) => {
    // update the name of the PO
    //await dbOrders.doc(id).update({ name: locationName });
    setIsLoading(true);
    await axios.changeOrderName(id, locationName);

    // update the name of all of the locations in the purchase order
    locations
      .filter((location) => location.orderID == id)
      .forEach(async (location) => {
        await dbLocations.doc(location.id).update({ name: locationName });
      });
    // update locations list
    setLocations(
      locations.map((location) => {
        if (location.orderID === id) location.name = locationName;
        return location;
      })
    );

    setEditableId(null);
    toast.dark('Order name has been updated!');

    // retrieve purchased location entries from database

    getLocations(orderId, user.uid)
      .then((data) => setLocations(getLocationsWIthCoordinates(data)))
      .catch((err) => toast.error(err.message));

    getPurchaseOrders(user.uid)
      .then((data) => {
        setpurchasedOrders(data);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setIsLoading(false));
  };
  const handleUpdateName = async (id, name, isOrder = false) => {
    const isCurrent = editableId === id;
    !isCurrent && setLocationName(name);
    setEditableId(isCurrent ? null : id);
    if (!isCurrent || !locationName || locationName === name) return;
    try {
      /* ----------------- BEGIN OMNI-1054 ------------------ */

      await dbLocations.doc(id).update({ name: locationName });

      /* ----------------- END OMNI-1054 ------------------ */

      toast.dark('Location name has been updated!');
      displaylocation(selected);
      // retrieve purchased location entries from database
      setIsLoading(true);
      getLocations(orderId, user.uid)
        .then((data) => {
          setLocations(getLocationsWIthCoordinates(data));
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setIsLoading(false));
    } catch (err) {
      alert(err.message);
    }
  };

  /* ------------ BEGIN OF OMNI-1055-------------- */
  const [expanded, setExpanded] = React.useState(false);
  const handleAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const removeDuplicates = (arr) => {
    let set = new Set(arr);
    return [...set];
  };
  /* ------------ END OF OMNI-1055-------------- */

  const [filteredLocations, setfilteredLocations] = useState([]);

  useEffect(() => {
    setfilteredLocations(
      removeDuplicates(
        locations
          .map((location) => {
            if (searchResult == null) return location.orderID;
            else if (searchResult.result.bbox == null) return;
            else {
              let x1 = searchResult.result.bbox[0];
              let y1 = searchResult.result.bbox[1];
              let x2 = searchResult.result.bbox[2];
              let y2 = searchResult.result.bbox[3];

              if (location.x >= x1 && location.y >= y1 && location.x <= x2 && location.y <= y2) {
                return location.orderID;
              }
            }
          })
          .filter((purchaseOrder) => purchaseOrder != undefined)
      )
    );

    // if searchResult is nulled out
    if (searchResult == null) {
      setgeocoderInput('');
    } else {
      setgeocoderInput(searchResult.result.place_name);
    }
  }, [locations, searchResult]);

  if (!user) return '';
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>
          <Typography className={classes.title}>My Locations</Typography>
        </div>
        <Grid item xs={12} md={12} lg={7} style={{ justifyContent: 'center', maxWidth: '100%' }}>
          {isLoading ? (
            <Grid container direction="row" justify="center" alignItems="center">
              <CircularProgress color="inherit" />
            </Grid>
          ) : (
            <div className={classes.demo}>
              {searchResult ? (
                <span
                  onClick={() => {
                    setSearchResult(null);
                  }}
                  style={{
                    margin: '5px 38px',
                    marginBottom: '14px',
                    color: 'blue',
                    cursor: 'pointer',
                  }}
                >
                  Reset filters
                </span>
              ) : (
                ''
              )}
              <List dense={false}>
                {
                  /* ------------ BEGIN OF OMNI-1055-------------- */
                  locations.length == 0 ? (
                    <div style={{ margin: '0px 38px' }}>
                      You currently don’t own any locations.
                      <br />
                      <br />
                      <RouterLink to="/locations">Buy Locations</RouterLink>
                      <br />
                      <br />
                      <RouterLink to="/shop">Buy Credits / OmniTokens</RouterLink>
                    </div>
                  ) : filteredLocations.length > 0 ? (
                    filteredLocations.map((orderID) => {
                      let purchaseOrderLocations = locations.filter((l) => orderID == l.orderID);
                      return (
                        <Accordion
                          expanded={expanded === orderID}
                          onChange={handleAccordion(orderID)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${orderID}-content`}
                            id={`${orderID}-header`}
                            className="accordionSummary"
                            style={{
                              backgroundColor:
                                selectedIsGroup && purchaseOrderLocations[0] == selectedLocation
                                  ? '#EEEEEE'
                                  : '',
                              minHeight: 72,
                            }}
                            onClick={() => {
                              displaylocation(purchaseOrderLocations[0].locationHex, true);
                              setSelected(purchaseOrderLocations[0].locationHex);
                              setSelectedLocation(purchaseOrderLocations[0]);
                              setSelectedIsGroup(true);
                            }}
                          >
                            {editableId !== orderID ? (
                              <>
                                <Typography className={classes.heading}>
                                  {purchasedOrders[orderID]?.name || 'Unnamed location'}
                                </Typography>

                                <IconButton
                                  className="edit-group-name-button"
                                  edge="end"
                                  aria-label="delete"
                                >
                                  <EditIcon
                                    onClick={() => {
                                      const order = purchasedOrders[orderID]
                                      // In case there is an orphaned purchasedLocations entry that does not have an order associated with it.
                                      if (!order) return

                                      setEditableId(order.id);
                                      setLocationName(order.name);
                                    }}
                                  />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <TextField
                                  autoFocus
                                  key={`${orderID}-textfield`}
                                  variant="outlined"
                                  value={locationName}
                                  onChange={(e) => setLocationName(e.target.value)}
                                  className={customStyle.field}
                                  placeholder="Enter a location name"
                                  onKeyDown={(e) => {
                                    if (e.key == 'Enter') {
                                      handleOrderUpdateName(orderID, locationName, true);
                                    } else if (e.key == 'Escape') {
                                      setEditableId(null);
                                      setLocationName(null);
                                    }
                                  }}
                                />
                                <CheckIcon
                                  onClick={() => {
                                    handleOrderUpdateName(orderID, locationName, true);
                                  }}
                                />
                              </>
                            )}
                          </AccordionSummary>
                          <AccordionDetails style={{ flexDirection: 'column', padding: 0 }}>
                            {purchaseOrderLocations.map((purchasedLocation) => {
                              const { date, id, locationHex, name, txId } = purchasedLocation;
                              const key = `${id}-${editableId}`;
                              const nameContent =
                                editableId === id ? (
                                  <TextField
                                    autoFocus
                                    key={key}
                                    variant="outlined"
                                    value={locationName}
                                    onChange={(e) => setLocationName(e.target.value)}
                                    className={customStyle.field}
                                    placeholder="Enter a location name"
                                    onKeyDown={(e) => {
                                      if (e.key == 'Enter') {
                                        handleUpdateName(id, name);
                                      } else if (e.key == 'Escape') {
                                        setEditableId(null);
                                        setLocationName(null);
                                      }
                                    }}
                                  />
                                ) : (
                                  <ListItemText
                                    primary={name || 'Unnamed Location'}
                                    key={key}
                                    secondary={
                                      <>
                                        <span>{`expiry date: ${date}`}</span>
                                        <br />
                                        <span>
                                          {txId && (
                                            <a
                                              href={`https://whatsonchain.com/tx/${txId}`}
                                              target="_blank"
                                            >
                                              View On-Chain Data
                                            </a>
                                          )}
                                        </span>
                                      </>
                                    }
                                  />
                                );

                              return (
                                <div className={'location-item'}>
                                  <ListItem
                                    {...{
                                      key,
                                      selected: selected === locationHex,
                                      className: 'location-item',
                                      style: {
                                        cursor: 'pointer',
                                        backgroundColor:
                                          !selectedIsGroup &&
                                            purchasedLocation.locationHex ==
                                            selectedLocation.locationHex
                                            ? '#EEEEEE'
                                            : 'rgba(0,0,0,0)',
                                      },

                                      onClick: () => {
                                        displaylocation(locationHex, false);
                                        setSelected(locationHex);
                                        setSelectedLocation(purchasedLocation);
                                        setSelectedIsGroup(false);
                                      },
                                    }}
                                  >
                                    <ListItemAvatar>
                                      <Avatar style={{ backgroundColor: 'black' }}>
                                        <PinDropIcon />
                                      </Avatar>
                                    </ListItemAvatar>
                                    {nameContent}
                                    <ListItemSecondaryAction
                                      className="edit-name-button"
                                      onClick={() => handleUpdateName(id, name)}
                                    >
                                      <IconButton edge="end" aria-label="delete">
                                        {editableId === id ? <CheckIcon /> : <EditIcon />}
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                </div>
                              );
                            })}
                          </AccordionDetails>
                        </Accordion>
                      );
                    })
                  ) : filteredLocations.length == 0 && searchResult ? (
                    <div style={{ margin: '0px 38px' }}>
                      You don’t own any locations in:
                      <br />
                      {searchResult == null ? '' : searchResult.result.place_name}
                    </div>
                  ) : (
                    ''
                  )
                  /* ------------END OF OMNI-1055-------------- */
                }
              </List>
              <div className="empty-message" style={{ width: '100%' }}>
                <Typography className={classes.err}>You haven't purchased any location!</Typography>
              </div>
            </div>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default LocationsTool;
