/* eslint-disable no-undef */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable no-alert */
/* eslint-disable no-unused-expressions */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import Geocoder from 'react-map-gl-geocoder';

// deck.gl loaders and dependencies
import { GLTFLoader } from '@loaders.gl/gltf';
import { registerLoaders } from '@loaders.gl/core';
import { EditableGeoJsonLayer } from 'nebula.gl';
import { Typography, FormControl, MenuItem, Select, InputLabel, Button } from '@material-ui/core';
import useStyles from './MapLocations.styles';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './map.css';

// deck.gl utilities
import { createH3Layer, createHexList } from '../../utils/layer_helpers';

// info modals
import { InfoModal, ConfirmOrderModal } from './InfoModals';
import app from 'firebase/app';
// Register the proper loader for scenegraph.gltf
registerLoaders([GLTFLoader]);

const SuccessModal = ({ onCancel }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.modalWrapper}>
      <div className={classes.modalContainer}>
        <Typography align="left" className={classes.title}>
          Purchase successful
        </Typography>
        <Typography align="left" className={classes.modalText} variant="body1">
          Want to leave this page and add 3D content to your new locations?
        </Typography>
        <div className={classes.modalButtonRow}>
          <Button
            className={classes.modalSaveButton}
            color="primary"
            onClick={() => history.push('/editor')}
            variant="contained"
          >
            Yes
          </Button>
          <Button className={classes.modalButton} onClick={onCancel}>
            No
          </Button>
        </div>
      </div>
    </div>
  );
};

const MapLocations = ({
  axios,
  success,
  setSuccess,
  totalCredits,
  cartList,
  setCartList,
  master,
  infoModal,
  setInfoModal,
  viewState,
  setViewState,
  confirmModal,
  setConfirmModal,
  token,
  setCartLayer,
  cartLayer,
  name,
  setName,
  loc,
  getLocationsdata,
  search,
  setSearch,
  maptheme,
  setSearchResult,
  geocoderInput,
  //setgeocoderInput
}) => {
  const [masterList, setMasterList] = useState([]);
  const [masterLayer, setMasterLayer] = useState([]);

  // Layer of hexagons in cart
  // const [cartLayer, setCartLayer] = useState([]);
  const [credits, setCredits] = useState(0);

  // Layer of hexagon use currently hovering over
  const [hovered, setHovered] = useState([]);

  // modal close
  const handleModal = () => setInfoModal(false);
  /**
   * Calculates acrt price
   */
  const priceCalc = () => setCredits(cartList.length * 100);

  /**
   * Called when user hovers over map
   */
  const handleHover = (e) => {
    // prevents error when hovering over search bar
    if (e.target.id !== 'deckgl-overlay') return;
    // prevents error when moving cursor outside of map
    if (!e.lngLat || !cartList) return;
  };

  /**
   * Right click deletes hex from cart
   */
  const handleRightClick = (e) => {
    e.preventDefault();
    const clicked = createHexList(e.lngLat);
    const filtered = cartList.filter((i) => !clicked.includes(i));
    setCartList(filtered);
    setCartLayer([createH3Layer(filtered, [255, 183, 77])]);
  };

  const handleConfirm = async () => {
    if (typeof confirmModal !== 'string') throw new Error('Missing orderId');

    await axios
      .confirmOrder(confirmModal)
      .then(() => {
        setConfirmModal(null);
      })
      .catch(() => {
        alert('Something went wrong. Order could not be completed.');
      });
    // then
  };

  // Map Utils
  const mapRef = React.useRef();
  const geocoderContainerRef = React.useRef();
  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
  const handleViewPortChange = (vp) => {
    setViewState({ ...vp, transitionDuration: 0 });
  };

  const onSearchResult = (vp) => {
    const { latitude, longitude } = vp;
    setSearch({ latitude, longitude });
    setViewState({ ...vp, zoom: viewState.zoom, transitionDuration: 0 });
  };

  const classes = useStyles();
  /**
   * Renders buildings from props data
   *
   * master = {
   *  total: 5,
   *  locations: [
   *    'location1',
   *    'location2',
   *    ]
   *  }
   *
   */
  useEffect(() => {
    if (master) {
      const list = master.locations;
      const { total } = master;
      let current = 0;
      let tempMaster = [];
      const tempList = [];
      // chunk loading hexes
      const chunkSize = 5000;
      while (current < total) {
        const next = Math.min(current + chunkSize, total);
        const slice = list.slice(current, next);
        tempList.push(...slice);
        tempMaster = [...tempMaster, createH3Layer(slice, [33, 150, 243])];
        current = next;
      }

      setMasterList([...masterList, ...tempList]);
      setMasterLayer([...masterLayer, ...tempMaster]);
      // these 2 are causing a bug when selecting a group of hexes in my-locations
      //setCartList([]);
      //setCartLayer([]);
    }
  }, [master]);
  /* this is causing a bug when selecting a group of hexes in my-locations
  useEffect(() => {
    if (cartList.length === 0) {
      console.log('wiped out 2');
      setCartLayer([]);
    }
  }, [cartList]);*/

  const closeModal1 = () => setConfirmModal(false);

  return (
    <div>
      {success && <SuccessModal onCancel={() => setSuccess(false)} />}
      {infoModal && <InfoModal info={infoModal} onCancel={handleModal} />}
      {confirmModal && (
        <ConfirmOrderModal
          credits={1000}
          onClick={handleConfirm1}
          onCancel={closeModal1}
          name={name}
          setName={setName}
          loc={loc}
        />
      )}
      <div className={classes.root}>
        <ReactMapGL
          ref={mapRef}
          {...viewState}
          width="inherit"
          height="100%"
          touchRotate // used for mobile
          touchZoom // used for mobile
          mapboxApiAccessToken={MAPBOX_TOKEN}
          onViewportChange={handleViewPortChange}
          onContextMenu={handleRightClick}
          onHover={handleHover}
          mapStyle={maptheme ? maptheme : `mapbox://styles/mapbox/streets-v11`}
          mapboxApiAccessToken={
            'pk.eyJ1IjoicnJpY2U5MTkiLCJhIjoiY2pqZ2dnY2kyMHB2djN2bzQ1ZGZvajR0eiJ9.kVpZH3MhHkOa1BEI1v4R8g'
          }
        >
          <div className={classes.navigation}>
            <NavigationControl />
          </div>
          <div className={classes.menuContainer}>
            <div className={classes.geocoder}>
              <Geocoder
                mapRef={mapRef}
                trackProximity
                containerRef={geocoderContainerRef}
                onViewportChange={onSearchResult}
                mapboxApiAccessToken={MAPBOX_TOKEN}
                position="top-left"
                inputValue={geocoderInput}
                onResult={(result) => setSearchResult(result)}
              />
            </div>
          </div>

          <DeckGL useDevicePixels viewState={{ ...viewState }} layers={[[...cartLayer]]} />
        </ReactMapGL>
      </div>
    </div>
  );
};

export default MapLocations;
