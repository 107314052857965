import React, { useEffect, Suspense } from 'react';
import { geoToH3 } from 'h3-js';
import Loading from '../Loading'
import useAuthUser from '../common/useAuthUser'
import useObjects from '../common/useObjects'
import MapView from '../MapView'

// const MapView = React.lazy(() => import('../MapView'));

function Viewer({ viewState, setViewState }) {

  // destructure latitude and longitude and rename as lat and long
  const { latitude: lat, longitude: long } = viewState;

  // 3D content state
  // const [buildings, setBuildings] = React.useState({});
  const [visitedHexes, setVisitedHexes] = React.useState([]);
  const { user, userData } = useAuthUser()
  // retrieve all objects created by all users
  const { error, loading, objects = [] } = useObjects(user?.uid, false)

  /**
   * Calculate hex on map viewState change
   */
  useEffect(() => {
    let hex = geoToH3(lat, long, 6);
    setViewState({ hex });
  }, [viewState.latitude])

  useEffect(() => {
    if (user && !visitedHexes.includes(viewState.hex)) {
      let hex = geoToH3(lat, long, 6);
      setVisitedHexes([...visitedHexes, hex])
    }
  }, [user, viewState.hex])

  if (!loading && error) return <Loading message={error} />

  return (
    <Suspense fallback={<Loading />}>
      <MapView
        buildings={objects}
        maptheme={userData?.maptheme}
      />
    </Suspense>
  );
}

export default React.memo(Viewer)
