import { hasValue, isDefined, isFn, isSubjectLike, isValidNumber } from "./utils"


/**
 * @name    subjectAsPromise
 * @summary sugar for using RxJS subject as a promise and, optionally, wait until an expected value is received.
 * 
 * @param   {Subject}           subject         RxJS subject or similar subscribable
 * @param   {*|Function}        expectedValue   (optional) if undefined, will resolve on first value received
 *                                              Use `subjectAsPromise.anyValueSymbol` to wait for any defined value.
 * @param   {Number|Function}   timeout         (optional) will reject if no value received within given time
 * 
 * @returns {[Promise, Function]}   will reject with: 
 *                                  - `null` if times out
 *                                  - `undefined` if @subject is not a valid RxJS subject like subscribable
 */
export const subjectAsPromise = (subject, expectedValue, timeout) => {
    if (!isSubjectLike(subject)) return console.log('Not subject', subject)

    let subscription, timeoutId
    const unsubscribe = () => setTimeout(() => {
        subscription.unsubscribe()
        clearTimeout(timeoutId)
    }, 50)
    const promise = new Promise((resolve, reject) => {
        subscription = subject.subscribe(value => {
            const isExpectedValue = isFn(expectedValue)
                ? expectedValue(value) === value
                : isDefined(expectedValue)
                    ? value === expectedValue
                    || expectedValue === subjectAsPromise.anyValueSymbol && hasValue(value)
                    : true
            if (!isExpectedValue) return
            unsubscribe()
            resolve(value)
        })
        timeoutId = isValidNumber(timeout) && setTimeout(() => {
            unsubscribe()
            reject(new Error('Timeout'))
        }, timeout)

    })
    return [promise, unsubscribe]
}
subjectAsPromise.anyValueSymbol = Symbol('any-value')