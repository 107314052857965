/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Suspense } from 'react';
import { compose } from 'redux';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import Loading from '../Loading';
import MapVirtualGoods from '../MapVirtualGoods'

// const MapVirtualGoods = React.lazy(() => import('../MapVirtualGoods'));

function VirtualGoods({ firebase }) {
  const [goods, setGoods] = useState([]);

  useEffect(() => {
    firebase.firestore.collection('models').onSnapshot(function (docs) {
      const temp = [];
      docs.forEach((doc) => temp.push(doc.data()));
      setGoods(temp);
    });
  }, [firebase.firestore]);

  return (
    <Suspense fallback={<Loading />}>
      <MapVirtualGoods goods={goods} />
    </Suspense>
  );
}

const condition = (authUser) => !!authUser;
export default compose(withAuthorization(condition), withFirebase)(VirtualGoods);
